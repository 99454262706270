import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

const fetchUser = async (token, setIsLoggedIn, setHasCompletedForm, setUserId, setEmail, setFirstName, setLastName, setSubscriptionActive, setIsMedical, setSuperUser, setIsLoading) => {
  if (!token) {
    setIsLoading(false);
    return;
  }

  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    setIsLoggedIn(true);
    setHasCompletedForm(data.hasCompletedForm);
    setUserId(data._id);
    setEmail(data.email);
    setFirstName(data.nombre);
    setLastName(data.apellido);
    setSubscriptionActive(data.subscriptionActive);
    setIsMedical(data.isMedical);
    setSuperUser(data.superUser);
  } catch (error) {
    console.error('Error:', error);
    setIsLoggedIn(false);
  } finally {
    setIsLoading(false);
  }
};

export function AuthProvider({ children }) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [hasCompletedForm, setHasCompletedForm] = useState(false);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const [userId, setUserId] = useState(null);
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [subscriptionActive, setSubscriptionActive] = useState(false);
  const [isMedical, setIsMedical] = useState(false);
  const [superUser, setSuperUser] = useState(false);

  const completeForm = (completed) => {
    setHasCompletedForm(completed);
  };

  const login = (token) => {
    if (!token) {
      return;
    }
    setIsLoggedIn(true);
    setToken(token);
    localStorage.setItem('token', token);
    fetchUser(token, setIsLoggedIn, setHasCompletedForm, setUserId, setEmail, setFirstName, setLastName, setSubscriptionActive, setIsMedical, setSuperUser, setIsLoading);
  };

  const logout = () => {
    setToken(null);
    localStorage.removeItem('token');
    setIsLoggedIn(false);
    setUserId(null);
    setFirstName('');
    setLastName('');
    setSubscriptionActive(false);
    setIsMedical(false);
    setSuperUser(false);
  };

  useEffect(() => {
    if (token) {
      fetchUser(token, setIsLoggedIn, setHasCompletedForm, setUserId, setEmail, setFirstName, setLastName, setSubscriptionActive, setIsMedical, setSuperUser, setIsLoading);
    } else {
      setIsLoading(false);
    }
  }, [token]);

  return (
    <AuthContext.Provider value={{ isLoggedIn, isLoading, login, logout, hasCompletedForm, completeForm, token, userId, email, firstName, lastName, subscriptionActive, isMedical, superUser }}>
      {children}
    </AuthContext.Provider>
  );
}