const es = {
    sideMenu: {
      dashboard: 'Dashboard',
      descubrir: 'Descubrir',
      despensa: 'Mi Despensa',
      historial: 'Historial',
      preferencias: 'Preferencias',
      configuracion: 'Configuración',
      nutricionista: 'Panel Nutricionista',
      editarRecetas: 'Editar Recetas',
      soporte: 'Soporte',
    },
  };
  
  export default es;  