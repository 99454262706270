const it = {
    swal: {
      title: 'Sei sicuro?',
      text: 'Vuoi uscire?',
      confirmButtonText: 'Sì, esci',
      cancelButtonText: 'No, annulla',
      confirmedTitle: 'Disconnesso!',
      confirmedText: 'La tua sessione è stata chiusa.',
    },
    button: {
      logout: 'Esci',
    },
  };
  
  export default it;  