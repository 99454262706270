import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { AuthContext } from '../AuthContext';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { TextField, Autocomplete, Typography, Button, Chip } from '@mui/material';
import Swal from 'sweetalert2';
import { useDropzone } from 'react-dropzone';

countries.registerLocale(english);
const countryOptions = Object.entries(countries.getNames("en", { select: "official" })).map(([code, name]) => ({
  code,
  name
}));

const unitOptions = ['litros', 'mililitros', 'gramos', 'kilogramos', 'tazas', 'cucharadas', 'cucharaditas', 'onzas', 'libras', 'unidad', 'al gusto'];

const categoryOptions = [
  { label: 'Escoger Categoria(s)', value: '', disabled: true },
  { label: 'Desayuno', value: 'desayuno' },
  { label: 'Merienda (mañana)', value: 'merienda1' },
  { label: 'Almuerzo', value: 'almuerzo' },
  { label: 'Merienda (tarde)', value: 'merienda2' },
  { label: 'Cena', value: 'cena' },
  { label: 'Merienda (noche)', value: 'merienda3' }
];

function MyDropzone({ onFilesAdded }) {
  const [previewImages, setPreviewImages] = useState([]);

  const onDrop = useCallback(acceptedFiles => {
    const filePreviews = acceptedFiles.map(file => {
      const preview = URL.createObjectURL(file);
      return { file, preview };
    });

    setPreviewImages(filePreviews);
    onFilesAdded(acceptedFiles);
  }, [onFilesAdded]);

  const removeImage = (index) => {
    URL.revokeObjectURL(previewImages[index].preview); // Liberar memoria
    const newPreviews = previewImages.filter((_, idx) => idx !== index);
    setPreviewImages(newPreviews);
    onFilesAdded(newPreviews.map(p => p.file)); // Actualizar archivos pasados hacia afuera
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div {...getRootProps()} style={{ border: '2px dashed gray', padding: '20px', textAlign: 'center' }}>
        <input {...getInputProps()} />
        {
          isDragActive ?
            <p>Suelta los archivos aquí...</p> :
            <p>Arrastra y suelta algunos archivos aquí, o haz clic para seleccionar archivos</p>
        }
      </div>
      {previewImages.length > 0 && (
        <div style={{ marginTop: '20px' }}>
          <h2>Vista previa de Imágenes</h2>
          {previewImages.map((preview, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <img src={preview.preview} style={{ width: '100px', height: '100px', objectFit: 'cover' }} alt="Preview" />
              <button onClick={() => removeImage(index)} style={{ marginLeft: '10px' }}>Eliminar</button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

function CreateRecipe() {
  const {
    isLoggedIn,
    isLoading,
    token,
    userId
  } = useContext(AuthContext);
  const navigate = useNavigate();

  const [tipoComidaOptions, setTipoComidaOptions] = useState([]);

  useEffect(() => {
    // Si el usuario no está cargado o autenticado, redirigir a login
    if (!isLoading && !isLoggedIn) {
      navigate('/login');
    } else if (isLoggedIn && token && userId) {
      // Aquí puedes cargar datos adicionales si es necesario
      //console.log("Usuario logueado con ID:", userId);
    }
  }, [isLoggedIn, isLoading, navigate, token, userId]);
  const [ingredientOptions, setIngredientOptions] = useState([]);
  const [formData, setFormData] = useState({
    nombre: '',
    pais: '',
    categoria: [],
    tipoComida: [],
    ingredientes: [],
    instrucciones: [{ descripcion: '', imagen: '', video: '' }],
    tiempoPreparacion: '',
    tiempoCoccion: '',
    dificultad: '',
    porciones: '',
    informacionNutricional: {},
    imagenes: [],
    tags: []
  });

  const fileInputsRefs = useRef({});

  const removeIngredient = (index) => {
    setFormData(prevState => ({
      ...prevState,
      ingredientes: prevState.ingredientes.filter((_, i) => i !== index)
    }));
  };

  const removeInstruction = (index) => {
    setFormData(prevState => ({
      ...prevState,
      instrucciones: prevState.instrucciones.filter((_, i) => i !== index)
    }));
  };

  useEffect(() => {
    const fetchTipoComida = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/tipocomida`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        if (response.data) {
          const tiposDeComida = response.data.map(tipo => ({
            label: tipo.label,  // Cambia 'tipo.nombre' por 'tipo.label' si es necesario
            value: tipo.value   // Cambia '_id' por 'value' si es necesario
          }));
          setTipoComidaOptions(tiposDeComida);
        } else {
          //console.log('No se recibieron datos:', response);
        }
      } catch (error) {
        console.error('Failed to fetch tipoComida:', error);
      }
    };

    if (isLoggedIn && token) {
      fetchTipoComida();
    }
  }, [isLoggedIn, token]);

  useEffect(() => {
    async function fetchIngredients() {
      try {
        const result = await axios.get(`${process.env.REACT_APP_API_URL}/api/ingredients`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        //console.log("Datos recibidos de /api/ingredients:", result.data);  // Verificar los datos recibidos
        const formattedData = result.data.map(ing => {
          //console.log("Procesando ingrediente:", ing._id); // Verifica que este es el ID correcto
          return {
            ...ing,
            opciones: ing.opciones.map((opcion, index) => ({ ...opcion, index }))
          };
        });
        setIngredientOptions(formattedData);
      } catch (error) {
        console.error('Failed to fetch ingredients:', error);
      }
    }
    fetchIngredients();
  }, [token, isLoggedIn]);

  useEffect(() => {
    const fetchRecetasFiltradas = async () => {
      if (userId) {
        try {
          // const horaLocal = new Date().getHours();
          //console.log("Hora local enviada:", horaLocal); // Confirma que esto muestra un número válido
          // const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/recipes/sorprendeme/${userId}?hora=${horaLocal}`);
          //console.log("Recetas filtradas:", response.data);
        } catch (error) {
          console.error("Error al filtrar recetas:", error);
        }
      }
    };

    fetchRecetasFiltradas();
  }, [userId]);

  useEffect(() => {
    //console.log("Estado actualizado de formData:", formData);
  }, [formData]);

  const handleIngredientChange = (index, field, value) => {
    const newIngredients = formData.ingredientes.map((ingredient, idx) => {
      if (idx === index) {
        if (field === 'tipo') {
          // Asumimos que 'value' es el objeto del ingrediente seleccionado desde el dropdown o autocompletado
          return {
            ...ingredient,
            tipo: value._id, // ID del ingrediente
            opcionIndex: value.index, // Índice de la opción seleccionada
            nombreOpcion: value.nombre, // Nombre del ingrediente seleccionado
          };
        } else {
          // Manejo de cambio en 'cantidadPorPorcion', 'unidad' o cualquier otro campo
          return {
            ...ingredient,
            [field]: value,
          };
        }
      }
      return ingredient;
    });
    setFormData({ ...formData, ingredientes: newIngredients });
  };

  const addIngredient = () => {
    setFormData({
      ...formData,
      ingredientes: [...formData.ingredientes, { tipo: '', opcionIndex: 0, cantidadPorPorcion: 0, unidad: '', nombreOpcion: '' }]
    });
  };

  const handleInstructionChange = (index, field, value) => {
    const newInstructions = formData.instrucciones.map((instruccion, idx) => {
      if (idx === index) {
        return { ...instruccion, [field]: value };
      }
      return instruccion;
    });
    setFormData({ ...formData, instrucciones: newInstructions });
  };

  const handleFileChange = (event, index, fileType) => {
    const file = event.target.files[0];
    if (file) {
      const preview = URL.createObjectURL(file);
      handleInstructionChange(index, fileType, preview);
      fileInputsRefs.current[`${index}-${fileType}`] = event.target;
    }
  };

  const removeFile = (index, fileType) => {
    handleInstructionChange(index, fileType, '');
    if (fileInputsRefs.current[`${index}-${fileType}`]) {
      fileInputsRefs.current[`${index}-${fileType}`].value = '';
    }
  };

  const addInstruction = () => {
    setFormData({
      ...formData,
      instrucciones: [...formData.instrucciones, { descripcion: '', imagen: '', video: '' }]
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    //console.log('Datos del formulario antes de enviar:', formData);

    const validCategories = formData.categoria.filter(cat => cat != null && cat !== '');

    if (!formData.nombre || !formData.pais || !formData.categoria) {
      Swal.fire(
        'Información incompleta',
        'Por favor, completa todos los campos requeridos.',
        'warning'
      );
      return;
    }

    const dataToSend = {
      ...formData,
      categoria: validCategories,
      tipoComida: formData.tipoComida || [],
      imagenes: formData.imagenes.map(img => img.split('/').pop())
    };

    axios.post(`${process.env.REACT_APP_API_URL}/api/recipes`, dataToSend)
      .then(response => {
        //console.log('Recipe created:', response.data);
        Swal.fire(
          '¡Éxito!',
          'Tu receta ha sido creada exitosamente.',
          'success'
        );
        setFormData({
          nombre: '',
          pais: '',
          categoria: [],
          tipoComida: [],
          ingredientes: [],
          instrucciones: [{ descripcion: '', imagen: '', video: '' }],
          tiempoPreparacion: '',
          tiempoCoccion: '',
          dificultad: '',
          porciones: '',
          informacionNutricional: {},
          imagenes: [],
          tags: []
        });
        Object.values(fileInputsRefs.current).forEach(input => input.value = '');
      })
      .catch(error => {
        console.error('Error creating recipe:', error.response ? error.response.data : error);
        Swal.fire(
          'Error',
          `No se pudo crear la receta. ${error.response ? error.response.data.message : "Inténtalo de nuevo."}`,
          'error'
        );
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'imagenes' || name === 'tags') {
      setFormData(prevState => ({
        ...prevState,
        [name]: value.split(',')
      }));
    } else {
      setFormData(prevState => ({
        ...prevState,
        [name]: value
      }));
    }
  };

  const handleNutritionChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      informacionNutricional: {
        ...prevState.informacionNutricional,
        [name]: value
      }
    }));
  };

  const handleTagsChange = (tags) => {
    setFormData(prevState => ({
      ...prevState,
      tags: tags
    }));
  };

  const handleFilesAdded = (files) => {
    //console.log("Procesando archivos añadidos:", files);
    const fileURLs = files.map(file => URL.createObjectURL(file));
    //console.log("URLs de los archivos:", fileURLs);
    setFormData(prevState => ({
      ...prevState,
      imagenes: [...prevState.imagenes, ...fileURLs]
    }));
  };

  //console.log("tipoComidaOptions", tipoComidaOptions);
  //console.log("formData.tipoComida", formData.tipoComida);

  return (
    <div>
      <h1>Crear Receta</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} placeholder="Nombre de la receta" />
        <Autocomplete
          options={countryOptions}
          getOptionLabel={(option) => option.name}
          style={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Seleccione un país" variant="outlined" />}
          onChange={(event, newValue) => {
            setFormData({ ...formData, pais: newValue ? newValue.name : '' });
          }}
          value={countryOptions.find(co => co.name === formData.pais) || null}
          isOptionEqualToValue={(option, value) => option.name === value.name}
        />
        <Autocomplete
          multiple
          id="category-select"
          options={categoryOptions}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Categorías"
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option.value}>
                <Typography component="div" style={{ opacity: option.value ? 1 : 0.5 }}>
                  {option.label}
                </Typography>
              </li>
            );
          }}
          onChange={(event, newValue) => {
            const categoryValues = newValue
              .filter(item => item && item.value)
              .map(item => item.value);

            setFormData(prevState => ({
              ...prevState,
              categoria: categoryValues
            }));
          }}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          value={formData.categoria.map(catValue => categoryOptions.find(option => option.value === catValue) || {})}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <Chip
                  key={option.value} // Pasa la key directamente
                  label={option.label}
                  {...tagProps} // Pasa las demás props usando spread
                />
              );
            })
          }
        />
        {tipoComidaOptions.length > 0 && (
          <Autocomplete
            multiple
            id="tipo-comida-select"
            options={tipoComidaOptions}
            getOptionLabel={(option) => option.label || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Tipo de Comida"
              />
            )}
            onChange={(event, newValue) => {
              const tipoComidaValues = newValue.map(item => item.value);
              setFormData(prevState => ({
                ...prevState,
                tipoComida: tipoComidaValues
              }));
            }}
            value={formData.tipoComida.map(tipoValue => tipoComidaOptions.find(option => option.value === tipoValue) || {})}
            isOptionEqualToValue={(option, value) => option.value === value.value}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const { key, ...tagProps } = getTagProps({ index });
                return (
                  <Chip
                    key={option.value}
                    label={option.label}
                    {...tagProps}
                  />
                );
              })
            }
          />
        )}
        {formData.ingredientes.map((ingredient, index) => (
          <div key={index}>
            <Autocomplete
              value={ingredientOptions.find(opt => opt._id === ingredient.tipo && ingredient.opcionIndex === opt.index)}
              onChange={(event, newValue) => {
                // Asegúrate de que newValue no es null y tiene la estructura correcta
                if (newValue) {
                  handleIngredientChange(index, 'tipo', {
                    _id: newValue._id,
                    index: newValue.index,
                    nombre: newValue.nombre
                  });
                } else {
                  handleIngredientChange(index, 'tipo', null);
                }
              }}
              options={ingredientOptions.flatMap(option => option.opciones.map((opcion, index) => ({
                ...opcion,
                _id: option._id,
                index
              })))}
              getOptionLabel={(option) => option.nombre}
              renderInput={(params) => (
                <TextField {...params} label="Seleccione un ingrediente" variant="outlined" fullWidth />
              )}
              style={{ marginBottom: '20px', width: 300 }}
              isOptionEqualToValue={(option, value) => option._id === value._id && option.index === value.index}
            />
            <input
              type="number"
              name="cantidadPorPorcion"
              value={ingredient.cantidadPorPorcion}
              onChange={(e) => handleIngredientChange(index, 'cantidadPorPorcion', e.target.value)}
              placeholder="Cantidad"
            />
            <select
              name="unidad"
              value={ingredient.unidad}
              onChange={(e) => handleIngredientChange(index, 'unidad', e.target.value)}
            >
              <option value="">Seleccione una unidad</option>
              {unitOptions.map((unit, idx) => (
                <option key={idx} value={unit}>{unit}</option>
              ))}
            </select>
            <Button variant="contained" color="secondary" onClick={() => removeIngredient(index)}>Eliminar Ingrediente</Button>
          </div>
        ))}
        <button type="button" onClick={addIngredient}>Añadir Ingrediente</button>
        {formData.instrucciones.map((instruction, index) => (
          <div key={index} style={{ marginBottom: '20px', border: '1px solid #ccc', padding: '10px' }}>
            <TextField
              fullWidth
              label="Descripción del paso"
              value={instruction.descripcion || ''}
              onChange={(e) => handleInstructionChange(index, 'descripcion', e.target.value)}
              variant="outlined"
              margin="normal"
            />
            <label>Escoger Imágen</label>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e, index, 'imagen')}
              style={{ display: 'block', marginTop: '10px' }}
            />
            {instruction.imagen && (
              <div style={{ marginTop: '10px' }}>
                <img src={instruction.imagen} alt="Vista previa" style={{ width: '100px', height: '100px', marginRight: '10px' }} />
                <Button variant="outlined" color="secondary" onClick={() => removeFile(index, 'imagen')}>
                  Eliminar Imagen
                </Button>
              </div>
            )}
            <label>Escoger Video</label>
            <input
              type="file"
              accept="video/*"
              onChange={(e) => handleFileChange(e, index, 'video')}
              style={{ display: 'block', marginTop: '10px' }}
            />
            {instruction.video && (
              <div style={{ marginTop: '10px' }}>
                <video src={instruction.video} controls style={{ width: '200px', height: 'auto' }} />
                <Button variant="outlined" color="secondary" onClick={() => removeFile(index, 'video')}>
                  Eliminar Video
                </Button>
              </div>
            )}
            <Button variant="contained" color="secondary" onClick={() => removeInstruction(index)}>Eliminar Instrucción</Button>
          </div>
        ))}
        <button type="button" onClick={addInstruction}>Añadir Instrucción</button>
        <input type="text" name="tiempoPreparacion" value={formData.tiempoPreparacion} onChange={handleChange} placeholder="Tiempo de preparación" />
        <input type="text" name="tiempoCoccion" value={formData.tiempoCoccion} onChange={handleChange} placeholder="Tiempo de cocción" />
        <input type="text" name="dificultad" value={formData.dificultad} onChange={handleChange} placeholder="Dificultad" />
        <input type="number" name="porciones" value={formData.porciones} onChange={handleChange} placeholder="Porciones" />
        <h2>Información Nutricional</h2>
        <input type="number" name="calorias" value={formData.informacionNutricional.calorias} onChange={handleNutritionChange} placeholder="Calorías" />
        <input type="number" name="proteinas" value={formData.informacionNutricional.proteinas} onChange={handleNutritionChange} placeholder="Proteínas" />
        <input type="number" name="carbohidratos" value={formData.informacionNutricional.carbohidratos} onChange={handleNutritionChange} placeholder="Carbohidratos" />
        <input type="number" name="grasas" value={formData.informacionNutricional.grasas} onChange={handleNutritionChange} placeholder="Grasas" />
        <MyDropzone onFilesAdded={handleFilesAdded} />
        <TagsInput value={formData.tags} onChange={handleTagsChange} />
        <Button type="submit" variant="contained" color="primary">
          Crear Receta
        </Button>
      </form>
    </div>
  );
}

export default CreateRecipe;