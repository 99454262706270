const enTranslations = {
    menu: {
      statusActive: 'Status: Active',
      statusInactive: 'Status: Inactive',
      editAccount: 'Edit Account',
      settings: 'Settings',
      support: 'Support',
      logout: 'Logout',
    },
    search: {
      placeholder: 'Search…',
    },
    notifications: {
      noNew: 'No new notifications',
    },
    languages: {
      es: 'ES',
      en: 'EN',
      ja: 'JP',
      it: 'IT',
    },     
  };  

  export default enTranslations;