import React, { useState, useEffect, useCallback } from 'react';
import '../../tablanutricional/TablaNutricional.css';
import Popup from '../../tablanutricional/Popup';
import ConfigPopup from '../../tablanutricional/ConfigPopup';
import useAuthValidation from '../../../hooks/useAuthValidation';
import handleCellClick from '../../tablanutricional/handleCellClick';
import handleSave from '../../tablanutricional/handleSave';
import handleDelete from '../../tablanutricional/handleDelete';
import handleAddTabla from '../../tablanutricional/handleAddTabla';
import handleConfigSave from '../../tablanutricional/handleConfigSave';
import calculateTotalPortions from '../../tablanutricional/calculateTotalPortions';
import { IconButton, Button, TextField, Autocomplete } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import Swal from 'sweetalert2';
import _ from 'lodash'; // Importar lodash

const TablaNutricionalPreferencias = ({ onNewTable, invitationId, tableType, tableIds }) => {
  const { userId, isLoading, token } = useAuthValidation();
  const [tablas, setTablas] = useState([]);
  const [activeTabla, setActiveTabla] = useState(null);
  const [selectedCell, setSelectedCell] = useState({ group: '', col: null });
  const [showPopup, setShowPopup] = useState(false);
  const [showConfigPopup, setShowConfigPopup] = useState(false);
  const [configInitialData, setConfigInitialData] = useState({});
  const [warnings, setWarnings] = useState('');
  const [showUnitsSelect, setShowUnitsSelect] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);
  const [doctorName, setDoctorName] = useState(null);
  const [doctorOptions, setDoctorOptions] = useState([]);

  useEffect(() => {
    console.log('TablaNutricionalPreferencias mounted');
  }, []);

  const fetchTablasCallback = useCallback(async () => {
    if (!hasFetched && userId) {
      try {
        console.log('Usuario autenticado:', userId); // Log para verificar el userId autenticado
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional?userId=${userId}&source=preferencias`);
        console.log('Response data:', response.data); // Log para verificar la respuesta de las tablas
        if (response.data.length > 0) {
          setTablas(response.data);
          setActiveTabla(response.data[0]);
          const userResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${userId}`);
          const assignedDoctorId = userResponse.data.assignedDoctor;
          if (assignedDoctorId) {
            const doctorResponse = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/${assignedDoctorId}`);
            console.log('Doctor asignado:', doctorResponse.data);
            setDoctorName(doctorResponse.data.nombre);
          } else {
            setDoctorName(null);
          }
        } else {
          setTablas([]);
          setActiveTabla(null);
          console.log('No tables found');
        }
        setHasFetched(true);
      } catch (error) {
        console.error('Error fetching tables:', error);
      }
    }
  }, [userId, hasFetched]);

  useEffect(() => {
    if (userId && !hasFetched) {
      fetchTablasCallback();
    }
  }, [userId, fetchTablasCallback, hasFetched]);

  const getMealTime = (col) => {
    switch (col) {
      case 1: return 'desayuno';
      case 2: return 'merienda1';
      case 3: return 'almuerzo';
      case 4: return 'merienda2';
      case 5: return 'cena';
      case 6: return 'merienda3';
      default: return '';
    }
  };

  const handleDeleteTabla = async (tablaId) => {
    try {
      const tabla = tablas.find(tabla => tabla._id === tablaId);
      let secondaryTable = null;
      if (tabla.tipo === 'principal') {
        secondaryTable = tablas.find(tabla => tabla.tipo === 'secundaria');
        if (secondaryTable) {
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${secondaryTable._id}`);
        }
      }

      await axios.delete(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${tablaId}`);
      const updatedTablas = tablas.filter(tabla => tabla._id !== tablaId && (!secondaryTable || tabla._id !== secondaryTable._id));
      setTablas(updatedTablas);
      setActiveTabla(updatedTablas.length > 0 ? updatedTablas[0] : null);
      console.log('Deleted table from database');
    } catch (error) {
      console.error('Error deleting table:', error);
    }
  };

  const handleConfigTabla = (tabla) => {
    setConfigInitialData(tabla);
    setShowConfigPopup(true);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const fetchDoctors = useCallback(
    _.debounce(async (query) => {
      if (query.trim() === '') {
        setDoctorOptions([]);
        return;
      }
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/search-doctors`, {
          params: { query },
          headers: { 'Authorization': `Bearer ${token}` }
        });
        const doctors = response.data.doctors || response.data; // Asegurarse de que sea siempre un array
        setDoctorOptions(doctors.map(doctor => ({
          nombre: doctor.nombre,
          email: doctor.email,
          id: doctor._id
        })));
      } catch (error) {
        console.error('Error al buscar doctores:', error);
      }
    }, 300), [token]
  );

  const handleAssignDoctor = async (doctorEmail, doctorName = null) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/invite-doctor`, {
            email: doctorEmail,
            doctorName,
            tablaId: activeTabla._id
        }, {
            headers: { 'Authorization': `Bearer ${token}` }
        });

        if (response.status === 200) {
            Swal.fire('Invitación Enviada', 'La invitación ha sido enviada al doctor.', 'success');
            fetchTablasCallback(); // Refrescar tablas después de enviar la invitación
        }
    } catch (error) {
        console.error('Error al invitar doctor:', error);
        Swal.fire('Error', 'Hubo un problema al enviar la invitación.', 'error');
    }
  };

  const handleRemoveDoctor = async () => {
    const confirmed = await Swal.fire({
      title: '¿Estás seguro?',
      text: "Eliminarás al doctor asignado.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar'
    });

    if (confirmed.isConfirmed) {
      try {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/remove-doctor`, {
          tablaId: activeTabla._id
        }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });

        setDoctorName(null);
        Swal.fire('Eliminado', 'El doctor ha sido eliminado.', 'success');
        fetchTablasCallback(); // Refrescar tablas después de eliminar el doctor
      } catch (error) {
        console.error('Error al eliminar el doctor:', error);
        Swal.fire('Error', 'Hubo un problema al eliminar el doctor.', 'error');
      }
    }
  };

  if (isLoading || !userId) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Crear Tabla Nutricional</h2>
      <div className='tabla_buttons'>
        {tablas.map((tabla, index) => (
          <div key={index} className="tabla_button_wrapper">
            <button
              className={activeTabla && activeTabla._id === tabla._id ? 'selected' : ''}
              onClick={() => setActiveTabla(tabla)}
            >
              {tabla.nombre}
            </button>
            {!tabla.isBlocked && (
              <>
                <IconButton onClick={() => handleDeleteTabla(tabla._id)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => handleConfigTabla(tabla)}>
                  <EditIcon />
                </IconButton>
              </>
            )}
          </div>
        ))}
        {tablas.length < 2 && !activeTabla?.isBlocked && (
          <button onClick={() => handleAddTabla('Nueva Tabla', false, setConfigInitialData, setShowConfigPopup, userId, 'preferencias', null, token, invitationId)}>
            +
          </button>
        )}
      </div>
      {activeTabla && (
        <div>
          <h2>{activeTabla.nombre}</h2>
          <div>
            Nutricionista: {doctorName ? (
              <>
                {doctorName}
                <Button onClick={handleRemoveDoctor}>Eliminar Doctor</Button>
              </>
            ) : (
              <Autocomplete
                options={doctorOptions}
                getOptionLabel={(option) => `${option.nombre} (${option.email})`}
                onInputChange={(event, newInputValue) => fetchDoctors(newInputValue)}
                onChange={(event, newValue) => {
                  if (newValue) {
                    console.log(`Asignando doctor ${newValue.nombre} (${newValue.id}) al usuario ${userId}`);
                    handleAssignDoctor(newValue.email);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Buscar Doctor"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />
            )}
          </div>
          <p>Lorem ipsum dolor sit amet.</p>
          {warnings && (
            <div style={{ color: 'red' }}>
              <p>{warnings}</p>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th>Grupo de Alimentos</th>
                <th>Porciones Totales</th>
                <th>Desayuno</th>
                <th>Merienda 1</th>
                <th>Almuerzo</th>
                <th>Merienda 2</th>
                <th>Cena</th>
                <th>Merienda 3</th>
              </tr>
            </thead>
            <tbody>
              {['lacteos', 'vegetales', 'frutas', 'azucar', 'harinas', 'proteinas', 'grasas'].map((group) => (
                <tr key={group}>
                  <td>{group.charAt(0).toUpperCase() + group.slice(1)}</td>
                  <td>{calculateTotalPortions(group, activeTabla)}</td>
                  {[1, 2, 3, 4, 5, 6].map((colIndex) => (
                    <td key={colIndex}>
                      {activeTabla.isBlocked
                        ? activeTabla.tableData[group][colIndex] || 'N/A'
                        : <button onClick={() => handleCellClick(group, colIndex, activeTabla, setWarnings, setSelectedCell, setShowPopup, setShowUnitsSelect, getMealTime)}>
                            {activeTabla.tableData[group][colIndex] || 'Seleccionar'}
                          </button>
                      }
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {showPopup && (
            <Popup
              onClose={() => setShowPopup(false)}
              onSave={(value) => handleSave(value, activeTabla, selectedCell, setActiveTabla, setShowPopup, setWarnings, setTablas, tablas, getMealTime)}
              onDelete={() => handleDelete(activeTabla, selectedCell, setActiveTabla, setShowPopup, setTablas, tablas, getMealTime)}
              initialValue={activeTabla.tableData[selectedCell.group][selectedCell.col]}
              showUnitsSelect={showUnitsSelect}
            />
          )}
        </div>
      )}
      {showConfigPopup && (
        <ConfigPopup
          onClose={() => setShowConfigPopup(false)}
          onSave={(data) => {
            handleConfigSave(data, tableType, userId, 'preferencias', setTablas, setShowConfigPopup, setActiveTabla, onNewTable);
          }}
          initialName={configInitialData.nombre || ''}
          initialData={configInitialData}
          source='preferencias'
        />
      )}
    </div>
  );
};

export default TablaNutricionalPreferencias;