import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  preguntasRestricciones,
  preguntasGastronomicas,
  preguntasSalud,
  preguntasHabitos,
  preguntasCultura
} from '../config/questions';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import useAuthValidation from '../hooks/useAuthValidation';

countries.registerLocale(english);
const countryOptions = countries.getNames('en', { select: 'official' });

export default function useFormData(initialData, categories, userCountry) {
  const [formData, setFormData] = useState(initialData);
  const [opciones, setOpciones] = useState({});
  const [loading, setLoading] = useState(true);
  const [ingredientesCategorias, setIngredientesCategorias] = useState({});
  const { token } = useAuthValidation();

  const allQuestions = useMemo(() => [
    ...preguntasGastronomicas, 
    ...preguntasRestricciones, 
    ...preguntasHabitos, 
    ...preguntasSalud, 
    ...preguntasCultura
  ], []);

  const fetchOptions = async (categories, userCountry) => {
    const fetchCategoryOptions = categories.filter(categoria => categoria !== 'paises').map(async (categoria) => {
      let url = `${process.env.REACT_APP_API_URL}/api/options/${categoria}`;

      if (categoria === 'recipes' && categories.includes('platillosInternacionales')) {
        url = `${process.env.REACT_APP_API_URL}/api/recipes/international/${userCountry}`;
      }
      
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (!data || !Array.isArray(data)) {
          console.error(`Invalid response for category ${categoria}:`, data);
          return { categoria, data: [] };
        }
        return { categoria, data };
      } catch (error) {
        console.error(`Error loading data for category ${categoria}:`, error);
        return { categoria, data: [] };
      }
    });

    const results = await Promise.all(fetchCategoryOptions);
    const newOptions = results.reduce((acc, { categoria, data }) => {
      let categoriaOpciones = [];
      
      if (categoria === 'preferencias_bebidas') {
        categoriaOpciones = data.flatMap(item =>
          item.categorias.flatMap(subItem =>
            subItem.opciones.map(opcion => ({
              label: `${subItem.nombre} - ${opcion.nombre}`,
              value: opcion._id
            }))
          )
        );
      } else {
        categoriaOpciones = data.flatMap(item =>
          item.opciones ? item.opciones.map(opcion => ({
            label: opcion.nombre,
            value: opcion._id
          })) : []
        );
      }
      
      return { ...acc, [categoria]: categoriaOpciones };
    }, {});

    newOptions['paises'] = Object.keys(countryOptions).map(key => ({ label: countryOptions[key], value: key }));

    const recipesResponse = await fetch(`${process.env.REACT_APP_API_URL}/api/options/recipes`);
    const recipes = await recipesResponse.json();
    newOptions['recipes'] = recipes.map(recipe => ({
      label: recipe.nombre,
      value: recipe._id
    }));

    setOpciones(newOptions);
    setLoading(false);
  };

  const fetchIngredients = useCallback(async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/ingredients`;
    try {
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      const data = await response.json();
      if (!data || !Array.isArray(data)) {
        console.error('Invalid response for ingredients:', data);
        return [];
      }
      return data;
    } catch (error) {
      console.error('Error loading ingredients:', error);
      return [];
    }
  }, [token]);

  useEffect(() => {
    setLoading(true);
    fetchOptions(categories, userCountry).catch(error => {
      console.error("Error processing fetch options:", error);
      setLoading(false);
    });
  }, [categories, userCountry, allQuestions]);

  useEffect(() => {
    fetchIngredients().then((data) => {
      const ingredientes = data.flatMap(item => item.opciones || []);
      const categorias = [...new Set(ingredientes.map(ingrediente => ingrediente.categoria).filter(categoria => categoria))];

      const ingredientesCategorias = categorias.reduce((acc, categoria) => {
        acc[categoria] = ingredientes.filter(ingrediente => ingrediente.categoria === categoria).map(ingrediente => ({
          label: ingrediente.nombre,
          value: ingrediente._id
        }));
        return acc;
      }, {});

      setIngredientesCategorias(ingredientesCategorias);
    }).catch(error => {
      console.error("Error fetching ingredients:", error);
    });
  }, [fetchIngredients]);

  return { formData, setFormData, opciones, loading, ingredientesCategorias };
}