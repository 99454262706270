import { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../components/AuthContext';

function useAuthValidation() {
  const {
    isLoggedIn,
    isLoading,
    logout,
    hasCompletedForm,
    completeForm,
    token,
    firstName: initialFirstName,
    lastName: initialLastName,
    subscriptionActive: initialSubscriptionActive,
    isMedical: initialIsMedical,
    superUser: initialSuperUser
  } = useContext(AuthContext);

  const [firstName, setFirstName] = useState(initialFirstName || '');
  const [lastName, setLastName] = useState(initialLastName || '');
  const [userCountry, setUserCountry] = useState('');
  const [userId, setUserId] = useState('');
  const [subscriptionActive, setSubscriptionActive] = useState(initialSubscriptionActive || false);
  const [isMedical, setIsMedical] = useState(initialIsMedical || false);
  const [superUser, setSuperUser] = useState(initialSuperUser || false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      navigate('/login');
    }
  }, [isLoading, isLoggedIn, navigate]);

  useEffect(() => {
    if (!isLoading && isLoggedIn && token) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          if (!response.ok) {
            throw new Error('Failed to fetch user data');
          }
          const data = await response.json();
          setFirstName(data.nombre);
          setLastName(data.apellido);
          setUserCountry(data.pais);
          setUserId(data._id);
          setSubscriptionActive(data.subscriptionActive);
          setIsMedical(data.isMedical);
          setSuperUser(data.superUser);
          completeForm(data.hasCompletedForm);

          // Redirigir basado en hasCompletedForm
          if (!data.hasCompletedForm) {
            navigate('/bienvenida');
          }
        } catch (error) {
          setError('Failed to load user data');
          logout();
          navigate("/login");
        } finally {
          setLoading(false);
        }
      };
      fetchUserData();
    }
  }, [isLoading, isLoggedIn, token, completeForm, navigate, logout]);

  return { firstName, lastName, userCountry, userId, error, loading, token, hasCompletedForm, isLoggedIn, isLoading, subscriptionActive, isMedical, superUser, logout, completeForm };
}

export default useAuthValidation;