import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';
import logo from '../images/unifood-blanco.png';
import './AuthForm.css';

function AuthForm({ isLogin }) {
    const [rememberMe, setRememberMe] = useState(false);
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        password: '',
        repeatPassword: '',
        fechaNacimiento: '',
        pais: '',
    });

    const handleInputChange = (e) => {
        let value = e.target.value;
        if (e.target.name === 'email') {
            value = value.toLowerCase();
        }
        setFormData({ ...formData, [e.target.name]: value });
    };

    return (
        <div className={`login-father`}>
            <div className="left-side">
                <Link to="/home">
                    <img src={logo} alt="Unifood Logo" className="logo" />
                </Link>
                <p className="trial-text">Disfruta de tus 3 meses gratis de prueba</p>
            </div>
            <div className="auth-form">
                {isLogin ? (
                    <LoginForm formData={formData} handleInputChange={handleInputChange} rememberMe={rememberMe} setRememberMe={setRememberMe} />
                ) : (
                    <RegisterForm formData={formData} handleInputChange={handleInputChange} />
                )}
            </div>
        </div>
    );
}

export default AuthForm;