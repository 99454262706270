import Menu from './Menu';

function Nosotros() {
    return (
        <div className="nosotros">
            <Menu/>
        </div>
    )
}

export default Nosotros;