import React, { useState, useMemo } from 'react';
import { Button, LinearProgress } from '@mui/material';
import Swal from 'sweetalert2';
import useAuthValidation from '../hooks/useAuthValidation';
import useFormData from '../hooks/useFormData';
import QuestionGroup from '../components/QuestionGroup';
import FoodToleranceScreen from '../components/FoodToleranceScreen';
import {
  preguntasGastronomicas,
  preguntasRestricciones,
  preguntasHabitos,
  preguntasSalud,
  preguntasCultura
} from '../config/questions';
import { useNavigate } from 'react-router-dom';

function Formulario({ nivel, hideForm }) {
  const { token, completeForm, userId, userCountry } = useAuthValidation();
  const navigate = useNavigate();

  const categories = useMemo(() => {
    return [...new Set([...preguntasGastronomicas, ...preguntasRestricciones, ...preguntasHabitos, ...preguntasSalud, ...preguntasCultura].map(q => q.categoria))];
  }, []);

  const { formData, setFormData, opciones, loading: formDataLoading, ingredientesCategorias } = useFormData({}, categories, userCountry);

  const stepsMapping = useMemo(() => ({
    'basic': [preguntasGastronomicas, preguntasRestricciones],
    'medium': [preguntasGastronomicas, preguntasRestricciones, preguntasHabitos, preguntasSalud],
    'advanced': [preguntasGastronomicas, preguntasRestricciones, preguntasHabitos, preguntasSalud, preguntasCultura]
  }), []);

  const [currentStep, setCurrentStep] = useState(0);
  const [showToleranceScreen, setShowToleranceScreen] = useState(false);
  const [toleranceLevels, setToleranceLevels] = useState({});
  const [noAplicaSelected, setNoAplicaSelected] = useState(false);
  // const [disableQuestion8, setDisableQuestion8] = useState(false);

  const handleBack = () => {
    if (showToleranceScreen) {
      setShowToleranceScreen(false);
    } else if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    } else {
      hideForm();
    }
  };

  const handleNext = () => {
    if (showToleranceScreen) {
      setShowToleranceScreen(false);
      return;
    }

    const alimentosQueNoGustan = formData['alimentosQueNoGustan'] || [];
    const ingredientesDisfrutas = formData['ingredientesDisfrutas'] || [];
    
    // Verificar si "No Aplica" está seleccionado en alimentosQueNoGustan (ID 4)
    const noAplicaInAlimentosQueNoGustan = alimentosQueNoGustan.some(item => item.id === 'No aplica');
    
    // Verificar si "No Aplica" está seleccionado en ingredientesDisfrutas (ID 4)
    const noAplicaInIngredientesDisfrutas = ingredientesDisfrutas.some(item => item.id === 'No aplica');
    
    if (currentStep < stepsMapping[nivel].length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      if (noAplicaInAlimentosQueNoGustan || noAplicaInIngredientesDisfrutas) {
        submitForm();
      } else if (!noAplicaSelected && alimentosQueNoGustan.length > 0) {
        setShowToleranceScreen(true);
      } else {
        submitForm();
      }
    }
  };

  const updateFormData = (fieldName, value) => {
    setFormData(prevFormData => {
      const newFormData = { ...prevFormData, [fieldName]: value };
  
      // if (fieldName === 'tipoDieta') {
      //   const isVegetarianOrVegan = value.some(option => option.nombre === 'Vegetariana' || option.nombre === 'Vegana');
      //   setDisableQuestion8(isVegetarianOrVegan);
  
      //   if (isVegetarianOrVegan) {
      //     const noAplicaOption = { id: 'no_aplica', nombre: 'No Aplica', refModel: null };
      //     newFormData.consumoDeCarne = [noAplicaOption];
      //     newFormData.frecuenciaConsumoCarne = [noAplicaOption]; // Asegurarse de que frecuenciaConsumoCarne también esté actualizado
      //   } else {
      //     newFormData.consumoDeCarne = [];
      //     newFormData.frecuenciaConsumoCarne = [];
      //   }
      // }
      console.log(`Updating formData for field: ${fieldName}`, newFormData);
  
      return newFormData;
    });
  };

  const submitForm = async () => {
    if (!userId) {
      console.error('User ID is not defined');
      return;
    }
  
    const sanitizedAlimentosQueNoGustan = formData.alimentosQueNoGustan.map(alimento => {
      if (alimento.id === 'No aplica') {
        return {
          id: 'No aplica',
          nombre: 'No aplica',
          refModel: null,
          nivelTolerancia: 0 // Asignar un valor por defecto que cumpla con el esquema
        };
      }
      return {
        id: alimento.id,
        nombre: alimento.nombre,
        nivelTolerancia: toleranceLevels[alimento.id] !== undefined ? toleranceLevels[alimento.id] : 0, // Asegurarse de que siempre haya un valor
        refModel: alimento.refModel
      };
    });
  
    const dataToSend = {
      userId,
      data: {
        ...formData,
        alimentosQueNoGustan: sanitizedAlimentosQueNoGustan
      }
    };
  
    console.log('Datos a enviar a FormData:', dataToSend);
  
    Swal.fire({
      title: 'Guardando preferencias',
      didOpen: () => {
        Swal.showLoading();
      }
    });
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/completeForm`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(dataToSend)
      });
  
      const data = await response.json();
  
      if (response.ok) {
        completeForm(true); // Actualizar el campo hasCompletedForm a true
        Swal.fire({
          icon: 'success',
          title: 'Preferencias guardadas',
          text: data.message
        });
        navigate('/dashboard'); // Redirigir al dashboard
      } else {
        throw new Error(data.message || 'No se pudieron guardar las preferencias');
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: error.message || 'No se pudieron guardar las preferencias'
      });
      console.error('Error:', error);
    }
  };  

  const handleNoAplicaChange = (isSelected) => {
    setNoAplicaSelected(isSelected);
  };

  const isNextDisabled = () => {
    const currentQuestions = stepsMapping[nivel][currentStep];
  
    return currentQuestions.some(q => {
      const isVegDiet = formData.tipoDieta && formData.tipoDieta.some(option => option.nombre === 'Vegetariana' || option.nombre === 'Vegana');
      
      if (q.nombreCampo === 'consumoDeCarne' && isVegDiet) {
        return !(formData.consumoDeCarne && formData.consumoDeCarne[0]?.nombre === 'No Aplica'); // Asegurarse de que "No Aplica" está seleccionado
      }
  
      const isFieldEmpty = !formData[q.nombreCampo] || formData[q.nombreCampo].length === 0;
      return isFieldEmpty;
    });
  };

  if (formDataLoading) {
    return <LinearProgress />;
  }

  if (showToleranceScreen) {
    return (
      <FoodToleranceScreen
        foods={formData['alimentosQueNoGustan'] || []}
        toleranceLevels={toleranceLevels}
        setToleranceLevels={setToleranceLevels}
        onClose={() => setShowToleranceScreen(false)}
        onSave={async (updatedToleranceLevels) => {
          setToleranceLevels(updatedToleranceLevels);
          setFormData(prevData => ({
            ...prevData,
            alimentosQueNoGustan: prevData.alimentosQueNoGustan.map(alimento => ({
              ...alimento,
              nivelTolerancia: updatedToleranceLevels[alimento.id] !== undefined ? updatedToleranceLevels[alimento.id] : alimento.nivelTolerancia
            }))
          }));
          await submitForm();
        }}
      />
    );
  }

  return (
    <div>
      <QuestionGroup
        preguntas={stepsMapping[nivel][currentStep]}
        opciones={opciones}
        formData={formData}
        updateFormData={updateFormData}
        userCountry={userCountry}
        onNoAplicaChange={handleNoAplicaChange}
        // disableQuestion8={disableQuestion8}
        ingredientesCategorias={ingredientesCategorias}
      />
      <Button variant="contained" onClick={handleBack}>Atrás</Button>
      <Button variant="contained" onClick={handleNext} disabled={isNextDisabled()}>Siguiente</Button>
    </div>
  );
}

export default Formulario;