import axios from 'axios';
import Swal from 'sweetalert2';

const handleConfigSave = async ({ nombre, dias, fechaInicio, fechaFin, tipo, _id }, tableType, userId, source, setTablas, setShowConfigPopup, setActiveTabla, onNewTable, email, token, context) => {
  let tablaData;

  try {
    console.log('Context en handleConfigSave:', context);
    console.log('Source en handleConfigSave:', source);

    if (!tipo) {
      console.error('Tipo de tabla no definido');
      Swal.fire({
        title: 'Error!',
        text: 'Tipo de tabla no definido. Inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    const isTemporary = source === 'nutricionista' && context === 'invitacionespanel';
    console.log('isTemporary evaluado:', isTemporary);

    tablaData = {
      nombre,
      tipo,
      dias,
      fechaInicio,
      fechaFin,
      sinLimite: tipo === 'principal',
      userId,
      tableData: {
        lacteos: Array(7).fill('Seleccionar'),
        vegetales: Array(7).fill('Seleccionar'),
        frutas: Array(7).fill('Seleccionar'),
        azucar: Array(7).fill('Seleccionar'),
        harinas: Array(7).fill('Seleccionar'),
        proteinas: Array(7).fill('Seleccionar'),
        grasas: Array(7).fill('Seleccionar')
      },
      elegirOpcionIndices: {
        desayuno: 0,
        merienda1: 0,
        almuerzo: 0,
        merienda2: 0,
        cena: 0,
        merienda3: 0,
      },
      source,
      isTemporary: isTemporary
    };

    // Validación de campos requeridos
    if (!tablaData.nombre || !tablaData.dias.length || !tablaData.fechaInicio || !tablaData.userId || !tablaData.source) {
      console.error('Missing required fields:', tablaData);
      Swal.fire({
        title: 'Error!',
        text: 'Faltan campos requeridos. Inténtalo de nuevo.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
      return;
    }

    console.log('Tabla Data a enviar:', tablaData);

    let response;
    if (_id) {
      if (nombre) {
        // Usar PATCH para actualizar solo el nombre de la tabla
        response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${_id}`, { nombre }, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setTablas(prevTablas => prevTablas.map(tabla => (tabla._id === _id ? { ...tabla, nombre: response.data.nombre } : tabla)));
        setActiveTabla(prevActiveTabla => ({ ...prevActiveTabla, nombre: response.data.nombre }));
      } else {
        // Usar PUT para actualizar todos los campos de la tabla
        response = await axios.put(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${_id}`, tablaData, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        setTablas(prevTablas => prevTablas.map(tabla => (tabla._id === _id ? response.data : tabla)));
        setActiveTabla(response.data);
      }
    } else {
      response = await axios.post(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional`, tablaData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      console.log('Respuesta del servidor al crear tabla:', response.data);

      const createdTable = response.data;

      // Asignar la tabla a la invitación temporal
      // if (source === 'nutricionista' && email) {
      //   await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/assign-table-to-invitation`, {
      //     email,
      //     tablaId: createdTable._id,
      //     tipo
      //   }, {
      //     headers: { 'Authorization': `Bearer ${token}` }
      //   });
      // }

      setTablas(prevTablas => [...prevTablas, createdTable]);
      setActiveTabla(createdTable);

      if (onNewTable) onNewTable(createdTable);
    }

    setShowConfigPopup(false);
    Swal.fire('Tabla nutricional guardada', '', 'success');
    console.log('Tabla guardada en la base de datos:', response.data);
  } catch (error) {
    console.error('Error saving table:', error);
    console.log('Datos enviados:', tablaData);
    Swal.fire({
      title: 'Error!',
      text: 'No se pudo guardar la tabla. Inténtalo de nuevo.',
      icon: 'error',
      confirmButtonText: 'OK'
    }).then(() => {
      setShowConfigPopup(true);
    });
  }
};

export default handleConfigSave;