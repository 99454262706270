// components/Administrativo/usuario/UserManagement.js
import React from 'react';
import UserList from './UserList';
import SideMenu from '../../../navigation/SideMenu';
import InternalMenu from '../../../navigation/InternalMenu';

const UserManagement = () => {
  return (
    <div className={`dashboard_flex`}>
      <SideMenu />
      <div className="main_content">
        <InternalMenu />
        <h1>Gestión de Usuarios</h1>
        <UserList />
      </div>
    </div>
  );
};

export default UserManagement;