const es = {
    swal: {
      title: '¿Estás seguro?',
      text: '¿Quieres cerrar la sesión?',
      confirmButtonText: 'Sí, cerrar sesión',
      cancelButtonText: 'No, cancelar',
      confirmedTitle: '¡Cerrado!',
      confirmedText: 'Tu sesión ha sido cerrada.',
    },
    button: {
      logout: 'Cerrar sesión',
    },
  };
  
  export default es;  