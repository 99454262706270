const jaTranslations = {
    menu: {
      statusActive: 'ステータス: アクティブ',
      statusInactive: 'ステータス: 非アクティブ',
      editAccount: 'アカウントを編集',
      settings: '設定',
      support: 'サポート',
      logout: 'ログアウト',
    },
    search: {
      placeholder: '検索…',
    },
    notifications: {
      noNew: '新しい通知はありません',
    },
    languages: {
      es: 'ES',
      en: 'EN',
      ja: 'JP',
      it: 'IT',
    },     
  };
  
  export default jaTranslations;  