import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importar traducciones para cada página/componente e idioma
import DashboardLanguageES from './language/es/DashboardLanguage';
import DashboardLanguageEN from './language/en/DashboardLanguage';
import DashboardLanguageJA from './language/ja/DashboardLanguage';
import DashboardLanguageIT from './language/it/DashboardLanguage';
import InternalMenuEN from './language/en/InternalMenuLanguage';
import InternalMenuES from './language/es/InternalMenuLanguage';
import InternalMenuIT from './language/it/InternalMenuLanguage';
import InternalMenuJA from './language/ja/InternalMenuLanguage';
import SideMenuES from './language/es/SideMenuLanguage';
import SideMenuEN from './language/en/SideMenuLanguage';
import SideMenuIT from './language/it/SideMenuLanguage';
import SideMenuJA from './language/ja/SideMenuLanguage';
import LogoutButtonLanguageES from './language/es/LogoutButtonLanguage';
import LogoutButtonLanguageEN from './language/en/LogoutButtonLanguage';
import LogoutButtonLanguageIT from './language/it/LogoutButtonLanguage';
import LogoutButtonLanguageJA from './language/ja/LogoutButtonLanguage';
import SurpriseMeES from './language/es/SurpriseMeLanguage';
import SurpriseMeEN from './language/en/SurpriseMeLanguage';
import SurpriseMeIT from './language/it/SurpriseMeLanguage';
import SurpriseMeJA from './language/ja/SurpriseMeLanguage'; 

// Configuración de recursos para i18next
const resources = {
  es: {
    translation: {
      ...DashboardLanguageES,
      ...InternalMenuES,
      ...SideMenuES,
      ...LogoutButtonLanguageES,
      ...SurpriseMeES // Agrega las traducciones de SurpriseMe
    }
  },
  en: {
    translation: {
      ...DashboardLanguageEN,
      ...InternalMenuEN,
      ...SideMenuEN,
      ...LogoutButtonLanguageEN,
      ...SurpriseMeEN // Agrega las traducciones de SurpriseMe
    }
  },
  ja: {
    translation: {
      ...DashboardLanguageJA,
      ...InternalMenuJA,
      ...SideMenuJA,
      ...LogoutButtonLanguageJA,
      ...SurpriseMeJA // Agrega las traducciones de SurpriseMe
    }
  },
  it: {
    translation: {
      ...DashboardLanguageIT,
      ...InternalMenuIT,
      ...SideMenuIT,
      ...LogoutButtonLanguageIT,
      ...SurpriseMeIT // Agrega las traducciones de SurpriseMe
    }
  }
};

i18n
  .use(initReactI18next) // Conectar i18next con react-i18next
  .init({
    resources, // Traducciones definidas
    lng: "es", // Idioma por defecto
    fallbackLng: "es", // Idioma de respaldo
    interpolation: {
      escapeValue: false // React ya se encarga de esto
    }
  });

export default i18n;