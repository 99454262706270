// IngredientPage.js
import React, { useState, useCallback, useEffect } from 'react';
import { Button, Container } from '@mui/material';
import axios from 'axios';
import IngredientForm from './IngredientForm';
import IngredientList from './IngredientList';
import useAuthValidation from '../../../../hooks/useAuthValidation';
import SideMenu from '../../../../navigation/SideMenu';
import InternalMenu from '../../../../navigation/InternalMenu';

const IngredientPage = () => {
  const { token } = useAuthValidation();
  const [open, setOpen] = useState(false);
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [ingredients, setIngredients] = useState([]);

  const fetchIngredients = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ingredients/paginacion`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { opciones } = response.data.ingredients[0];
      setIngredients(opciones);
    } catch (error) {
      console.error('Error fetching ingredients', error);
    }
  }, [token]);

  useEffect(() => {
    fetchIngredients();
  }, [fetchIngredients]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedIngredient(null);
  };

  return (
    <div className={`dashboard_flex`}>
      <SideMenu />
        <div className="main_content">
          <InternalMenu />
          <div>
            <Container>
              <Button variant="contained" color="primary" onClick={handleOpen}>
                Agregar Ingrediente
              </Button>
              <IngredientForm
                open={open}
                onClose={handleClose}
                selectedIngredient={selectedIngredient}
                setSelectedIngredient={setSelectedIngredient}
                fetchIngredients={fetchIngredients}
              />
              <IngredientList
                setSelectedIngredient={setSelectedIngredient}
                openForm={handleOpen}
                ingredients={ingredients}
                fetchIngredients={fetchIngredients}
              />
            </Container>
          </div>
        </div>
    </div>
  );
};

export default IngredientPage;