import React from 'react';
import RecipeCard from '../recetas/recipe_card/RecipeCard';
import './SearchResults.css';

const SearchResults = ({ results }) => {
  return (
    <div className="search-results">
      {results.map((recipe) => (
        <RecipeCard key={recipe._id} recipe={recipe} />
      ))}
    </div>
  );
};

export default SearchResults;
