import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import './Home.css';
import Menu from '../navigation/Menu';
// import handleSubscribe from '../navigation/subscriptionHandler';
import { launchConfetti } from './ConfettiUtils';
import Swal from 'sweetalert2';
import axios from 'axios';
import Footer from '../navigation/Footer';
import { Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function Home() {
    const [showMessage, setShowMessage] = useState(false);
    const [openBanner, setOpenBanner] = useState(true);
    const location = useLocation();
    const navigate = useNavigate();
    const emailRef = useRef(null);
    const impactoRef = useRef(null);
    const nosotrosRef = useRef(null);
    const preciosRef = useRef(null);

    const handleCloseBanner = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenBanner(false);
    };

    useEffect(() => {
        // Extraer el token del parámetro de URL
        const urlParams = new URLSearchParams(location.search);
        const token = urlParams.get('token');
    
        // Si hay un token, intentar confirmar la suscripción
        if (token) {
            const confirmAndNavigate = async () => {
                await confirmSubscription(token);
                navigate('/', { replace: true });
            };
    
            confirmAndNavigate();
        }
    }, [location, navigate]);

    const handleSubscribeClick = () => {
        let email = emailRef.current.value;
        // //console.log("Correo electrónico recibido:", email);
        email = email.toLowerCase();
    
        // Validar el correo electrónico
        if (!email || !/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email)) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Es necesario ingresar un correo electrónico válido',
            });
            return;
        }
    
        // Llamar a handleSubscribe
        handleSubscribe(email, () => {
            // Éxito en la suscripción
            Swal.fire({
                icon: 'success',
                title: '¡Casi listo!',
                text: 'Por favor, revisa tu correo electrónico para confirmar la suscripción.',
            });
            emailRef.current.value = '';
        }, () => {
            // Manejar suscripciones duplicadas
            console.error('Suscripción ya existente o duplicada');
        }, (error) => {
            // Manejar otros errores
            console.error('Error al suscribirse:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Este correo electrónico ya está suscrito',
            });
        });
    };

    const handleSubscribe = async (email, onSuccess, onDuplicate, onError) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/subscriptions/subscribe`, { email });
    
            if (response.status === 200) {
                onSuccess();
            } else if (response.status === 400 && response.data.message === 'Ya estás suscrito') {
                onDuplicate();
            } else {
                onError(new Error('Error desconocido al suscribirse'));
            }
        } catch (error) {
            onError(error);
        }
    };

    const confirmSubscription = async (token) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/subscriptions/confirm-subscription?token=${token}`);
            if (response.status === 200) {
                // Mostrar confeti, mensaje, etc.
                launchConfetti();
                setShowMessage(true);
                setTimeout(() => setShowMessage(false), 3000);
            }
        } catch (error) {
            console.error('Error al confirmar la suscripción', error);
        }
    };

    return (
        <div className="home">
            <Menu impactoRef={impactoRef} nosotrosRef={nosotrosRef} preciosRef={preciosRef} />
            <div className='parent-div'>
                <div className="hero-background">
                    <h1 className="hero-title">Sabores Únicos, Salud y Comunidad en Cada Plato </h1>
                    <p className="hero-text">En Unifood, creemos que comer bien es un derecho y un placer para todos. Nuestra misión es llevar a tu mesa recetas adaptadas a tus preferencias y necesidades nutricionales, fomentando un estilo de vida saludable para ti y tu familia.</p>
                    <div className="subscription">
                        <input type="text" ref={emailRef} className="subscription-input" placeholder="Ingrese su correo electrónico" /> 
                        <button className="subscription-button" onClick={handleSubscribeClick}>Suscribirse</button> 
                    </div>
                    {showMessage && <div className="thank-you-message">¡Gracias por suscribirte!</div>}
                </div>
                <div className='plan' ref={preciosRef}>
                    <div>
                        <h2>$1 al mes + impuestos</h2>
                        <p id='familia'>Por familia, con un máximo de 6 miembros</p>
                        <p id='suscripcion'>Si no puedes pagar la suscripción solicitala aquí</p>
                        <Link to="/subsidio" className="boton_solicitar">Solicitar</Link>
                    </div>
                </div>
                <div className="second-div" ref={impactoRef}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" viewBox="0 0 1728 535" fill="none">
                        <path d="M1772 0V534.49C1218.65 265.205 1218.65 265.205 238.745 233.585C279.96 135.986 -1500.55 162.456 -2323 114.752V0H1772Z" fill="#FFC82F"/>
                    </svg>
                    <h2 className='titulo-podemos-ayudarte'>Como podemos ayudarte</h2>
                    <div className='caracteristicas'>
                        <div className='caracteristica-definida verde'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#01B494"/>
                            </svg>
                            <h3>Salud & Nutrición</h3>
                        </div>
                        <div className='caracteristica-definida amarillo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FFC82F"/>
                            </svg>
                            <h3>Presupuesto</h3>
                        </div>
                        <div className='caracteristica-definida rosado'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#F597BB"/>
                            </svg>
                            <h3>Experiencia Culinaria</h3>
                        </div>
                        <div className='caracteristica-definida rojo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FE595D"/>
                            </svg>
                            <h3>Expediente medico & nutricional</h3>
                        </div>
                        <div className='caracteristica-definida amarillo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FFC82F"/>
                            </svg>
                            <h3>Actualización automática</h3>
                        </div>
                        <div className='caracteristica-definida rojo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FE595D"/>
                            </svg>
                            <h3>Recetas para eventos</h3>
                        </div>
                        <div className='caracteristica-definida verde'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#01B494"/>
                            </svg>
                            <h3>Alianza con restaurantes</h3>
                        </div>
                        <div className='caracteristica-definida rosado'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#F597BB"/>
                            </svg>
                            <h3>Recetas para todos</h3>
                        </div>
                        <div className='caracteristica-definida rosado'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#F597BB"/>
                            </svg>
                            <h3>Basado en su presupuesto</h3>
                        </div>
                        <div className='caracteristica-definida verde'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#01B494"/>
                            </svg>
                            <h3>Cocinar lo que amas</h3>
                        </div>
                        <div className='caracteristica-definida amarillo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FFC82F"/>
                            </svg>
                            <h3>Conocer nuevos platillos</h3>
                        </div>
                        <div className='caracteristica-definida rojo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FE595D"/>
                            </svg>
                            <h3>Red social integrada</h3>
                        </div>
                        <div className='caracteristica-definida amarillo'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#FFC82F"/>
                            </svg>
                            <h3>Entretenimiento para cocinar</h3>
                        </div>
                        <div className='caracteristica-definida rosado'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                                <path d="M27.5037 5.00001C26.0485 4.99751 24.6128 5.33462 23.3108 5.98453C22.0087 6.63444 20.8764 7.5792 20.0037 8.74368C18.8217 7.17109 17.1752 6.01 15.2971 5.42471C13.4189 4.83941 11.4043 4.85955 9.53819 5.48227C7.67212 6.10499 6.04913 7.29877 4.89887 8.89467C3.7486 10.4906 3.12933 12.4078 3.12866 14.375C3.12866 25.6036 18.7288 34.4688 19.3929 34.8406C19.5795 34.9451 19.7898 35 20.0037 35C20.2175 35 20.4279 34.9451 20.6145 34.8406C23.4836 33.1602 26.1765 31.1954 28.6523 28.976C34.1109 24.0633 36.8787 19.1508 36.8787 14.375C36.8758 11.8895 35.8872 9.50654 34.1297 7.749C32.3721 5.99146 29.9892 5.00283 27.5037 5.00001Z" fill="#F597BB"/>
                            </svg>
                            <h3>Basado en sus ingredientes</h3>
                        </div>
                    </div>
                </div> {/* fin second div */}
            </div> {/* fin parent div */}
            <div className="third_box" ref={nosotrosRef}>
                <div className='texto_third_box'>
                    <h3>Algunas funciones que debes conocer</h3>
                    <div className='grid_4'>
                        <div className='tab'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" fill="#01B494"/>
                            <path d="M20 11.25V20H28.75" stroke="#F1F3F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" stroke="#01B494" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M30.6066 4.09009L35.9099 9.39339" stroke="#01B494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.0901 9.39339L9.3934 4.09009" stroke="#01B494" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <h4>Experiencia Culinaria</h4>
                            <p>Eleva tu arte culinario. Desde recetas básicas hasta técnicas avanzadas, encuentra el placer de cocinar en cada plato.</p>
                        </div>
                        <div className='tab'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" fill="#F597BB"/>
                            <path d="M20 11.25V20H28.75" stroke="#F1F3F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" stroke="#F597BB" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M30.6066 4.09009L35.9099 9.39339" stroke="#F597BB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.0901 9.39339L9.3934 4.09009" stroke="#F597BB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <h4>Salud & Nutrición</h4>
                            <p>Cocina con consciencia nutricional. Recetas equilibradas para una dieta saludable, adaptadas a tus necesidades y metas de bienestar.</p>
                        </div>
                        <div className='tab'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" fill="#FFC82F"/>
                            <path d="M20 11.25V20H28.75" stroke="#F1F3F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" stroke="#FFC82F" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M30.6066 4.09009L35.9099 9.39339" stroke="#FFC82F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.0901 9.39339L9.3934 4.09009" stroke="#FFC82F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <h4>Presupuesto Consciente</h4>
                            <p>Gastronomía que cuida tu bolsillo. Descubre platos exquisitos que se alinean con tu presupuesto, maximizando sabor y ahorro.</p>
                        </div>
                        <div className='tab'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" fill="#FE595D"/>
                            <path d="M20 11.25V20H28.75" stroke="#F1F3F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75Z" stroke="#FE595D" strokeWidth="2" strokeMiterlimit="10"/>
                            <path d="M30.6066 4.09009L35.9099 9.39339" stroke="#FE595D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.0901 9.39339L9.3934 4.09009" stroke="#FE595D" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <h4>Recetas para Todos</h4>
                            <p>Una diversidad de recetas para satisfacer todos los paladares. Desde veganos hasta carnívoros, hay opciones para cada miembro de la familia.</p>
                        </div>
                    </div>
                </div>
            </div> {/* Fin third box */} 
            <div className='espacio_blanco'></div>
            {/* Fondo Cuatro */}
            <div className='descargar_app'>
                <div className='contenido_derecha'>
                    <h3 className='nordique_bold'>Descárgate la app</h3>
                    <p>Descubre una colección única de recetas adaptadas a tus gustos y necesidades nutricionales. Comienza tu viaje gastronómico con Unifood hoy mismo.</p>
                    <div className='grid_2'>
                        <button className='button_store'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M15.3754 13.5327L12.9266 11.1249L3.93702 19.9897L15.3754 13.5327Z" fill="white"/>
                                <path d="M15.3754 6.97826L3.93702 0.52124L12.9266 9.38608L15.3754 6.97826Z" fill="white"/>
                                <path d="M18.9598 11.4727C19.6929 10.9002 19.6929 9.61 18.9111 9.03752L16.5118 7.67212L13.8348 10.256L16.5118 12.8398L18.9598 11.4727Z" fill="white"/>
                                <path d="M1.58985 20.5066L12.0431 10.2516L1.58985 0.000854443V0C1.06095 0.272567 0.706358 0.768999 0.706358 1.4141V19.0925C0.706358 19.7376 1.06095 20.2341 1.58985 20.5066Z" fill="white"/>
                            </svg>
                            <div className='flex_vertical'>
                                <h6>Download on</h6>
                                <h4>Google Play</h4>
                            </div>
                        </button>
                        <button className='button_store second-button-hover'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
                            <path d="M12.0504 0C12.0971 0 12.1438 0 12.1932 0C12.3079 1.41623 11.7673 2.47444 11.1103 3.24076C10.4657 4.00179 9.58298 4.73989 8.15528 4.6279C8.06004 3.23194 8.60149 2.25222 9.25758 1.48766C9.86605 0.775137 10.9816 0.141094 12.0504 0Z" fill="white"/>
                            <path d="M16.3722 14.7407C16.3722 14.7549 16.3722 14.7672 16.3722 14.7804C15.971 15.9956 15.3987 17.0371 14.7003 18.0036C14.0627 18.881 13.2814 20.0618 11.8863 20.0618C10.6808 20.0618 9.88012 19.2866 8.64466 19.2655C7.33777 19.2443 6.61908 19.9136 5.42418 20.082C5.2875 20.082 5.15081 20.082 5.01677 20.082C4.13934 19.9551 3.43122 19.2602 2.91535 18.6341C1.39417 16.784 0.218681 14.3942 -1.52588e-05 11.336C-1.52588e-05 11.0361 -1.52588e-05 10.7372 -1.52588e-05 10.4374C0.0925779 8.24864 1.15608 6.46908 2.56967 5.60665C3.3157 5.14809 4.34128 4.75743 5.48326 4.93204C5.97269 5.00788 6.47269 5.17543 6.91096 5.34121C7.32631 5.50082 7.84571 5.7839 8.33778 5.7689C8.67112 5.7592 9.00269 5.58548 9.33867 5.46291C10.3228 5.10752 11.2875 4.70011 12.5591 4.89147C14.0874 5.12252 15.172 5.80153 15.8422 6.84916C14.5495 7.67191 13.5274 8.91178 13.702 11.0291C13.8572 12.9524 14.9754 14.0776 16.3722 14.7407Z" fill="white"/>
                        </svg>
                            <div className='flex_vertical'>
                                <h6>Download on</h6>
                                <h4>Apple Store</h4>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {/* Fin Fondo Cuatro */}
            {/* Fondo Cinco */}
            <div className='como_funciona'>
                <div className='como_funciona_interno'>
                    <div>
                        <h3 className='nordique_bold'>Cómo funciona</h3>
                        <p className='nordique_normal'>En Unifood, nos dedicamos a ofrecer recetas personalizadas que se ajustan a tus objetivos de salud y preferencias culinarias, todo con un toque de creatividad y sabor.</p>
                        <button>Contribuir</button>
                    </div>
                    <div className='espacio_50'></div>
                </div>
            </div>
            {/* Fin Fondo Cinco */}
            {/* Fondo Seis */}
            <div className='fondo_seis'>
                <h2 className='nordique_bold'>Más detalles sobre lo que puedes hacer con nuestra aplicación</h2>
                <div className='detalles_fondo_seis'>
                    <div className='background-columna1'>
                        <h3 className='nordique_bold'>Cultura culinaria al alcance de todos</h3>
                    </div>
                    <div className='background-columna2-1'>
                        <h4 className='nordique_bold'>Cultura culinaria al alcance de todos</h4>
                    </div>
                    <div className='background-columna2-2'>
                        <h4 className='nordique_bold'>Cultura culinaria al alcance de todos</h4>
                    </div>
                    <div className='background-columna3-1'>
                        <h4 className='nordique_bold'>Cultura culinaria al alcance de todos</h4>
                    </div>
                    <div className='background-columna3-2'>
                        <h4 className='nordique_bold'>Cultura culinaria al alcance de todos</h4>
                    </div>
                </div>
            </div>
            {/* Fin Fondo Seis */}
            {/* Fondo Siete */}
            <div className='fondo_siete'>
            <div className='contenido_derecha'>
                    <h3 className='nordique_bold'>Descárgate la app</h3>
                    <p>Descubre una colección única de recetas adaptadas a tus gustos y necesidades nutricionales. Comienza tu viaje gastronómico con Unifood hoy mismo.</p>
                    <div className='grid_2'>
                        <button className='button_store'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                <path d="M15.3754 13.5327L12.9266 11.1249L3.93702 19.9897L15.3754 13.5327Z" fill="white"/>
                                <path d="M15.3754 6.97826L3.93702 0.52124L12.9266 9.38608L15.3754 6.97826Z" fill="white"/>
                                <path d="M18.9598 11.4727C19.6929 10.9002 19.6929 9.61 18.9111 9.03752L16.5118 7.67212L13.8348 10.256L16.5118 12.8398L18.9598 11.4727Z" fill="white"/>
                                <path d="M1.58985 20.5066L12.0431 10.2516L1.58985 0.000854443V0C1.06095 0.272567 0.706358 0.768999 0.706358 1.4141V19.0925C0.706358 19.7376 1.06095 20.2341 1.58985 20.5066Z" fill="white"/>
                            </svg>
                            <div className='flex_vertical'>
                                <h6>Download on</h6>
                                <h4>Google Play</h4>
                            </div>
                        </button>
                        <button className='button_store second-button-hover'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="21" viewBox="0 0 17 21" fill="none">
                            <path d="M12.0504 0C12.0971 0 12.1438 0 12.1932 0C12.3079 1.41623 11.7673 2.47444 11.1103 3.24076C10.4657 4.00179 9.58298 4.73989 8.15528 4.6279C8.06004 3.23194 8.60149 2.25222 9.25758 1.48766C9.86605 0.775137 10.9816 0.141094 12.0504 0Z" fill="white"/>
                            <path d="M16.3722 14.7407C16.3722 14.7549 16.3722 14.7672 16.3722 14.7804C15.971 15.9956 15.3987 17.0371 14.7003 18.0036C14.0627 18.881 13.2814 20.0618 11.8863 20.0618C10.6808 20.0618 9.88012 19.2866 8.64466 19.2655C7.33777 19.2443 6.61908 19.9136 5.42418 20.082C5.2875 20.082 5.15081 20.082 5.01677 20.082C4.13934 19.9551 3.43122 19.2602 2.91535 18.6341C1.39417 16.784 0.218681 14.3942 -1.52588e-05 11.336C-1.52588e-05 11.0361 -1.52588e-05 10.7372 -1.52588e-05 10.4374C0.0925779 8.24864 1.15608 6.46908 2.56967 5.60665C3.3157 5.14809 4.34128 4.75743 5.48326 4.93204C5.97269 5.00788 6.47269 5.17543 6.91096 5.34121C7.32631 5.50082 7.84571 5.7839 8.33778 5.7689C8.67112 5.7592 9.00269 5.58548 9.33867 5.46291C10.3228 5.10752 11.2875 4.70011 12.5591 4.89147C14.0874 5.12252 15.172 5.80153 15.8422 6.84916C14.5495 7.67191 13.5274 8.91178 13.702 11.0291C13.8572 12.9524 14.9754 14.0776 16.3722 14.7407Z" fill="white"/>
                        </svg>
                            <div className='flex_vertical'>
                                <h6>Download on</h6>
                                <h4>Apple Store</h4>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {/* Fin Fondo Siete */}
            {/* Fondo Ocho */}
            <div className='fondo_ocho'>
                <div className='contenido_derecha'>
                    <h2 className='nordique_bold'>Accesibilidad, salud, nutrición y degustación al alcance de todos.</h2>
                    <p className='montserrat_500'>Comer es un derecho para todos y esta es nuestra mayor meta, por eso somos accesibles para todos. </p>
                </div>
            </div>
            {/* Fin Fondo Ocho */}
            {/* Fondo Nueve */}
            <div className='fondo_nueve'>
                <div className='div_texto'>
                    <h2 className='nordique_bold'>Se parte de nuestro viaje</h2>
                    <p className='nordique_normal'>Ayúdanos a crecer, con tu contribución tendremos recursos para seguir desarrollando Unifood.</p>
                </div>
                <div className='div_form'>
                    <h3>Colabora con nosotros</h3>
                    <p>Excepteur sint occaecat cupidatat non proident sunt in culpa qui officia </p>
                    <form>
                        <div className='check'>
                            <label className='container' htmlFor="contribuir" id='c1'> Contribuir
                                <input type='checkbox' id='contribuir' name='contribuir' value='contribuir'/>
                                <span className="checkmark"></span>
                            </label>
                        </div>
                        <button>Siguiente</button>
                    </form>
                </div>
            </div>
            {/* Fin Fondo Nueve */}
            {/* Fondo Diez */}
            <div className='titulo_parrafo_fondo_diez'>
                <h2 className='nordique_bold'>Contáctenos</h2>
                <p className='nordique_normal'>Si tiene alguna pregunta o comentario, no dude en comunicarse con nosotros. Siempre estamos disponibles para ayudar.</p>
            </div>
            <div className='fondo_diez'>
                <form className='form'>
                    <div className='form_input'>
                        <div className='label_arriba'>
                            <label htmlFor="nombre">Nombre</label>
                            <input type="text" id='nombre' name='nombre' placeholder='Nombre' className='contacto_input'/>
                        </div>
                        <div className='label_arriba'>
                            <label htmlFor="apellido">Apellido</label>
                            <input type="text" id='apellido' name='apellido' placeholder='Apellido' className='contacto_input'/>
                        </div>
                    </div>
                    <div className='form_input'>
                        <div className='label_arriba'>
                            <label htmlFor="email">Email</label>
                            <input type="email" id='email' name='email' placeholder='Email' className='contacto_input'/>
                        </div>
                        <div className='label_arriba'>
                            <label htmlFor="phone">Teléfono</label>
                            <input type="tel" id='phone' name='phone' placeholder='Teléfono' className='contacto_input'/>
                        </div>
                    </div>
                    <div className='form_input'>
                        <div className='label_arriba'>
                            <label htmlFor="mensaje">Mensaje</label>
                            <textarea type="text" id='mensaje' name='mensaje' placeholder='Mensaje' rows="10" className='contacto_input'/>
                        </div>
                    </div>
                    <button className='boton_form'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 4.52765C9.64418 2.41689 6.02125 2.49347 3.75736 4.75736C1.41421 7.10051 1.41421 10.8995 3.75736 13.2426L10.5858 20.0711C11.3668 20.8521 12.6332 20.8521 13.4142 20.0711L20.2426 13.2426C22.5858 10.8995 22.5858 7.10051 20.2426 4.75736C17.9787 2.49347 14.3558 2.41689 12 4.52765ZM10.8284 6.17157L11.2929 6.63604C11.6834 7.02656 12.3166 7.02656 12.7071 6.63604L13.1716 6.17157C14.7337 4.60948 17.2663 4.60948 18.8284 6.17157C20.3905 7.73367 20.3905 10.2663 18.8284 11.8284L12 18.6569L5.17157 11.8284C3.60948 10.2663 3.60948 7.73367 5.17157 6.17157C6.73367 4.60948 9.26633 4.60948 10.8284 6.17157Z" fill="#F9FBFF"/>
                        </svg>
                        Contáctanos
                    </button>
                </form>
            </div>
            {/* Fin Fondo Diez */}
            <Snackbar
                open={openBanner}
                onClose={handleCloseBanner}
                message="Aplicación web en construcción. Cambios constantes y cualquier dato registrado será eliminado. No se recomienda hacer uso de la aplicación ya que está en un entorno de prueba. Espera pronto la gran apertura. Síguenos en Facebook e Instagram como unifoodcr."
                action={
                    <>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleCloseBanner}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                        <button
                            onClick={handleCloseBanner}
                            style={{ color: 'inherit', background: 'none', border: 'none', cursor: 'pointer', padding: 0, margin: 0, textDecoration: 'underline' }}
                        >
                            Aceptar
                        </button>
                    </>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                sx={{ mb: 2 }} // Añade margen inferior para que el banner no esté pegado al borde inferior
            />
            <Footer handleSubscribeClick={handleSubscribeClick} showMessage={showMessage} />
        </div>
    );
}

export default Home;