import React, { useState } from 'react';
import InvitacionesPanel from './invitaciones/InvitacionesPanel';
import AdministrarPacientes from './pacientes/AdministrarPacientes';
import SideMenu from '../../navigation/SideMenu';
import InternalMenu from '../../navigation/InternalMenu';
import AsignarPacientesPopup from './pacientes/AsignarPacientesPopup';

const NutricionistaPanel = () => {
    console.log("NutricionistaPanel component has been rendered.");
    
    const [activePanel, setActivePanel] = useState('invitaciones');
    const [showPopup, setShowPopup] = useState(false);

    const handlePanelChange = (panel) => {
        setActivePanel(panel);
    };

    const handleShowPopup = () => {
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <div className='dashboard_flex'>
            <SideMenu />
            <div className='main_content'>
                <InternalMenu />
                <h1>Panel de Nutricionista</h1>
                <div className='horizontal_menu'>
                    <button onClick={() => handlePanelChange('invitaciones')}>Invitar Paciente</button>
                    <button onClick={() => handlePanelChange('administrar')}>Administrar Pacientes</button>
                    <button onClick={handleShowPopup}>Asignar Pacientes</button>
                </div>
                {activePanel === 'invitaciones' && <InvitacionesPanel />}
                {activePanel === 'administrar' && <AdministrarPacientes />}
                {showPopup && <AsignarPacientesPopup onClose={handleClosePopup} />}
            </div>
        </div>
    );
};

export default NutricionistaPanel;