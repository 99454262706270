import confetti from 'canvas-confetti';

export const launchConfetti = () => {
    confetti({
        particleCount: 150,      // Aumenta el número de partículas
        spread: 160,             // Aumenta el ángulo de dispersión
        startVelocity: 30,       // Velocidad inicial de las partículas
        origin: { y: 0.6 },      // Punto de origen (en este caso, un poco más alto en la pantalla)
        angle: 90,               // Ángulo en el que se dispara el confeti
        width: 100,               // Ancho de cada confeti
        height: 150,              // Altura de cada confeti
        duration: 3000           // Duración de la animación en milisegundos
    });
};