// NUEVO CODIGO SIN USERAGENT
import crypto from 'crypto';

const COMPLEX_SECRET = process.env.REACT_APP_COMPLEX_SECRET;

export function generateSignature(token) {
    const secret = COMPLEX_SECRET;
    const frontendSecret = process.env.REACT_APP_FRONTEND_SECRET;
    const signature = crypto.createHash('sha256').update(token + secret + frontendSecret).digest('hex');
    
    return signature;
}