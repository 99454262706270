import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from './AuthContext';

const PrivateRoute = () => {
  const { isLoggedIn, isLoading, hasCompletedForm } = useContext(AuthContext);

  if (isLoading) {
    return <div>Cargando...</div>; // o cualquier indicador de carga
  }

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (!hasCompletedForm) {
    return <Navigate to="/bienvenida" />;
  }

  return <Outlet />;
};

export default PrivateRoute;