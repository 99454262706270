import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Container, Typography, Box, Button, CircularProgress, TextField, Grid } from '@mui/material';
import IngredientForm from './IngredientForm';
import InstructionList from './InstructionList';
import NutritionalInfo from './NutritionalInfo';
import SelectImageModal from './SelectImageModal';
import TagsInput from './TagsInput';
import IngredientValidation from '../IngredientValidation';
import useAuthValidation from '../../../../hooks/useAuthValidation';
import useFetchIngredients from '../../../../hooks/useFetchIngredients';
import SideMenu from '../../../../navigation/SideMenu';
import InternalMenu from '../../../../navigation/InternalMenu';
import './IngredientRecipeForm.css';

const IngredientRecipeForm = () => {
  const { token } = useAuthValidation();
  const [nombre, setNombre] = useState('');
  const [nombreEstado, setNombreEstado] = useState('');
  const [categoria, setCategoria] = useState('');
  const [dificultad, setDificultad] = useState('');
  const [instrucciones, setInstrucciones] = useState([{ descripcion: '', imagen: '', video: '', prompt: '', tiempo: '', tieneCronometro: false }]);
  const [informacionNutricional, setInformacionNutricional] = useState({
    calorias: 'N/A',
    proteinas: 'N/A',
    carbohidratos: 'N/A',
    grasas: 'N/A'
  });
  const [imagenReceta, setImagenReceta] = useState('');
  const [imagenRecetaIA, setImagenRecetaIA] = useState('');
  const [generandoImagen, setGenerandoImagen] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalTarget, setImageModalTarget] = useState(null);
  const [selectedInstructionIndex, setSelectedInstructionIndex] = useState(null);
  const [promptHistory, setPromptHistory] = useState('');
  const [tags, setTags] = useState([]);
  const [tiempoPreparacion, setTiempoPreparacion] = useState('');
  const [tiempoCoccion, setTiempoCoccion] = useState('');
  const [promptReceta, setPromptReceta] = useState('');
  const { ingredientes } = useFetchIngredients(token);
  const [ingredientesSeleccionados, setIngredientesSeleccionados] = useState([]);

  const allFieldsFilled = nombre && nombreEstado && categoria && dificultad && tiempoPreparacion && tags.length > 0 && (imagenRecetaIA || imagenReceta) && ingredientesSeleccionados.length > 0;
  const allInstructionsFilled = instrucciones.every(instruccion => instruccion.descripcion.trim() !== '');

  const isFormValid = allFieldsFilled && allInstructionsFilled;

  useEffect(() => {
    console.log('Updated imagenReceta:', imagenReceta);
  }, [imagenReceta]);

  function convertirUnidad(valor, unidadOrigen, unidadDestino) {
    const conversiones = {
        g: { g: 1, mg: 0.001, kg: 1000 },
        mg: { g: 0.001, mg: 1, kg: 1000000 },
        kg: { g: 1000, mg: 1000000, kg: 1 },
        ml: { ml: 1, l: 0.001, cl: 0.1 },
        l: { ml: 1000, l: 1, cl: 100 },
        cl: { ml: 10, l: 0.01, cl: 1 },
    };

    const valorConvertido = valor * (conversiones[unidadOrigen]?.[unidadDestino] || 1);
    return { valor: valorConvertido, unidad: unidadDestino };
  }

  const calcularInformacionNutricional = useCallback(() => {
    const totales = ingredientesSeleccionados.reduce((acumulado, ingrediente) => {
      const calorias = convertirUnidad(ingrediente.caloriasPorUnidad, ingrediente.unidadCalorias, 'g');
      const proteinas = convertirUnidad(ingrediente.proteinas.valor, ingrediente.proteinas.unidad, 'g');
      const carbohidratos = convertirUnidad(ingrediente.carbohidratos.valor, ingrediente.carbohidratos.unidad, 'g');
      const grasas = convertirUnidad(ingrediente.grasas.valor, ingrediente.grasas.unidad, 'g');
      
      return {
        calorias: {
          valor: acumulado.calorias.valor + calorias.valor,
          unidad: calorias.unidad
        },
        proteinas: {
          valor: acumulado.proteinas.valor + proteinas.valor,
          unidad: proteinas.unidad
        },
        carbohidratos: {
          valor: acumulado.carbohidratos.valor + carbohidratos.valor,
          unidad: carbohidratos.unidad
        },
        grasas: {
          valor: acumulado.grasas.valor + grasas.valor,
          unidad: grasas.unidad
        }
      };
    }, { 
      calorias: { valor: 0, unidad: 'g' }, 
      proteinas: { valor: 0, unidad: 'g' }, 
      carbohidratos: { valor: 0, unidad: 'g' }, 
      grasas: { valor: 0, unidad: 'g' }
    });
  
    setInformacionNutricional(totales);
  }, [ingredientesSeleccionados]);  

  useEffect(() => {
    calcularInformacionNutricional();
  }, [ingredientesSeleccionados, calcularInformacionNutricional]);

  const handleAddIngredient = (nuevoIngrediente) => {
    if (Array.isArray(ingredientesSeleccionados)) {
      setIngredientesSeleccionados([...ingredientesSeleccionados, nuevoIngrediente]);
    } else {
      console.error('ingredientesSeleccionados is not an array:', ingredientesSeleccionados);
    }
  };

  const validateEstadoInContent = () => {
    if (!nombreEstado || nombreEstado === 'ninguno') return true;

    const estadoRegex = new RegExp(`\\b${nombreEstado}\\b`, 'i');
    const isInName = estadoRegex.test(nombre);
    const isInInstructions = instrucciones.some(instruccion => estadoRegex.test(instruccion.descripcion));

    return isInName && isInInstructions;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid) {
      Swal.fire('Error', 'Todos los campos obligatorios deben estar llenos y las instrucciones deben estar completas.', 'error');
      return;
    }

    if (!validateEstadoInContent()) {
      Swal.fire('Error', 'El estado seleccionado debe estar presente tanto en el nombre de la receta como en al menos una instrucción.', 'error');
      return;
    }

    const nuevaReceta = {
      nombre,
      nombreEstado,
      categoria,
      ingredientes: ingredientesSeleccionados,
      instrucciones,
      dificultad,
      informacionNutricional,
      imagenReceta: imagenRecetaIA || imagenReceta,
      tags,
      tiempoPreparacion,
      tiempoCoccion
    };

    axios.post(`${process.env.REACT_APP_API_URL}/api/ingredient-recipes`, nuevaReceta, {
      headers: { 'Authorization': `Bearer ${token}` }
    })
      .then(response => {
        console.log('Receta creada:', response.data);
        resetForm();
        Swal.fire('Receta Creada', 'La receta de ingrediente se ha creado exitosamente', 'success');
      })
      .catch(error => {
        console.error('Error creating recipe:', error);
        Swal.fire('Error', 'Ocurrió un error al crear la receta de ingrediente', 'error');
      });
  };

  const resetForm = () => {
    setNombre('');
    setNombreEstado('');
    setCategoria('');
    setDificultad('');
    setInstrucciones([{ descripcion: '', imagen: '', video: '', prompt: '', tiempo: '', tieneCronometro: false }]);
    setInformacionNutricional({ calorias: 'N/A', proteinas: 'N/A', carbohidratos: 'N/A', grasas: 'N/A' });
    setImagenReceta('');
    setTags([]);
    setTiempoPreparacion('');
    setTiempoCoccion('');
    setPromptReceta('');
    setImagenRecetaIA('');
    setIngredientesSeleccionados([]);
  };

  const handleUploadRecipeImage = async () => {
    if (!imagenReceta) return;

    const formData = new FormData();
    formData.append('image', imagenReceta);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const imageUrl = response.data.url;
      setImagenReceta(imageUrl);
      console.log(`Imagen de receta subida, URL: ${imageUrl}`);
    } catch (error) {
      console.error('Error uploading recipe image:', error);
      Swal.fire('Error', 'Ocurrió un error al subir la imagen de la receta', 'error');
    }
  };

  const handleGenerateImageIA = async () => {
    if (!promptReceta) {
      Swal.fire('Error', 'Debe ingresar un prompt para generar la imagen de la receta', 'error');
      return;
    }

    setGenerandoImagen(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-image`, { prompt: promptReceta }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const localImageUrl = response.data.imageUrl;
      console.log(`Generated image URL: ${localImageUrl}`);

      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload/generateImageUrl`, { imageUrl: localImageUrl }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const publicUrl = uploadResponse.data.url;
      console.log(`Public URL of uploaded image: ${publicUrl}`);
      setImagenRecetaIA(publicUrl);
    } catch (error) {
      console.error('Error generating or uploading image:', error);
      Swal.fire('Error', 'Ocurrió un error al generar o subir la imagen', 'error');
    } finally {
      setGenerandoImagen(false);
    }
  };

  const removeIngredient = (index) => {
    setIngredientesSeleccionados(prev => prev.filter((_, i) => i !== index));
  };

  const handleRemoveImage = () => {
    setImagenReceta('');
  };

  const handleOpenImageModal = (target, index = null) => {
    setImageModalTarget(target);
    setSelectedInstructionIndex(index);
    setOpenImageModal(true);
  };

  const handleImageSelect = (url) => {
    if (imageModalTarget === 'recipe') {
      setImagenReceta(url);
    } else if (imageModalTarget === 'instruction') {
      const newInstructions = [...instrucciones];
      newInstructions[selectedInstructionIndex].imagen = url;
      setInstrucciones(newInstructions);
    }
    setOpenImageModal(false);
  };

  return (
    <div className={`dashboard_flex app-container`}>
      <SideMenu />
      <div className="main_content">
        <InternalMenu />
        <div>
          <Container maxWidth="md">
            <Grid container spacing={3}>
              <Grid item xs={12} md={8}>
                <form onSubmit={handleSubmit} className="ingredient-recipe-form">
                  <Typography variant="h4" className="nordique_bold" gutterBottom>Crear Receta de Ingrediente</Typography>
                  <IngredientForm 
                    nombre={nombre} setNombre={setNombre} 
                    nombreEstado={nombreEstado} setNombreEstado={setNombreEstado}
                    categoria={categoria} setCategoria={setCategoria}
                    dificultad={dificultad} setDificultad={setDificultad}
                    ingredientes={ingredientes}
                    ingredientesSeleccionados={ingredientesSeleccionados}
                    setIngredientesSeleccionados={setIngredientesSeleccionados}
                    onAddIngredient={handleAddIngredient}
                  />
                  <InstructionList 
                    instrucciones={instrucciones} 
                    setInstrucciones={setInstrucciones}
                    generandoImagen={generandoImagen}
                    setGenerandoImagen={setGenerandoImagen}
                    token={token}
                    promptHistory={promptHistory}
                    setPromptHistory={setPromptHistory}
                    setOpenImageModal={(index) => handleOpenImageModal('instruction', index)}
                    nombreEstado={nombreEstado}
                  />
                  <NutritionalInfo informacionNutricional={informacionNutricional} />
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Tiempo de Preparación (minutos)"
                      value={tiempoPreparacion}
                      onChange={(e) => setTiempoPreparacion(e.target.value)}
                      variant="outlined"
                      required
                    />
                  </Box>
                  <Box mb={2}>
                    <TextField
                      fullWidth
                      type="number"
                      label="Tiempo de Cocción (minutos)"
                      value={tiempoCoccion}
                      onChange={(e) => setTiempoCoccion(e.target.value)}
                      variant="outlined"
                    />
                  </Box>
                  <TagsInput tags={tags} setTags={setTags} />
                  <Box mt={2}>
                    <Typography variant="h6" className="nordique_normal" gutterBottom>Imagen de la Receta:</Typography>
                    <Button variant="contained" component="label">
                      Subir Imagen
                      <input type="file" accept="image/*" hidden onChange={(e) => {
                        const file = e.target.files[0];
                        console.log('File selected for recipe image:', file);
                        setImagenReceta(file);
                      }} />
                    </Button>
                    {imagenReceta && (
                      <Button onClick={handleUploadRecipeImage} variant="outlined" color="primary">
                        Subir
                      </Button>
                    )}
                    {imagenReceta && (
                      <Box mt={2}>
                        <Typography variant="body1">Imagen Subida:</Typography>
                        <img src={imagenReceta} alt="Imagen de la receta" style={{ maxWidth: '100%' }} />
                        <Button onClick={handleRemoveImage} variant="outlined" color="secondary" style={{ marginLeft: '10px' }}>
                          Eliminar Imagen
                        </Button>
                      </Box>
                    )}
                    <Box mt={2}>
                      <Button variant="contained" color="primary" onClick={() => handleOpenImageModal('recipe')}>
                        Seleccionar Imagen desde Biblioteca
                      </Button>
                      <TextField
                        fullWidth
                        label="Prompt para generar imagen por IA"
                        value={promptReceta}
                        onChange={(e) => setPromptReceta(e.target.value)}
                        variant="outlined"
                        placeholder="Describe cómo debería verse la receta final"
                      />
                      <Button onClick={handleGenerateImageIA} variant="contained" color="primary" disabled={generandoImagen}>
                        Generar Imagen por IA
                      </Button>
                      {generandoImagen && (
                        <Box display="flex" alignItems="center" mt={2}>
                          <CircularProgress size={24} />
                          <Typography variant="body1" ml={2}>Generando imagen...</Typography>
                        </Box>
                      )}
                    </Box>
                    {imagenRecetaIA && (
                      <Box mt={2}>
                        <Typography variant="body1">Imagen Generada:</Typography>
                        <img src={imagenRecetaIA} alt="Receta Generada" style={{ maxWidth: '100%' }} />
                      </Box>
                    )}
                  </Box>
                  <Button type="submit" variant="contained" color="primary" disabled={!isFormValid}>Crear Receta de Ingrediente</Button>
                </form>
              </Grid>
              <Grid item xs={12} md={4}>
                <IngredientValidation 
                  ingredientes={ingredientesSeleccionados} 
                  instrucciones={instrucciones}
                  removeIngredient={removeIngredient}
                />
              </Grid>
            </Grid>
          </Container>
        </div>
      </div>
      <SelectImageModal open={openImageModal} handleClose={() => setOpenImageModal(false)} handleSelect={handleImageSelect} />
    </div>
  );
};

export default IngredientRecipeForm;