import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import './LogoutButton.css';

function LogoutButton({ isShown = true, className = "" }) {
  const { t } = useTranslation();
  const { isLoggedIn, isLoading, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  if (!isLoggedIn || !isShown) {
    return null;
  }

  const handleLogout = async () => {
    const result = await Swal.fire({
      title: t('swal.title'),
      text: t('swal.text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('swal.confirmButtonText'),
      cancelButtonText: t('swal.cancelButtonText')
    });

    if (result.isConfirmed) {
      logout();
      navigate('/login');
    }
  };

  if (isLoading) {
    return null;
  }

  if (location.pathname === '/login' || location.pathname === '/' || location.pathname === '/home' ) {
    return null;
  }

  return isLoggedIn && (
    <button className={`cerrar-sesion-boton ${className}`} onClick={handleLogout}>
      {t('button.logout')}
    </button>
  );
}

export default LogoutButton;