import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Grid } from '@mui/material';
import axios from 'axios';
import useAuthValidation from '../../../../hooks/useAuthValidation';
import './SelectImageModal.css';

const SelectImageModal = ({ open, handleClose, handleSelect }) => {
  const { token } = useAuthValidation();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (open) {
      // Fetch images from GCS via your API
      axios.get(`${process.env.REACT_APP_API_URL}/api/upload/list`, {
        headers: { 'Authorization': `Bearer ${token}` }
      })
        .then(response => {
          setImages(response.data);
        })
        .catch(error => {
          console.error('Error fetching images:', error);
        });
    }
  }, [open, token]);

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="modalBox">
        <h2>Seleccionar Imagen</h2>
        <Grid container spacing={2}>
          {images.map((image, index) => (
            <Grid item xs={4} key={index}>
              <img
                src={image.url}
                alt={`gcs-image-${index}`}
                className="imageItem"
                onClick={() => handleSelect(image.url)}
              />
            </Grid>
          ))}
        </Grid>
        <Button onClick={handleClose}>Cerrar</Button>
      </Box>
    </Modal>
  );
};

export default SelectImageModal;