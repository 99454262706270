import React from 'react';
import { Box, Typography } from '@mui/material';

const NutritionalInfo = ({ informacionNutricional }) => {
  return (
    <Box mb={2} className="nutritional-info-box">
      <Typography variant="body1">
        Calorías: {informacionNutricional.calorias.valor} {informacionNutricional.calorias.unidad}
      </Typography>
      <Typography variant="body1">
        Proteínas: {informacionNutricional.proteinas.valor} {informacionNutricional.proteinas.unidad}
      </Typography>
      <Typography variant="body1">
        Carbohidratos: {informacionNutricional.carbohidratos.valor} {informacionNutricional.carbohidratos.unidad}
      </Typography>
      <Typography variant="body1">
        Grasas: {informacionNutricional.grasas.valor} {informacionNutricional.grasas.unidad}
      </Typography>
    </Box>
  );
};

export default NutritionalInfo;