import React, { useState, useEffect } from 'react';
import { TextField, Autocomplete, Checkbox, Chip } from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CustomDropdown from './CustomDropdown';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function QuestionGroup({ preguntas, opciones, formData, updateFormData, userCountry, ingredientesCategorias }) {
  const [noAplicaSelected, setNoAplicaSelected] = useState({});
  const [disableQuestion9, setDisableQuestion9] = useState(false);

  useEffect(() => {}, [opciones, userCountry]);

  const handleMultipleChange = (event, newValue, pregunta) => {
    // console.log('handleMultipleChange - newValue:', newValue);
    let updatedValue = newValue;

    if (Array.isArray(newValue) && newValue.some(op => op.value === 'No aplica')) {
      setNoAplicaSelected({ ...noAplicaSelected, [pregunta.nombreCampo]: true });
      updatedValue = [{ label: 'No aplica', value: 'No aplica' }];
    } else if (noAplicaSelected[pregunta.nombreCampo]) {
      setNoAplicaSelected({ ...noAplicaSelected, [pregunta.nombreCampo]: false });
      updatedValue = newValue.filter(op => op.value !== 'No aplica');
    }

    const dataToUpdate = updatedValue.map(option => {
      if (pregunta.nombreCampo === 'alimentosQueNoGustan' || [2, 4, 5, 6].includes(pregunta.id)) {
        return {
          id: option.id,
          nombre: option.nombre,
          refModel: option.refModel
        };
      }

      return {
        id: option.value === 'No aplica' ? 'No aplica' : option.value,
        nombre: option.label,
        refModel: option.value === 'No aplica' ? null : pregunta.categoria === 'recipes' ? 'Recipe' : pregunta.categoria
      };
    });

    // console.log('handleMultipleChange - dataToUpdate:', dataToUpdate);
    updateFormData(pregunta.nombreCampo, dataToUpdate);
  };

  const handleSingleChange = (event, newValue, pregunta) => {
    console.log('handleSingleChange - newValue:', newValue);
    const updatedValue = newValue ? [newValue] : [];

    if (pregunta.id === 8) {
      const isVegetarianOrVegan = updatedValue.some(option => option.label === 'Vegetariana' || option.label === 'Vegana');
      setDisableQuestion9(isVegetarianOrVegan);
      if (isVegetarianOrVegan) {
        updateFormData('frecuenciaConsumoCarne', []);
      }
    }

    const dataToUpdate = updatedValue.map(option => ({
      id: option.value,
      nombre: option.label,
      refModel: pregunta.categoria
    }));

    // console.log('handleSingleChange - dataToUpdate:', dataToUpdate);
    updateFormData(pregunta.nombreCampo, dataToUpdate);
  };

  const handleChange = (event, newValue, pregunta) => {
    // console.log('handleChange - pregunta:', pregunta);
    // console.log('handleChange - newValue:', newValue);
    if (pregunta.id === 8 || pregunta.id === 9) {
      handleSingleChange(event, newValue, pregunta);
    } else {
      handleMultipleChange(event, newValue, pregunta);
    }
  };

  const handleNoAplicaChange = (isSelected) => {
    // console.log('handleNoAplicaChange - isSelected:', isSelected);
    setNoAplicaSelected(isSelected);
  };

  return (
    <div>
      {preguntas.map((pregunta) => {
        console.log('Rendering pregunta:', pregunta);
        if (pregunta.id === 9 && disableQuestion9) {
          return null;
        }

        let opcionesParaPregunta = [];

        if (pregunta.nombreCampo === 'platillosFavoritos') {
          opcionesParaPregunta = opciones.recipes || [];
        } else if (pregunta.nombreCampo === 'platillosInternacionales') {
          opcionesParaPregunta = opciones.recipes.filter(recipe => recipe.pais !== userCountry) || [];
        } else if (pregunta.categoria === 'paises') {
          opcionesParaPregunta = opciones.paises || [];
        } else {
          opcionesParaPregunta = opciones[pregunta.categoria] || [];
        }

        console.log('opcionesParaPregunta:', opcionesParaPregunta);

        if ([4, 5, 6, 7, 10, 11, 12, 13, 15].includes(pregunta.id) && !opcionesParaPregunta.some(op => op.value === 'No aplica')) {
          opcionesParaPregunta = [{ label: 'No aplica', value: 'No aplica' }, ...opcionesParaPregunta];
        }

        if (noAplicaSelected[pregunta.nombreCampo]) {
          opcionesParaPregunta = opcionesParaPregunta.filter(op => op.value === 'No aplica');
        }

        if ([2, 4, 5, 6].includes(pregunta.id)) {
          return (
            <div key={pregunta.id}>
              <label>{pregunta.texto}</label>
              <CustomDropdown
                label={pregunta.texto}
                options={ingredientesCategorias}
                selectedOptions={formData[pregunta.nombreCampo] || []}
                onChange={(newSelectedOptions) => handleChange(null, newSelectedOptions, pregunta)}
                allowNoAplica={pregunta.id !== 2}
                onNoAplicaChange={(isSelected) => handleNoAplicaChange(isSelected)}
              />
            </div>
          );
        }

        return (
          <div key={pregunta.id}>
            <label>{pregunta.texto}</label>
            {pregunta.id === 8 || pregunta.id === 9 ? (
              <Autocomplete
                options={opcionesParaPregunta}
                getOptionLabel={(option) => option.label || ""}
                value={formData[pregunta.nombreCampo]?.map(value => opcionesParaPregunta.find(option => option.value === value.id) || { label: value.nombre, value: value.id })[0] || null}
                onChange={(event, newValue) => handleChange(event, newValue, pregunta)}
                isOptionEqualToValue={(option, value) => {
                  console.log('isOptionEqualToValue - option:', option, 'value:', value);
                  return option.value === value.value;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={pregunta.texto}
                    variant="outlined"
                    error={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0}
                    helperText={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0 ? 'Este campo es requerido' : ''}
                  />
                )}
              />
            ) : (
              <Autocomplete
                multiple
                options={opcionesParaPregunta}
                getOptionLabel={(option) => option.label || ""}
                value={formData[pregunta.nombreCampo]?.map(value => {
                  const option = opcionesParaPregunta.find(option => option.value === value.id);
                  return option ? option : { label: value.nombre, value: value.id };
                }) || []}
                onChange={(event, newValue) => handleChange(event, newValue, pregunta)}
                isOptionEqualToValue={(option, value) => {
                  if (value?.value === 'No aplica') {
                    return option.value === 'No aplica';
                  }
                  return option.value === value.value;
                }}
                renderOption={(props, option, { selected }) => (
                  <li {...props} key={option.value}>
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.label}
                  </li>
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => {
                    const tagProps = getTagProps({ index });
                    delete tagProps.key;
                    return (
                      <Chip key={option.value} label={option.label} {...tagProps} />
                    );
                  })
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={pregunta.texto}
                    variant="outlined"
                    error={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0}
                    helperText={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0 ? 'Este campo es requerido' : ''}
                  />
                )}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

export default QuestionGroup;

// import React, { useState, useEffect } from 'react';
// import { TextField, Autocomplete, Checkbox, Chip } from '@mui/material';
// import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CustomDropdown from './CustomDropdown';

// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
// const checkedIcon = <CheckBoxIcon fontSize="small" />;

// function QuestionGroup({ preguntas, opciones, formData, updateFormData, userCountry, ingredientesCategorias }) {
//   const [noAplicaSelected, setNoAplicaSelected] = useState({});
//   const [disableQuestion9, setDisableQuestion9] = useState(false);

//   useEffect(() => {}, [opciones, userCountry]);

//   const handleMultipleChange = (event, newValue, pregunta) => {
//     console.log('handleMultipleChange - newValue:', newValue);
//     let updatedValue = newValue;

//     if (Array.isArray(newValue) && newValue.some(op => op.value === 'No aplica')) {
//       setNoAplicaSelected({ ...noAplicaSelected, [pregunta.nombreCampo]: true });
//       updatedValue = [{ label: 'No aplica', value: 'No aplica' }];
//     } else if (noAplicaSelected[pregunta.nombreCampo]) {
//       setNoAplicaSelected({ ...noAplicaSelected, [pregunta.nombreCampo]: false });
//       updatedValue = newValue.filter(op => op.value !== 'No aplica');
//     }

//     const dataToUpdate = updatedValue.map(option => {
//         if (pregunta.nombreCampo === 'alimentosQueNoGustan' || [2, 4, 5, 6].includes(pregunta.id)) {
//           return {
//             id: option.id,
//             nombre: option.nombre,
//             refModel: option.refModel
//           };
//         }
  
//         return {
//           id: option.value === 'No aplica' ? 'No aplica' : option.value,
//           nombre: option.label,
//           refModel: option.value === 'No aplica' ? null : pregunta.categoria === 'recipes' ? 'Recipe' : pregunta.categoria
//         };
//       });
  
//       console.log('handleMultipleChange - dataToUpdate:', dataToUpdate);
//       updateFormData(pregunta.nombreCampo, dataToUpdate);
//     };

//   const handleSingleChange = (event, newValue, pregunta) => {
//     console.log('handleSingleChange - newValue:', newValue);
//     const updatedValue = newValue ? [newValue] : [];

//     if (pregunta.id === 8) {
//       const isVegetarianOrVegan = updatedValue.some(option => option.label === 'Vegetariana' || option.label === 'Vegana');
//       setDisableQuestion9(isVegetarianOrVegan);
//       if (isVegetarianOrVegan) {
//         updateFormData('frecuenciaConsumoCarne', []);
//       }
//     }

//     const dataToUpdate = updatedValue.map(option => ({
//       id: option.value,
//       nombre: option.label,
//       refModel: pregunta.categoria
//     }));

//     console.log('handleSingleChange - dataToUpdate:', dataToUpdate);
//     updateFormData(pregunta.nombreCampo, dataToUpdate);
//   };

//   const handleChange = (event, newValue, pregunta) => {
//     console.log('handleChange - pregunta:', pregunta);
//     console.log('handleChange - newValue:', newValue);
//     if (pregunta.id === 8 || pregunta.id === 9) {
//       handleSingleChange(event, newValue, pregunta);
//     } else {
//       handleMultipleChange(event, newValue, pregunta);
//     }
//   };

//   const handleNoAplicaChange = (isSelected) => {
//     console.log('handleNoAplicaChange - isSelected:', isSelected);
//     setNoAplicaSelected(isSelected);
//   };

//   return (
//     <div>
//       {preguntas.map((pregunta) => {
//         console.log('Rendering pregunta:', pregunta);
//         if (pregunta.id === 9 && disableQuestion9) {
//           return null;
//         }

//         let opcionesParaPregunta = [];

//         if (pregunta.nombreCampo === 'platillosFavoritos') {
//           opcionesParaPregunta = opciones.recipes || [];
//         } else if (pregunta.nombreCampo === 'platillosInternacionales') {
//           opcionesParaPregunta = opciones.recipes.filter(recipe => recipe.pais !== userCountry) || [];
//         } else if (pregunta.categoria === 'paises') {
//           opcionesParaPregunta = opciones.paises || [];
//         } else {
//           opcionesParaPregunta = opciones[pregunta.categoria] || [];
//         }

//         console.log('opcionesParaPregunta:', opcionesParaPregunta);

//         if ([4, 5, 6, 7, 10, 11, 12, 13, 15].includes(pregunta.id) && !opcionesParaPregunta.some(op => op.value === 'No aplica')) {
//           opcionesParaPregunta = [{ label: 'No aplica', value: 'No aplica' }, ...opcionesParaPregunta];
//         }

//         if (noAplicaSelected[pregunta.nombreCampo]) {
//           opcionesParaPregunta = opcionesParaPregunta.filter(op => op.value === 'No aplica');
//         }

//         if ([2, 4, 5, 6].includes(pregunta.id)) {
//           return (
//             <div key={pregunta.id}>
//               <label>{pregunta.texto}</label>
//               <CustomDropdown
//                 label={pregunta.texto}
//                 options={ingredientesCategorias}
//                 selectedOptions={formData[pregunta.nombreCampo] || []}
//                 onChange={(newSelectedOptions) => handleChange(null, newSelectedOptions, pregunta)}
//                 allowNoAplica={pregunta.id !== 2}
//                 onNoAplicaChange={(isSelected) => handleNoAplicaChange(isSelected)}
//               />
//             </div>
//           );
//         }

//         return (
//           <div key={pregunta.id}>
//             <label>{pregunta.texto}</label>
//             {pregunta.id === 8 || pregunta.id === 9 ? (
//               <Autocomplete
//                 options={opcionesParaPregunta}
//                 getOptionLabel={(option) => option.label || ""}
//                 value={formData[pregunta.nombreCampo]?.map(value => opcionesParaPregunta.find(option => option.value === value.id) || { label: value.nombre, value: value.id })[0] || null}
//                 onChange={(event, newValue) => handleChange(event, newValue, pregunta)}
//                 isOptionEqualToValue={(option, value) => {
//                   console.log('isOptionEqualToValue - option:', option, 'value:', value);
//                   return option.value === value.value;
//                 }}
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label={pregunta.texto}
//                     variant="outlined"
//                     error={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0}
//                     helperText={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0 ? 'Este campo es requerido' : ''}
//                   />
//                 )}
//               />
//             ) : (
//               <Autocomplete
//                 multiple
//                 options={opcionesParaPregunta}
//                 getOptionLabel={(option) => option.label || ""}
//                 value={formData[pregunta.nombreCampo]?.map(value => {
//                   const option = opcionesParaPregunta.find(option => option.value === value.id);
//                   return option ? option : { label: value.nombre, value: value.id };
//                 }) || []}
//                 onChange={(event, newValue) => handleChange(event, newValue, pregunta)}
//                 isOptionEqualToValue={(option, value) => {
//                   if (value?.value === 'No aplica') {
//                     return option.value === 'No aplica';
//                   }
//                   return option.value === value.value;
//                 }}
//                 renderOption={(props, option, { selected }) => (
//                   <li {...props} key={option.value}>
//                     <Checkbox
//                       icon={icon}
//                       checkedIcon={checkedIcon}
//                       style={{ marginRight: 8 }}
//                       checked={selected}
//                     />
//                     {option.label}
//                   </li>
//                 )}
//                 renderTags={(value, getTagProps) =>
//                   value.map((option, index) => {
//                     const tagProps = getTagProps({ index });
//                     delete tagProps.key;
//                     return (
//                       <Chip key={option.value} label={option.label} {...tagProps} />
//                     );
//                   })
//                 }
//                 renderInput={(params) => (
//                   <TextField
//                     {...params}
//                     label={pregunta.texto}
//                     variant="outlined"
//                     error={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0}
//                     helperText={!formData[pregunta.nombreCampo] || formData[pregunta.nombreCampo].length === 0 ? 'Este campo es requerido' : ''}
//                   />
//                 )}
//               />
//             )}
//           </div>
//         );
//       })}
//     </div>
//   );
// }

// export default QuestionGroup;