import Menu from './Menu';

function Impacto() {
    return (
        <div className="impacto">
            <Menu/>
        </div>
    )
}

export default Impacto;