import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import useAuthValidation from '../../../hooks/useAuthValidation';
import TablaNutricionalNutricionista from '../invitaciones/TablaNutricionalNutricionista';
import Swal from 'sweetalert2';
import { Tabs, Tab, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const AdministrarPacientes = () => {
    const { token } = useAuthValidation();
    const [pacientes, setPacientes] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState(null);
    const [selectedTablas, setSelectedTablas] = useState([]);
    const [invitations, setInvitations] = useState([]);
    const [tabIndex, setTabIndex] = useState(0);

    const fetchPacientes = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/get-patients`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Pacientes fetched:', response.data);
            setPacientes(response.data);
        } catch (error) {
            console.error('Error fetching patients:', error);
        }
    }, [token]);

    const fetchInvitations = useCallback(async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/get-invitation-history`, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            setInvitations(response.data);
        } catch (error) {
            console.error('Error fetching invitation history:', error);
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            fetchPacientes();
            fetchInvitations();
        }
    }, [fetchPacientes, fetchInvitations, token]);

    const handleClickPaciente = async (paciente) => {
        setSelectedPaciente(paciente);
        setSelectedTablas(paciente.tablas);
    };

    const handleDeleteAssignment = async (userId) => {
        try {
            await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/delete-assignment`, { userId }, {
                headers: { 'Authorization': `Bearer ${token}` }
            });
            console.log('Userid', userId)
            fetchPacientes();
            fetchInvitations();
            setSelectedPaciente(null);
            setSelectedTablas([]);
        } catch (error) {
            console.error('Error deleting assignment:', error);
        }
    };      

    const confirmDeleteAssignment = (userId) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: "No podrás revertir esto!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminarlo!'
        }).then((result) => {
            if (result.isConfirmed) {
                handleDeleteAssignment(userId); // Pasar userId aquí
            }
        });
    };

    const handleTabChange = (event, newIndex) => {
        setTabIndex(newIndex);
    };

    return (
        <div>
            <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Administrar Pacientes" />
                <Tab label="Historial de Invitaciones" />
            </Tabs>

            {tabIndex === 0 && (
                <div>
                    <h2>Administrar Pacientes</h2>
                    <List>
                        {pacientes.map(paciente => (
                            <ListItem key={paciente._id} onClick={() => handleClickPaciente(paciente)} button>
                                <ListItemText primary={`${paciente.nombre} ${paciente.apellido}`} />
                                <IconButton edge="end" aria-label="delete" onClick={(e) => {
                                    e.stopPropagation();
                                    confirmDeleteAssignment(paciente._id); // Cambiar a userId
                                }}>
                                    <DeleteIcon />
                                </IconButton>
                            </ListItem>
                        ))}
                    </List>
                    {selectedPaciente && (
                        <TablaNutricionalNutricionista
                            email={selectedPaciente?.email}
                            tableIds={selectedTablas.map(tabla => tabla._id)}
                            onNewTable={() => handleClickPaciente(selectedPaciente)}
                            userId={selectedPaciente._id}
                            context="administrarpaciente"
                        />
                    )}
                </div>
            )}

            {tabIndex === 1 && (
                <div>
                    <h2>Historial de Invitaciones</h2>
                    <List>
                        {invitations.map(invitation => (
                            <ListItem key={invitation._id}>
                                <ListItemText
                                    primary={`${invitation.userId.nombre} ${invitation.userId.apellido}`}
                                    secondary={`Estado: ${invitation.isRead ? 'Aceptada' : 'Pendiente'}`}
                                />
                            </ListItem>
                        ))}
                    </List>
                </div>
            )}
        </div>
    );
};

export default AdministrarPacientes;