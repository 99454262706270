import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ForgotPasswordForm() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');

    const handleInputChange = (e) => {
        setEmail(e.target.value.toLowerCase());
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!email) {
            Swal.fire('Error', 'Por favor, introduce tu correo electrónico.', 'error');
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/forgot-password`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email }),
            });
    
            if (!response.ok) {
                throw new Error('Error al enviar el correo electrónico');
            }

            Swal.fire('Éxito', 'Correo electrónico de restablecimiento de contraseña enviado', 'success');
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
            Swal.fire('Error', 'Error al enviar el correo electrónico de restablecimiento de contraseña', 'error');
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="email"
                name="email"
                placeholder="Correo"
                value={email}
                onChange={handleInputChange}
            />
            <button type="submit">Enviar enlace de restablecimiento</button>
        </form>
    );
}

export default ForgotPasswordForm;