import axios from 'axios';

const fetchTablas = async ({ userId, source, tableIds, setTablas, setActiveTabla, setHasFetched }) => {
  try {
    //console.log('userId:', userId); // Verificar el valor de userId
    let response;
    if (source === 'nutricionista' && tableIds.length > 0) {
      response = await axios.get(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional`, {
        params: { ids: tableIds.join(','), source }
      });
    } else if (source === 'preferencias') {
      response = await axios.get(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional?userId=${userId}&source=${source}`);
    } else {
      //console.log('No temporary table assigned yet.');
      setTablas([]);
      setActiveTabla(null);
      setHasFetched(true);
      return;
    }

    if (response.data.length > 0) {
      setTablas(response.data);
      setActiveTabla(response.data[0]);
    } else {
      setTablas([]);
      setActiveTabla(null);
      //console.log('No tables found');
    }

    setHasFetched(true); // Establecer hasFetched en true después de la llamada API
  } catch (error) {
    console.error('Error fetching table data:', error);
  }
};

export default fetchTablas;