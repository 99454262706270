const updateTotalPortions = (updatedTableData) => {
    const totals = { ...updatedTableData };
  
    ['lacteos', 'vegetales', 'frutas', 'azucar', 'harinas', 'proteinas', 'grasas'].forEach((group) => {
      let total = 0;
      for (let i = 1; i < 6; i++) {
        const value = parseInt(updatedTableData[group][i], 10);
        if (!isNaN(value)) {
          total += value;
        }
      }
      totals[group][0] = total.toString();
    });
  
    return totals;
  };
  
  export default updateTotalPortions;
  