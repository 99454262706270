// Definición en calculos.js
const unitConversionToGrams = {
  'g': 1,
  'mg': 0.001,
  'ml': 1,
  'µg': 0.000001,
};

export const calculateCalories = (proteinas, carbohidratos, grasas) => {
  const protValor = parseFloat(proteinas?.valor) || 0;
  const protUnidad = proteinas?.unidad || 'g';
  const carbValor = parseFloat(carbohidratos?.valor) || 0;
  const carbUnidad = carbohidratos?.unidad || 'g';
  const fatValor = parseFloat(grasas?.valor) || 0;
  const fatUnidad = grasas?.unidad || 'g';

  const prot = protValor * (unitConversionToGrams[protUnidad] || 1);
  const carb = carbValor * (unitConversionToGrams[carbUnidad] || 1);
  const fat = fatValor * (unitConversionToGrams[fatUnidad] || 1);

  return (prot * 4) + (carb * 4) + (fat * 9);
};