import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import useAuthValidation from '../../../hooks/useAuthValidation';
import TablaNutricionalNutricionista from './TablaNutricionalNutricionista';
import InvitacionPaciente from './InvitacionPaciente';
import { IconButton, Dialog, DialogActions, DialogContent, DialogTitle, Button, Backdrop, CircularProgress, TextField } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const InvitacionesPanel = () => {
  const { userId, token } = useAuthValidation();
  const [invitados, setInvitados] = useState([]);
  const [pacientes, setPacientes] = useState([]);
  const [selectedInvitation, setSelectedInvitation] = useState(null);
  const [temporaryTable, setTemporaryTable] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newEmail, setNewEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  const fetchInvitados = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/invitations`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setInvitados(response.data);
      console.log('Invitados fetched:', response.data);
    } catch (error) {
      console.error('Error fetching invitations:', error);
    }
  }, [token]);

  const fetchPacientes = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/pacientes`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setPacientes(response.data);
      console.log('Pacientes fetched:', response.data);
    } catch (error) {
      console.error('Error fetching patients:', error);
    }
  }, [token]);

  const handleEditInvitation = async () => {
    setLoading(true);
    setOpenEditDialog(false);
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/nutritionist/update-invitation`, { email: newEmail, id: selectedInvitation._id }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      Swal.fire('Correo Editado', 'El correo electrónico ha sido editado exitosamente.', 'success');
      setNewEmail('');
      fetchInvitados();
    } catch (error) {
      console.error('Error editing invitation:', error);
      Swal.fire('Error', 'No se pudo editar el correo electrónico', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteInvitation = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: "No podrás revertir esto",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminarlo',
      cancelButtonText: 'Cancelar'
    });

    if (result.isConfirmed) {
      setLoading(true);
      try {
        await axios.delete(`${process.env.REACT_APP_API_URL}/api/nutritionist/delete-invitation/${id}`, {
          headers: { 'Authorization': `Bearer ${token}` }
        });
        Swal.fire('Eliminado', 'El correo electrónico ha sido eliminado.', 'success');
        setSelectedInvitation(null);
        fetchInvitados();
      } catch (error) {
        console.error('Error deleting invitation:', error);
        Swal.fire('Error', 'No se pudo eliminar el correo electrónico', 'error');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleSelectInvitation = useCallback(async (email) => {
    const selectedInvitation = invitados.find(inv => inv.email === email);
    setSelectedInvitation(selectedInvitation);
    console.log(`Invitación seleccionada: ${email}`);
    console.log('Selected Invitation ID:', selectedInvitation?._id);

    // Invalidate previous fetch state
    setHasFetched(false); 

    // Fetch temporaryTable data
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/temporary-table/${selectedInvitation._id}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setTemporaryTable(response.data.temporaryTable);
      console.log('Temporary Table fetched:', response.data.temporaryTable);
    } catch (error) {
      console.error('Error fetching temporary table data:', error);
    }
  }, [invitados, token]);

  const handleNewTable = async (createdTable) => {
    if (!selectedInvitation) return;

    try {
      const updatedTemporaryTable = temporaryTable || {};
      if (!updatedTemporaryTable.principal) {
        updatedTemporaryTable.principal = createdTable._id;
      } else if (!updatedTemporaryTable.secundaria) {
        updatedTemporaryTable.secundaria = createdTable._id;
      }

      await axios.put(`${process.env.REACT_APP_API_URL}/api/nutritionist/assign-table-token`, {
        token: selectedInvitation.token,
        temporaryTable: updatedTemporaryTable
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      setTemporaryTable(updatedTemporaryTable);
      Swal.fire('Tabla asignada', 'La tabla ha sido asignada correctamente.', 'success');
    } catch (error) {
      console.error('Error assigning table:', error);
      Swal.fire('Error', 'No se pudo asignar la tabla', 'error');
    }
  };

  useEffect(() => {
    if (!isDataFetched) {
      fetchInvitados();
      fetchPacientes();
      setIsDataFetched(true);
    }
  }, [fetchInvitados, fetchPacientes, isDataFetched]);

  useEffect(() => {
    if (selectedInvitation && !hasFetched) {
      handleSelectInvitation(selectedInvitation.email);
    }
  }, [selectedInvitation, hasFetched, handleSelectInvitation]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (invitados.length > 0 && !selectedInvitation) {
      handleSelectInvitation(invitados[0].email); // Selecciona el primer invitado por defecto
    }
  }, [invitados, handleSelectInvitation]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const handleOpenEditDialog = (invitation) => {
    setSelectedInvitation(invitation);
    setNewEmail(invitation.email);
    setOpenEditDialog(true);
  };

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
    setNewEmail('');
  };

  return (
    <div>
      <h2>Invitar Cliente</h2>
      <InvitacionPaciente onInvitationSent={fetchInvitados} />
      <div>
        <h2>Lista de Invitados</h2>
        <ul>
          {invitados.map((invitado) => (
            <li key={invitado._id}>
              <Button onClick={() => handleSelectInvitation(invitado.email)}>
                {invitado.email}
              </Button>
              <IconButton onClick={() => handleOpenEditDialog(invitado)} color="primary">
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDeleteInvitation(invitado._id)} color="secondary">
                <DeleteIcon />
              </IconButton>
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Pacientes Activos</h2>
        <ul>
          {pacientes.map((paciente) => (
            <li key={paciente._id}>
              {paciente.nombre} {paciente.apellido}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h2>Correo Seleccionado: {selectedInvitation ? selectedInvitation.email : 'Ninguno'}</h2>
      </div>

      {selectedInvitation && (
        <TablaNutricionalNutricionista
          source="nutricionista"
          email={selectedInvitation.email}
          tableIds={temporaryTable ? [temporaryTable.principal, temporaryTable.secundaria].filter(Boolean) : []}
          onNewTable={handleNewTable}
          invitationId={selectedInvitation._id}
          setHasFetchedFalse={() => setHasFetched(false)}
          context="invitacionespanel"
          userId={userId}
        />
      )}

      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>Editar Correo Electrónico</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Correo Electrónico"
            type="email"
            fullWidth
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="primary">
            Cancelar
          </Button>
          <Button onClick={handleEditInvitation} color="primary">
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop open={loading} style={{ zIndex: 1300, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default InvitacionesPanel;