import React, { useState, useEffect, useCallback } from 'react';
import { IconButton, InputBase, MenuItem, Menu, Avatar, Typography } from '@mui/material';
import { Search as SearchIcon, Close as CloseIcon } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Flag from 'react-world-flags';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next'; // Importa el hook useTranslation
import SearchResults from '../components/busqueda/SearchResults';
import useAuthValidation from '../hooks/useAuthValidation';
import './InternalMenu.css';
import arrowDownIcon from '../assets/icons/flecha_v.svg';
import notificationIcon from '../assets/icons/campana_sin_notificacion.svg';
import notificationActiveIcon from '../assets/icons/campana_con_notificacion.svg';
import i18n from '../i18n';

const InternalMenu = () => {
  const { t } = useTranslation(); // Inicializa el hook useTranslation
  const [profileEl, setProfileEl] = useState(null);
  const [languageEl, setLanguageEl] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState({ code: 'CRI', label: 'ES' });
  const isProfileMenuOpen = Boolean(profileEl);
  const isLanguageMenuOpen = Boolean(languageEl);
  const navigate = useNavigate();
  const { firstName, lastName, email, isLoading, subscriptionActive, logout, token } = useAuthValidation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('selectedLanguage');
    if (savedLanguage) {
      const languageMap = {
        'en': { code: 'USA', label: 'EN' },
        'es': { code: 'CRI', label: 'ES' },
        'ja': { code: 'JPN', label: 'JP' },
        'it': { code: 'ITA', label: 'IT' },
      };
      const language = languageMap[savedLanguage];
      if (language) {
        setSelectedLanguage(language);
        i18n.changeLanguage(savedLanguage);
      }
    }
  }, []);

  const checkNotifications = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error al obtener notificaciones');
      }

      const { notifications } = await response.json();
      const unreadNotifications = notifications.some(notification => !notification.isRead);
      setHasUnreadNotifications(unreadNotifications);
    } catch (error) {
      console.error('Error:', error);
    }
  }, [token]);

  useEffect(() => {
    checkNotifications();
    window.addEventListener('notification-update', checkNotifications);

    return () => {
      window.removeEventListener('notification-update', checkNotifications);
    };
  }, [checkNotifications]);

  const handleProfileMenuOpen = (event) => {
    setProfileEl(event.currentTarget);
  };

  const handleProfileMenuClose = () => {
    setProfileEl(null);
  };

  const handleLanguageMenuOpen = (event) => {
    setLanguageEl(event.currentTarget);
  };

  const handleLanguageMenuClose = (code, label) => {
    if (code && typeof code === 'string') {
      setSelectedLanguage({ code, label });
      let languageCode;
      switch (code) {
        case 'USA':
          languageCode = 'en';
          break;
        case 'CRI':
          languageCode = 'es';
          break;
        case 'JPN':
          languageCode = 'ja';
          break;
        case 'ITA':
          languageCode = 'it';
          break;
        default:
          languageCode = 'es'; // Por defecto, español
      }
      i18n.changeLanguage(languageCode);
      localStorage.setItem('selectedLanguage', languageCode); // Guardar la elección en localStorage
    }
    setLanguageEl(null);
  };

  const handleNotificationClick = () => {
    navigateAndClick('/configuracion', 'notificaciones-btn');
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleSearchSubmit = async (event) => {
    event.preventDefault();
    if (!searchQuery.trim()) return;

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/search?q=${encodeURIComponent(searchQuery)}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        throw new Error('Error en la búsqueda');
      }

      const data = await response.json();
      setSearchResults(data.recipes);
      setShowSearchResults(true);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    setShowSearchResults(false);
  };

  const handleLogout = () => {
    handleProfileMenuClose();
    Swal.fire({
      title: t('swal.title'), // Usando traducción para el título
      text: t('swal.text'), // Usando traducción para el texto
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t('swal.confirmButtonText'), // Usando traducción para el botón de confirmación
      cancelButtonText: t('swal.cancelButtonText'), // Usando traducción para el botón de cancelar
    }).then((result) => {
      if (result.isConfirmed) {
        logout();
        Swal.fire(
          t('swal.confirmedTitle'),
          t('swal.confirmedText'),
          'success'
        );
        navigate('/login');
      }
    });
  };

  const navigateAndClick = (path, elementId) => {
    if (window.location.pathname === path) {
      const element = document.getElementById(elementId);
      if (element) {
        element.click();
      }
    } else {
      navigate(path);
      setTimeout(() => {
        const element = document.getElementById(elementId);
        if (element) {
          element.click();
        }
      }, 100);
    }
    handleProfileMenuClose();
  };

  const closeOverlay = () => {
    setShowSearchResults(false);
    setSearchQuery('');
  };

  const menuId = 'primary-search-account-menu';
  const languageMenuId = 'language-menu';

  const renderProfileMenu = (
    <Menu
      anchorEl={profileEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <MenuItem disabled>{t(`menu.status${subscriptionActive ? 'Active' : 'Inactive'}`)}</MenuItem>
      <MenuItem onClick={() => { navigateAndClick('/configuracion', 'perfil-btn'); }}>{firstName} {lastName}</MenuItem>
      <MenuItem onClick={() => { navigateAndClick('/configuracion', 'perfil-btn'); }}>{email}</MenuItem>
      <MenuItem onClick={() => { navigateAndClick('/configuracion', 'perfil-btn'); }}>{t('menu.editAccount')}</MenuItem>
      <MenuItem onClick={() => { navigateAndClick('/configuracion', 'perfil-btn'); }}>{t('menu.settings')}</MenuItem>
      <MenuItem onClick={() => { navigate('/soporte'); handleProfileMenuClose(); }}>{t('menu.support')}</MenuItem>
      <MenuItem onClick={handleLogout}>{t('menu.logout')}</MenuItem>
    </Menu>
  );

  const renderLanguageMenu = (
    <Menu
      anchorEl={languageEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={languageMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isLanguageMenuOpen}
      onClose={handleLanguageMenuClose}
    >
      <MenuItem onClick={() => { handleLanguageMenuClose('CRI', 'ES'); }}>
        <Flag code="CRI" className="flag-icon" /> {t('languages.es')}
      </MenuItem>
      <MenuItem onClick={() => { handleLanguageMenuClose('USA', 'EN'); }}>
        <Flag code="USA" className="flag-icon" /> {t('languages.en')}
      </MenuItem>
      <MenuItem onClick={() => { handleLanguageMenuClose('JPN', 'JP'); }}>
        <Flag code="JPN" className="flag-icon" /> {t('languages.ja')}
      </MenuItem>
      <MenuItem onClick={() => { handleLanguageMenuClose('ITA', 'IT'); }}>
        <Flag code="ITA" className="flag-icon" /> {t('languages.it')}
      </MenuItem>
    </Menu>
  );

  if (isLoading) {
    return <div>Loading...</div>; // Indicador de carga visual
  }

  return (
    <div className="internal-menu">
      <form onSubmit={handleSearchSubmit} className="search">
        <InputBase
          placeholder={t('search.placeholder')}
          classes={{
            root: 'input-root',
            input: 'input-input',
          }}
          inputProps={{ 'aria-label': 'search' }}
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="clear-icon-container">
          {searchQuery && (
            <IconButton onClick={handleClearSearch} className="clear-icon">
              <CloseIcon />
            </IconButton>
          )}
          <IconButton type="submit" className="search-icon">
            <SearchIcon />
          </IconButton>
        </div>
      </form>
      <div className="menu-items">
        <div className="flag-container" onClick={handleLanguageMenuOpen}>
          <div className="flag-circle">
            <Flag code={selectedLanguage.code} style={{ width: '2vw', height: '2vw', objectFit: 'cover' }} />
          </div>
          <Typography variant="body2" sx={{ marginLeft: '0.5vw' }}>{selectedLanguage.label}</Typography>
          <img src={arrowDownIcon} alt="arrow down" className="arrow-down-icon" />
        </div>
        <IconButton color="inherit" onClick={handleNotificationClick} className="notification-container">
          <div className="notification-circle">
            <img
              src={hasUnreadNotifications ? notificationActiveIcon : notificationIcon}
              alt="notification"
              className="notification-icon"
            />
          </div>
        </IconButton>
        <div className="profile-container" onClick={handleProfileMenuOpen}>
          <Avatar alt={firstName} src="/static/images/avatar/1.jpg" style={{ width: '2vw', height: '2vw' }} />
          <Typography variant="body2" sx={{ marginLeft: '0.5vw' }}>{`${firstName} ${lastName}`}</Typography>
          <img src={arrowDownIcon} alt="arrow down" className="arrow-down-icon" />
        </div>
      </div>
      {renderProfileMenu}
      {renderLanguageMenu}

      {/* Superposición blanca con resultados de búsqueda */}
      {showSearchResults && (
        <div className="search-overlay" onClick={closeOverlay}>
          <div className="search-overlay-content" onClick={(e) => e.stopPropagation()}>
            <IconButton className="close-button" onClick={closeOverlay}>
              <CloseIcon />
            </IconButton>
            <SearchResults results={searchResults} />
          </div>
        </div>
      )}
    </div>
  );
};

export default InternalMenu;