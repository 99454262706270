// IngredientList.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, IconButton, Box, Pagination } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import useAuthValidation from '../../../../hooks/useAuthValidation';

const IngredientList = ({ setSelectedIngredient, openForm, ingredients, fetchIngredients }) => {
  const { token } = useAuthValidation();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(Math.ceil(ingredients.length / 10));

  useEffect(() => {
    setTotalPages(Math.ceil(ingredients.length / 10));
  }, [ingredients]);

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/ingredients/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      fetchIngredients();
    } catch (error) {
      console.error('Error deleting ingredient', error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box>
      <List>
        {ingredients.slice((page - 1) * 10, page * 10).map((ingredient) => (
          <ListItem key={ingredient._id} secondaryAction={
            <>
              <IconButton edge="end" aria-label="edit" onClick={() => { setSelectedIngredient(ingredient); openForm(); }}>
                <EditIcon />
              </IconButton>
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(ingredient._id)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={ingredient.nombre} secondary={`Categoría: ${ingredient.categoria}`} />
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination 
          count={totalPages} 
          page={page} 
          onChange={handlePageChange} 
          variant="outlined" 
          shape="rounded" 
        />
      </Box>
    </Box>
  );
};

export default IngredientList;