import React from 'react';
import { Link } from 'react-router-dom';
import './Recipes.css';
import SideMenu from '../../navigation/SideMenu';
import InternalMenu from '../../navigation/InternalMenu';

const Recipes = () => {
  return (
    <div className={`dashboard_flex`}>
      <SideMenu />
        <div className="main_content">
          <InternalMenu />
    <div className="recipes-container">
      <h1>Administrar Recetas</h1>
      <div className="recipes-buttons">
        <Link to="/recetas/ingredientes">
          <button>Recetas por Ingredientes</button>
        </Link>
        <Link to="/recetas/base">
          <button>Recetas Base</button>
        </Link>
        <Link to="/recetas/completas">
          <button>Recetas Completas</button>
        </Link>
        <Link to="/ingredientes">
          <button>Adminsitrar Ingredientes</button>
        </Link>
      </div>
    </div>
    </div>
    </div>
  );
};

export default Recipes;