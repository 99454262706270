const ja = {
    sideMenu: {
      dashboard: 'ダッシュボード',
      descubrir: '発見する',
      despensa: '私のパントリー',
      historial: '履歴',
      preferencias: '好み',
      configuracion: '設定',
      nutricionista: '栄養士パネル',
      editarRecetas: 'レシピ編集',
      soporte: 'サポート',
    },
  };
  
  export default ja;  