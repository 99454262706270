//CODIGO TESTEAR
import axios from 'axios';

const handleAddTabla = async (nombre, isPrincipal, setConfigInitialData, setShowConfigPopup, userId, source, email, setTablaConfigured, token, invitationId, context) => {
  try {
    console.log('Context en handleAddTabla:', context);
    console.log('Source en handleAddTabla:', source);
    let tipo;

    if (context === 'administrarpaciente') {
      // Manejo para administrarpaciente
      console.log('Context: administrarpaciente, userId:', userId);
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional?userId=${userId}&context=${context}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const tablas = response.data;
      console.log('Tablas del usuario:', tablas);

      tipo = tablas.some(tabla => tabla.tipo === 'principal') ? 'secundaria' : 'principal';
      console.log('Tipo de tabla determinada:', tipo);
    } else if (source === 'nutricionista' && invitationId) {
      // Manejo para nutricionista con invitación
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/temporary-table/${invitationId}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const { temporaryTable } = response.data;
      tipo = !temporaryTable || !temporaryTable.principal ? 'principal' : 'secundaria';
    } else if (source === 'preferencias') {
      // Manejo para preferencias
      console.log('Source: preferencias, manejando configuración específica para preferencias');
      tipo = 'secundaria'; // Puedes establecer un tipo específico para preferencias si es necesario
    } else {
      console.error('Invalid source or context');
      return;
    }

    const nombreTabla = tipo === 'principal' ? 'Tabla Principal' : (tipo === 'secundaria' ? 'Tabla Secundaria' : 'Tabla Secundaria');

    setConfigInitialData({
      nombre: nombreTabla,
      dias: [],
      fechaInicio: '',
      fechaFin: null,
      sinLimite: tipo === 'principal',
      tipo
    });

    if (source === 'nutricionista' && context === 'invitacionespanel' && invitationId) {
      const tablaResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/create-temporary-table`, {
        id: invitationId,
        tipo
      }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      console.log('Respuesta de creación de tabla temporal:', tablaResponse.data);
      void tablaResponse.data;
    }

    setShowConfigPopup(true);
  } catch (error) {
    console.error('Error handling tabla:', error);
  }
};

export default handleAddTabla;


//CODIGO ORIGINAL
// import axios from 'axios';

// const handleAddTabla = async (nombre, isPrincipal, setConfigInitialData, setShowConfigPopup, userId, source, email, setTablaConfigured, token, invitationId, context) => {
//   try {
//     console.log('Context en handleAddTabla:', context);
//     console.log('Source en handleAddTabla:', source);
//     let tipo;

//     if (context === 'administrarpaciente') {
//       // Realizar la solicitud para obtener las tablas del usuario (sin importar el source)
//       console.log('Context: administrarpaciente, userId:', userId);
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional?userId=${userId}&context=${context}`, {
//         headers: { 'Authorization': `Bearer ${token}` }
//       });
//       const tablas = response.data;
//       console.log('Tablas del usuario:', tablas);

//       // Determinar el tipo de tabla basado en la existencia de una tabla principal
//       tipo = tablas.some(tabla => tabla.tipo === 'principal') ? 'secundaria' : 'principal';
//       console.log('Tipo de tabla determinada:', tipo);
//     } else if (source === 'nutricionista' && invitationId) {
//       const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/nutritionist/temporary-table/${invitationId}`, {
//         headers: { 'Authorization': `Bearer ${token}` }
//       });
//       const { temporaryTable } = response.data;
//       tipo = !temporaryTable || !temporaryTable.principal ? 'principal' : 'secundaria';
//     } else {
//       console.error('Invalid source or context');
//       return;
//     }

//     const nombreTabla = tipo === 'principal' ? 'Tabla Principal' : 'Tabla Secundaria';

//     setConfigInitialData({
//       nombre: nombreTabla,
//       dias: [],
//       fechaInicio: '',
//       fechaFin: null,
//       sinLimite: tipo === 'principal',
//       tipo
//     });

//     if (source === 'nutricionista' && context === 'invitacionespanel' && invitationId) {
//       const tablaResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/create-temporary-table`, {
//         id: invitationId,
//         tipo
//       }, {
//         headers: { 'Authorization': `Bearer ${token}` }
//       });
//       console.log('Respuesta de creación de tabla temporal:', tablaResponse.data);
//       void tablaResponse.data;
//     }

//     setShowConfigPopup(true);
//   } catch (error) {
//     console.error('Error handling tabla:', error);
//   }
// };

// export default handleAddTabla;