const en = {
    sideMenu: {
      dashboard: 'Dashboard',
      descubrir: 'Discover',
      despensa: 'My Pantry',
      historial: 'History',
      preferencias: 'Preferences',
      configuracion: 'Settings',
      nutricionista: 'Nutritionist Panel',
      editarRecetas: 'Edit Recipes',
      soporte: 'Support',
    },
  };
  
  export default en;  