const handleSubscribe = async (email, onSuccess, onAlreadySubscribed, onError) => {
    try {
        // //console.log('Sending subscription request for:', email);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/subscriptions/subscribe`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email: email })
        });

        // //console.log('Subscription response status:', response.status);

        if (response.ok) {
            onSuccess();
        } else if (response.status === 409) {
            onAlreadySubscribed();
        } else {
            onError(new Error('Error al suscribirse'));
        }
    } catch (error) {
        console.error('Error in handleSubscribe:', error);
        onError(error);
    }
};

export default handleSubscribe;