import React from 'react';
import { Box, Typography, List, ListItem, ListItemText, IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const IngredientValidation = ({ ingredientes, instrucciones, removeIngredient }) => {
  const validationResults = ingredientes.map((ingrediente, index) => {
    const lowerCasedInstructions = instrucciones.map(instruccion => 
      (instruccion.descripcion || '').toLowerCase()
    );
    const isValid = lowerCasedInstructions.some(descripcion => 
      descripcion.includes((ingrediente.nombre || '').toLowerCase())
    );

    return { ingrediente, isValid, index };
  });

  return (
    <Box>
      <Typography variant="h6">Validación de Ingredientes</Typography>
      <List>
        {validationResults.map((result) => (
          <ListItem key={result.index} style={{ color: result.isValid ? 'green' : 'red' }}>
            <ListItemText primary={result.ingrediente.nombre} />
            <IconButton edge="end" aria-label="delete" onClick={() => removeIngredient(result.index)}>
              <DeleteIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default IngredientValidation;