const itTranslations = {
    menu: {
      statusActive: 'Stato: Attivo',
      statusInactive: 'Stato: Inattivo',
      editAccount: 'Modifica Account',
      settings: 'Impostazioni',
      support: 'Supporto',
      logout: 'Disconnettersi',
    },
    search: {
      placeholder: 'Cerca…',
    },
    notifications: {
      noNew: 'Nessuna nuova notifica',
    },
    languages: {
      es: 'ES',
      en: 'EN',
      ja: 'JP',
      it: 'IT',
    },    
  };
  
  export default itTranslations;  