import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Preferencias.css';
import SideMenu from '../../navigation/SideMenu';
import InternalMenu from '../../navigation/InternalMenu';
import UserPreferences from './UserPreferences';
import PreferenciasUsuarioNutricion from './usuariosnutricion/PreferenciasUsuarioNutricion';

function Preferencias() {
    const { isLoggedIn, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('Formulario');

    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, isLoading, navigate]);

    return (
        <div className='flex_row'>
            <SideMenu />
            <div className='main_content'>
                <InternalMenu />
                <div className='button_group'>
                    {['Formulario', 'Horarios', 'Sorprendeme', 'Tabla Nutricional'].map(section => (
                        <button
                            key={section}
                            className={activeSection === section ? 'selected' : ''}
                            onClick={() => setActiveSection(section)}
                        >
                            {section}
                        </button>
                    ))}
                </div>

                {activeSection === 'Formulario' && <Formulario />}
                {activeSection === 'Horarios' && <UserPreferences />}
                {activeSection === 'Sorprendeme' && <Sorprendeme />}
                {activeSection === 'Tabla Nutricional' && (
                    <PreferenciasUsuarioNutricion onRouteChange={() => navigate('/tabla-nutricional')} />
                )}
            </div>
        </div>
    );
}

const Formulario = () => <div>Formulario en construcción</div>;
const Sorprendeme = () => <div>Sorprendeme en construcción</div>;

export default Preferencias;