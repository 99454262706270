import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import logo from '../images/unifood-blanco.png'; // Logo de escritorio
import logoMobile from '../images/logo-mobile.png'; // Logo móvil
import LogoutButton from '../components/LogoutButton';
import { AuthContext } from '../components/AuthContext'; // Importar el contexto de autenticación
import { useTranslation } from 'react-i18next';
import './SideMenu.css';

function SideMenu() {
    const { t } = useTranslation(); // Hook de i18next para traducciones
    const isMobile = window.innerWidth < 481;
    const { isMedical, superUser } = useContext(AuthContext); // Obtener los roles del contexto

    return (
        <div className='side_left'>
            <div className='logo_side_menu'>
                <Link to="/dashboard">
                    <img src={isMobile ? logoMobile : logo} alt="Logo" className="logo"/>
                </Link>
            </div>
            <div className='menu_principal'>
                <Link to="/dashboard"><button>{t('sideMenu.dashboard')}</button></Link>
                <Link to="/descubrir"><button>{t('sideMenu.descubrir')}</button></Link>
                <Link to="/despensa"><button>{t('sideMenu.despensa')}</button></Link>
                <Link to="/historial"><button>{t('sideMenu.historial')}</button></Link>
                <Link to="/preferencias"><button>{t('sideMenu.preferencias')}</button></Link>
                <Link to="/configuracion"><button>{t('sideMenu.configuracion')}</button></Link>
                { (isMedical || superUser) && ( // Mostrar solo si el usuario es un superUser o isMedical
                    <Link to="/nutricionista"><button>{t('sideMenu.nutricionista')}</button></Link>
                )}
                { superUser && ( // Mostrar solo si el usuario es un superUser
                    <Link to="/recetas"><button>{t('sideMenu.editarRecetas')}</button></Link>
                )}
            </div>
            <div className='menu_cerrar_soporte'>
                <Link to="/soporte"><button>{t('sideMenu.soporte')}</button></Link>
                <LogoutButton isShown={true} className="cerrar-sesion-boton-side" />
            </div>
        </div>
    );
}

export default SideMenu;