import React, { useState, useEffect } from 'react';
import './ConfigPopup.css';
import { Checkbox, FormControlLabel, Button, MenuItem, Select, InputLabel, FormControl, TextField } from '@mui/material';

const ConfigPopup = ({ onClose, onSave, initialName, initialData, source }) => {
  const [nombre, setNombre] = useState(initialName || '');
  const [dias, setDias] = useState(initialData.dias || []);
  const [fechaInicio, setFechaInicio] = useState(initialData.fechaInicio || '');
  const [fechaFin, setFechaFin] = useState(initialData.fechaFin || '');
  const [tipo] = useState(initialData.tipo || '');
  const [sinLimite, setSinLimite] = useState(false);
  const [isSecondary, setIsSecondary] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setNombre(initialData.nombre || initialName || '');
    setDias(initialData.dias || []);
    setFechaInicio(initialData.fechaInicio ? initialData.fechaInicio.split('T')[0] : '');
    setFechaFin(initialData.fechaFin ? initialData.fechaFin.split('T')[0] : '');
    setSinLimite(initialData.sinLimite || false);
    setIsSecondary(initialData.tipo === 'secundaria');
  }, [initialName, initialData]);

  const validate = () => {
    const newErrors = {};
    if (!nombre) newErrors.nombre = 'El nombre es obligatorio';
    if (dias.length === 0) newErrors.dias = 'Debe seleccionar al menos un día';
    if (!fechaInicio) newErrors.fechaInicio = 'La fecha de inicio es obligatoria';
    if (!sinLimite && !fechaFin) newErrors.fechaFin = 'La fecha de fin es obligatoria';

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = () => {
    if (validate()) {
        onSave({
            nombre,
            dias,
            fechaInicio,
            fechaFin: sinLimite ? null : fechaFin,
            source: initialData.source, // Mantener el source original
            userId: initialData.userId, // Mantener el userId original
            tipo,
            _id: initialData._id
        });
        onClose();
    }
  };

  const handleSelectAllDays = () => {
    setDias(['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo']);
  };

  const handleDiasChange = (event) => {
    setDias(event.target.value);
  };

  return (
    <div className='popup_overlay'>
      <div className='popup_content'>
        <h3>Configurar Tabla Nutricional</h3>
        <div className='popup_buttons'>
          <TextField
            label="Nombre de la Tabla"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            fullWidth
            error={!!errors.nombre}
            helperText={errors.nombre}
          />
          <InputLabel id="dias-label">Días de uso</InputLabel>
          <FormControl fullWidth error={!!errors.dias}>
            <Select
              labelId="dias-label"
              multiple
              value={dias}
              onChange={handleDiasChange}
              renderValue={(selected) => selected.join(', ')}
            >
              {['lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado', 'domingo'].map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </Select>
            {!!errors.dias && <div style={{ color: 'red', marginTop: '5px' }}>{errors.dias}</div>}
          </FormControl>
          <Button onClick={handleSelectAllDays} variant="contained" color="primary" style={{ marginTop: '10px' }}>
            Seleccionar todos los días
          </Button>
          <TextField
            type="date"
            label="Fecha de Inicio"
            value={fechaInicio}
            onChange={(e) => setFechaInicio(e.target.value)}
            fullWidth
            InputLabelProps={{ shrink: true }}
            error={!!errors.fechaInicio}
            helperText={errors.fechaInicio}
          />
          {!sinLimite && (
            <TextField
              type="date"
              label="Fecha de Fin"
              value={fechaFin}
              onChange={(e) => setFechaFin(e.target.value)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              error={!!errors.fechaFin}
              helperText={errors.fechaFin}
            />
          )}
          {!isSecondary && (
            <FormControlLabel
              control={<Checkbox checked={sinLimite} onChange={(e) => setSinLimite(e.target.checked)} />}
              label="Sin límite de tiempo"
            />
          )}
        </div>
        <div className='popup_actions'>
          <Button variant="contained" color="secondary" onClick={onClose}>Cancelar</Button>
          <Button variant="contained" color="primary" onClick={handleSave}>{initialData._id ? 'Editar' : 'Guardar'}</Button>
        </div>
      </div>
    </div>
  );
};

export default ConfigPopup;