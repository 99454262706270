const ja = {
    swal: {
      title: '本当ですか？',
      text: 'ログアウトしますか？',
      confirmButtonText: 'はい、ログアウトします',
      cancelButtonText: 'いいえ、キャンセル',
      confirmedTitle: 'ログアウトしました！',
      confirmedText: 'セッションが終了しました。',
    },
    button: {
      logout: 'ログアウト',
    },
  };
  
  export default ja;  