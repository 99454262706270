import React from 'react';
import './RecipeCard.css';

const RecipeCard = ({ recipe }) => {
  return (
    <div className="recipe-card">
      <img src={recipe.imagenes[0]} alt={recipe.nombre} className="recipe-image" />
      <div className="recipe-details">
        <div className="recipe-category">Categoría: {recipe.categoria.join(', ')}</div>
        <h3 className="recipe-title">{recipe.nombre}</h3>
        <p className="recipe-info">{recipe.porciones} porciones · {recipe.tiempoPreparacion + recipe.tiempoCoccion} min</p>
        <p className="recipe-info">Calorías: {recipe.informacionNutricional.calorias} kcal</p>
      </div>
    </div>
  );
};

export default RecipeCard;