import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { generateSignature } from '../utils/generateSignature';
import './AuthForm.css';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';

function RegisterForm({ formData, handleInputChange }) {
    const navigate = useNavigate();
    const [registerStep, setRegisterStep] = useState(1);
    const [countryList, setCountryList] = useState([]);
    const [passwordValidation, setPasswordValidation] = useState({
        length: false,
        uppercase: false,
        lowercase: false,
        number: false,
        specialChar: false,
        match: false,
    });
    const [doctorInfo, setDoctorInfo] = useState(null);

    useEffect(() => {
        countries.registerLocale(english);
        setCountryList(Object.values(countries.getNames('en')));
    }, []);

    useEffect(() => {
        validatePassword(formData.password, formData.repeatPassword);
    }, [formData.password, formData.repeatPassword]);

    const validatePassword = (password, repeatPassword) => {
        const validations = {
            length: password.length >= 8,
            uppercase: /[A-Z]/.test(password),
            lowercase: /[a-z]/.test(password),
            number: /\d/.test(password),
            specialChar: /[\]!@#$%^&*_+=:;,./\\|~'?\\:_\\[-]/.test(password),
            match: password === repeatPassword
        };
        setPasswordValidation(validations);
    };

    const handleInputChangeWithValidation = (e) => {
        handleInputChange(e);
    };

    const handleContinue = (event) => {
        event.preventDefault();
    
        // Verificaciones para la fase 1 del registro
        if (registerStep === 1) {
            if (!formData.nombre) {
                Swal.fire('Error', 'El nombre es requerido', 'error');
                return;
            }
            if (!formData.apellido) {
                Swal.fire('Error', 'El apellido es requerido', 'error');
                return;
            }
    
            if (formData.password !== formData.repeatPassword) {
                Swal.fire('Error', 'Las contraseñas no coinciden', 'error');
                return;
            }
            if (!formData.email) {
                Swal.fire('Error', 'El email es requerido', 'error');
                return;
            }
    
            if (!formData.password) {
                Swal.fire('Error', 'La contraseña es requerida', 'error');
                return;
            }

            if (Object.values(passwordValidation).includes(false)) {
                Swal.fire('Error', 'La contraseña no cumple con todos los requisitos', 'error');
                return;
            }
        }
    
        // Verificaciones para la fase 2 del registro
        if (registerStep === 2) {
            const age = calculateAge(formData.fechaNacimiento);
            if (age === -1) {
                Swal.fire('Error', 'La fecha de nacimiento no puede ser en el futuro', 'error');
                return;
            } else if (age < 18) {
                Swal.fire('Error', 'Debes tener al menos 18 años', 'error');
                return;
            }
    
            if (!formData.fechaNacimiento) {
                Swal.fire('Error', 'La fecha de nacimiento es requerida', 'error');
                return;
            }
    
            if (!formData.pais) {
                Swal.fire('Error', 'El país es requerido', 'error');
                return;
            }
    
            // Redirige al usuario a la página de bienvenida después del paso 2
            navigate('/bienvenida');
            return;
        }
        setRegisterStep(registerStep + 1);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (registerStep === 1) {
            Swal.fire('Error', 'Por favor, completa la fase 1 del registro', 'error');
            return;
        } else if (registerStep === 2) {
            const age = calculateAge(formData.fechaNacimiento);
            if (age === -1) {
                Swal.fire('Error', 'La fecha de nacimiento no puede ser en el futuro', 'error');
                return;
            } else if (age < 18) {
                Swal.fire('Error', 'Debes tener al menos 18 años', 'error');
                return;
            }
    
            if (!formData.fechaNacimiento) {
                Swal.fire('Error', 'La fecha de nacimiento es requerida', 'error');
                return;
            }
    
            if (!formData.pais) {
                Swal.fire('Error', 'El país es requerido', 'error');
                return;
            }
        }
    
        const token = new URLSearchParams(window.location.search).get('token');
        const signature = generateSignature(token);
        const url = token 
            ? `${process.env.REACT_APP_API_URL}/api/users/register/${token}?signature=${signature}`
            : `${process.env.REACT_APP_API_URL}/api/users/register`;
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include'
            });
    
            const data = await response.json();
    
            if (!response.ok) {
                Swal.fire('Error', data.message, 'error');
            } else {
                Swal.fire('Éxito', 'Registro completado exitosamente. Por favor, revisa tu correo para confirmar tu cuenta.', 'success');
                navigate('/login');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        countries.registerLocale(english);
        setCountryList(Object.values(countries.getNames('en')));
    
        // Obtener token de la URL usando una expresión regular
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        //console.log('Token:', token); // Log token
        if (token) {
            fetchDoctorInfo(token);
        }
    }, []);    
    
    // Función para obtener la información del doctor usando el token
    const fetchDoctorInfo = async (token) => {
        try {
            //console.log('Fetching doctor info for token:', token);
            const signature = generateSignature(token);
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/nutritionist/invitations/${token}?signature=${signature}`);
            const data = await response.text(); // Cambiar a .text() para manejar el error
    
            if (response.ok) {
                const doctorData = JSON.parse(data); // Convertir solo si la respuesta es correcta
                setDoctorInfo(doctorData.doctor);
                //console.log('Doctor Info Set:', doctorData.doctor);
            } else {
                console.error('Error fetching doctor info:', data);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };    
    
    return (
        <>
            {registerStep === 1 ? (
                <div className='login-form'>
                    {doctorInfo && (
                        <>
                            <p className="invitation-message">
                                Estás siendo registrado por invitación del doctor(a) {doctorInfo.nombre} {doctorInfo.apellido}.
                            </p>
                        </>
                    )}
                    <h2 className='login-form'>Crea tu Cuenta</h2>
                    <label className='label-form-auth'>Nombre</label>
                    <input
                        type="text"
                        name="nombre"
                        placeholder="Nombre"
                        value={formData.nombre}
                        className='input-form-auth'
                        onChange={handleInputChangeWithValidation}
                    />
                    <label className='label-form-auth'>Apellido</label>
                    <input
                        type="text"
                        name="apellido"
                        placeholder="Apellido"
                        value={formData.apellido}
                        className='input-form-auth'
                        onChange={handleInputChangeWithValidation}
                    />
                    <label className='label-form-auth'>Correo Electrónico</label>
                    <input
                        type="email"
                        name="email"
                        placeholder="Correo Electrónico"
                        value={formData.email}
                        className='input-form-auth'
                        onChange={handleInputChangeWithValidation}
                    />
                    <label className='label-form-auth'>Contraseña</label>
                    <input
                        type="password"
                        name="password"
                        placeholder="Contraseña"
                        value={formData.password}
                        className='input-form-auth'
                        onChange={handleInputChangeWithValidation}
                    />
                    <label className='label-form-auth'>Repetir Contraseña</label>
                    <input
                        type="password"
                        name="repeatPassword"
                        placeholder="Repetir Contraseña"
                        value={formData.repeatPassword}
                        className='input-form-auth'
                        onChange={handleInputChangeWithValidation}
                    />
                    <PasswordRequirements validation={passwordValidation} />
                    <div className="extras">
                        <label className='checkbox-label'>
                            <input type="checkbox" className='checkbox'/>
                            Acepto términos y condiciones y la política de privacidad
                        </label>
                    </div>
                    <button type="button" className='login-button' onClick={handleContinue}>Continuar</button>
                    <p>¿Ya tienes una cuenta? <Link to="/login"><strong>Ingresar</strong></Link></p>
                </div>
            ) : registerStep === 2 ? (
                <div className='login-form'>
                    <label className='label-form-auth'>Fecha de Nacimiento</label>
                    <input
                        type="date"
                        name="fechaNacimiento"
                        value={formData.fechaNacimiento}
                        className='input-form-auth'
                        onChange={handleInputChangeWithValidation}
                    />
                    <label className='label-form-auth'>País</label>
                    <select name="pais" value={formData.pais} onChange={handleInputChangeWithValidation} className='input-form-auth'>
                        <option value="">Selecciona un país</option>
                        {countryList.map((country, index) => (
                            <option key={index} value={country}>{country}</option>
                        ))}
                    </select>
                    <button type="submit" className='login-button' onClick={handleSubmit}>Continuar</button>
                    <button type="button" className='login-button' onClick={() => setRegisterStep(registerStep - 1)}>Volver</button>
                </div>
            ) : null}
        </>
    );
}

function PasswordRequirements({ validation }) {
    return (
        <div className="password-requirements">
            <p className={`requirement ${validation.length ? 'valid' : 'invalid'}`}>
                <span className="requirement-icon">{validation.length ? '✔' : '✖'}</span>
                Al menos 8 caracteres
            </p>
            <p className={`requirement ${validation.uppercase ? 'valid' : 'invalid'}`}>
                <span className="requirement-icon">{validation.uppercase ? '✔' : '✖'}</span>
                Al menos una letra mayúscula
            </p>
            <p className={`requirement ${validation.lowercase ? 'valid' : 'invalid'}`}>
                <span className="requirement-icon">{validation.lowercase ? '✔' : '✖'}</span>
                Al menos una letra minúscula
            </p>
            <p className={`requirement ${validation.number ? 'valid' : 'invalid'}`}>
                <span className="requirement-icon">{validation.number ? '✔' : '✖'}</span>
                Al menos un número
            </p>
            <p className={`requirement ${validation.specialChar ? 'valid' : 'invalid'}`}>
                <span className="requirement-icon">{validation.specialChar ? '✔' : '✖'}</span>
                Al menos un carácter especial
            </p>
            <p className={`requirement ${validation.match ? 'valid' : 'invalid'}`}>
                <span className="requirement-icon">{validation.match ? '✔' : '✖'}</span>
                Las contraseñas deben coincidir
            </p>
        </div>
    );
}

function calculateAge(dob) {
    const birthDate = new Date(dob);
    const currentDate = Date.now();

    // Verificar si la fecha de nacimiento es en el futuro
    if (birthDate.getTime() > currentDate) {
        return -1; // Retorna -1 si la fecha de nacimiento es en el futuro
    }

    const diffMs = currentDate - birthDate.getTime();
    const ageDt = new Date(diffMs);

    return Math.abs(ageDt.getUTCFullYear() - 1970);
}

export default RegisterForm;