import React, { useState } from 'react';
import Swal from 'sweetalert2';
import useAuthValidation from '../../../hooks/useAuthValidation';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';

const AsignarPacientesPopup = ({ onClose }) => {
    const { userId, token } = useAuthValidation();
    const [email, setEmail] = useState('');

    const handleInvite = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/nutritionist/add-patient`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email, doctorId: userId }) // Incluir doctorId
            });

            if (!response.ok) {
                throw new Error('Error al enviar la invitación');
            }

            await response.json();
            Swal.fire('¡Invitación enviada!', 'La invitación ha sido enviada correctamente.', 'success');
            onClose();
        } catch (error) {
            console.error('Error:', error);
            Swal.fire('Error', 'No se pudo enviar la invitación.', 'error');
            onClose(); // Cerrar el modal también en caso de error
        }
    };

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Asignar Pacientes</DialogTitle>
            <DialogContent>
                <TextField
                    type='email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder='Correo del paciente'
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleInvite} color="primary" variant="contained">Enviar Invitación</Button>
                <Button onClick={onClose} color="secondary" variant="contained">Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default AsignarPacientesPopup;