// components/Administrativo/usuario/UserList.js
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { List, ListItem, ListItemText, IconButton, Box, Pagination, Checkbox, FormControlLabel } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Swal from 'sweetalert2';
import useAuthValidation from '../../../hooks/useAuthValidation';

const UserList = () => {
  const { token } = useAuthValidation();
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const superUserId = '65cbd935c9a2943603e014bc'; // ID del superusuario principal

  const fetchUsers = useCallback(async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const { users, totalUsers } = response.data;
      setUsers(users);

      const totalPageCount = totalUsers ? Math.ceil(totalUsers / 10) : 0;
      setTotalPages(totalPageCount);
    } catch (error) {
      console.error('Error fetching users', error);
    }
  }, [token]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers, page]);

  const handleDelete = (userId) => {
    // Prevenir eliminación del superusuario principal
    if (userId === superUserId) {
      return Swal.fire('Error', 'No puedes eliminar el superusuario principal', 'error');
    }

    Swal.fire({
      title: '¿Estás seguro?',
      text: "¡No podrás revertir esto!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminarlo'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });
          Swal.fire('¡Eliminado!', 'El usuario ha sido eliminado.', 'success');
          fetchUsers();
        } catch (error) {
          console.error('Error deleting user', error);
          Swal.fire('Error', 'Hubo un problema al eliminar el usuario', 'error');
        }
      }
    });
  };

  const handleRoleChange = async (userId, role, value) => {
    // Asegurar que el superusuario principal no pueda perder su rol
    if (userId === superUserId && role === 'superUser') {
      return Swal.fire('Error', 'No puedes quitar el rol de superusuario de este usuario', 'error');
    }
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/users/${userId}`, { [role]: value }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      fetchUsers(); // Actualizar la lista de usuarios
    } catch (error) {
      console.error('Error updating user role', error);
      Swal.fire('Error', 'Hubo un problema al actualizar el rol del usuario', 'error');
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <Box>
      <List>
        {users.map((user) => (
          <ListItem key={user._id} secondaryAction={
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.superUser}
                    onChange={(e) => handleRoleChange(user._id, 'superUser', e.target.checked)}
                    disabled={user._id === superUserId} // Deshabilitar checkbox para el superusuario principal
                  />
                }
                label="Super Usuario"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.isMedical}
                    onChange={(e) => handleRoleChange(user._id, 'isMedical', e.target.checked)}
                  />
                }
                label="Nutricionista"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={user.isChef}
                    onChange={(e) => handleRoleChange(user._id, 'isChef', e.target.checked)}
                  />
                }
                label="Chef"
              />
              <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(user._id)}>
                <DeleteIcon />
              </IconButton>
            </>
          }>
            <ListItemText primary={`${user.nombre} ${user.apellido}`} secondary={user.email} />
          </ListItem>
        ))}
      </List>
      <Box display="flex" justifyContent="center" mt={2}>
        <Pagination
          count={totalPages}
          page={page}
          onChange={handlePageChange}
          variant="outlined"
          shape="rounded"
          color="primary"
          disabled={totalPages <= 1}
        />
      </Box>
    </Box>
  );
};

export default UserList;