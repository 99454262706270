// CODIGO FUNCIONAL ETAPA 2 PARA QUE SIRVA DELETE

const handleCellClick = (group, col, activeTabla, setWarnings, setSelectedCell, setShowPopup, setShowUnitsSelect, getMealTime) => {
  const mealTime = getMealTime(col);

  //console.log(`Clicked on cell: Group: ${group}, Column: ${col}, Meal Time: ${mealTime}`);

  for (const [key, value] of Object.entries(activeTabla.elegirOpcionIndices)) {
    if (key !== mealTime && value === 1) {
      setWarnings(`Debe completar "Elegir una opción" en ${key} antes de continuar con ${mealTime}.`);
      return;
    }
  }

  setShowUnitsSelect(activeTabla.elegirOpcionIndices[mealTime] === 0);

  setSelectedCell({ group, col });
  setShowPopup(true);
};

export default handleCellClick;



// CODIGO BASE

// const handleCellClick = (group, col, activeTabla, setWarnings, setSelectedCell, setShowPopup, setShowUnitsSelect, getMealTime) => {
//     const mealTime = getMealTime(col);
  
//     //console.log(`Clicked on cell: Group: ${group}, Column: ${col}, Meal Time: ${mealTime}`);
  
//     for (const [key, value] of Object.entries(activeTabla.elegirOpcionIndices)) {
//       if (key !== mealTime && value === 1) {
//         setWarnings(`Debe completar "Elegir una opción" en ${key} antes de continuar con ${mealTime}.`);
//         return;
//       }
//     }
  
//     setShowUnitsSelect(activeTabla.elegirOpcionIndices[mealTime] === 0);
  
//     setSelectedCell({ group, col });
//     setShowPopup(true);
//   };
  
//   export default handleCellClick;  