import React, { useState, useEffect } from 'react';
import { Typography, Button, IconButton, Grid } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentNeutralIcon from '@mui/icons-material/SentimentNeutral';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

function FoodToleranceScreen({ foods, toleranceLevels, setToleranceLevels, onClose, onSave }) {
  const [localToleranceLevels, setLocalToleranceLevels] = useState({});

  useEffect(() => {
    const initialLevels = foods.reduce((acc, food) => ({
      ...acc,
      [food.id]: toleranceLevels[food.id] !== undefined ? toleranceLevels[food.id] : 100
    }), {});
    setLocalToleranceLevels(initialLevels);
  }, [foods, toleranceLevels]);

  const handleToleranceChange = (foodId, newValue) => {
    setLocalToleranceLevels(prevLevels => ({ ...prevLevels, [foodId]: newValue }));
    setToleranceLevels(prevLevels => ({ ...prevLevels, [foodId]: newValue }));
  };

  const handleSave = () => {
    onSave(localToleranceLevels);
  };

  const toleranceOptions = [
    { value: 25, label: 'Lo Evito Si Puedo', icon: <SentimentVeryDissatisfiedIcon /> },
    { value: 50, label: 'Me disgusta', icon: <SentimentDissatisfiedIcon /> },
    { value: 75, label: 'Alta Intolerancia', icon: <SentimentNeutralIcon /> },
    { value: 100, label: 'No lo tolero', icon: <SentimentVerySatisfiedIcon /> },
  ];

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Ajusta el nivel de tolerancia para los alimentos que no toleras:
      </Typography>
      {foods.map((food) => (
        <div key={food.id} style={{ marginBottom: '20px' }}>
          <Typography gutterBottom>{food.nombre}</Typography>
          <Grid container spacing={2}>
            {toleranceOptions.map((option) => (
              <Grid item key={option.value}>
                <IconButton
                  color={localToleranceLevels[food.id] === option.value ? 'primary' : 'default'}
                  onClick={() => handleToleranceChange(food.id, option.value)}
                >
                  {option.icon}
                </IconButton>
                <Typography variant="body2" align="center">{option.label}</Typography>
              </Grid>
            ))}
          </Grid>
        </div>
      ))}
      <Button onClick={onClose} variant="contained" color="secondary" style={{ marginRight: '10px' }}>
        Cerrar
      </Button>
      <Button onClick={handleSave} variant="contained" color="primary">
        Guardar
      </Button>
    </div>
  );
}

export default FoodToleranceScreen;