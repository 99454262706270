import React, { useEffect, useState } from 'react';
import { List, ListItem, ListItemText, Button, IconButton } from '@mui/material';
import Swal from 'sweetalert2';
import DeleteIcon from '@mui/icons-material/Delete';
import './Notificaciones.css';

function Notificaciones() {
    const [notifications, setNotifications] = useState([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const fetchNotifications = async (page = 1) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al obtener notificaciones');
            }

            const data = await response.json();
            setNotifications(data.notifications);
            setTotalPages(data.totalPages);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchNotifications(page);
    }, [page]);

    const markAsRead = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/${id}/read`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al marcar la notificación como leída');
            }

            await response.json();
            fetchNotifications(page);
            window.dispatchEvent(new CustomEvent('notification-update')); // Emitir evento personalizado
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const markAllAsRead = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/mark-all-read`, {
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al marcar todas las notificaciones como leídas');
            }

            await response.json();
            fetchNotifications(page);
            window.dispatchEvent(new CustomEvent('notification-update')); // Emitir evento personalizado
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const deleteNotification = async (id) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/${id}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (!response.ok) {
                throw new Error('Error al eliminar la notificación');
            }

            await response.json();
            fetchNotifications(page);
            window.dispatchEvent(new CustomEvent('notification-update')); // Emitir evento personalizado
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const deleteAllNotifications = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                }
            });
    
            if (!response.ok) {
                throw new Error('Error al eliminar todas las notificaciones');
            }
    
            await response.json();
            fetchNotifications(page);
            window.dispatchEvent(new CustomEvent('notification-update')); // Emitir evento personalizado
        } catch (error) {
            console.error('Error:', error);
        }
    };  

    const handleAcceptInvitation = async (id, invitationType) => {
        try {
            const endpoint = invitationType === 'patient-to-doctor' 
                ? 'accept-patient-invitation' 
                : 'accept-invitation';
            
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/${endpoint}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ invitationId: id, type: invitationType })
            });
    
            if (!response.ok) {
                throw new Error('Error al aceptar la invitación');
            }
    
            await response.json();
            Swal.fire('¡Invitación aceptada!', 'Has aceptado la invitación correctamente.', 'success');
            fetchNotifications(page);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire('Error', 'No se pudo aceptar la invitación.', 'error');
        }
    };    

    const handleRejectInvitation = async (id, invitationType) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/notifications/reject-invitation`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ invitationId: id, type: invitationType })
            });

            if (!response.ok) {
                throw new Error('Error al rechazar la invitación');
            }

            await response.json();
            Swal.fire('¡Invitación rechazada!', 'Has rechazado la invitación correctamente.', 'success');
            fetchNotifications(page);
        } catch (error) {
            console.error('Error:', error);
            Swal.fire('Error', 'No se pudo rechazar la invitación.', 'error');
        }
    };

    return (
        <div>
            <h2>Notificaciones</h2>
            <Button variant="contained" color="primary" onClick={markAllAsRead}>
                Marcar todas como leídas
            </Button>
            <Button variant="contained" color="secondary" onClick={deleteAllNotifications}>
                Eliminar todas
            </Button>
            <List>
                {notifications.map(notification => (
                    <ListItem key={notification._id} className={notification.isRead ? 'read' : 'unread'}>
                        <ListItemText
                            primary={notification.title}
                            secondary={notification.message}
                        />
                        {!notification.isRead && (
                            <>
                                {notification.type === 'invitation' && (
                                    <>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleAcceptInvitation(notification._id, notification.invitationType)}
                                        >
                                            Aceptar Invitación
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleRejectInvitation(notification._id, notification.invitationType)}
                                        >
                                            Rechazar Invitación
                                        </Button>
                                    </>
                                )}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => markAsRead(notification._id)}
                                >
                                    Marcar como leída
                                </Button>
                            </>
                        )}
                        <IconButton edge="end" aria-label="delete" onClick={() => deleteNotification(notification._id)}>
                            <DeleteIcon />
                        </IconButton>
                    </ListItem>
                ))}
            </List>
            <div>
                {Array.from({ length: totalPages }, (_, index) => (
                    <Button key={index + 1} onClick={() => setPage(index + 1)}>
                        {index + 1}
                    </Button>
                ))}
            </div>
        </div>
    );
}

export default Notificaciones;