import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Checkbox, Chip, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Select, InputLabel, FormControl, Autocomplete } from '@mui/material';
import useAuthValidation from '../../../../hooks/useAuthValidation';
import { calculateCalories } from './calculos'; // Importar función de cálculo de calorías

const categories = [
  'Lacteos',
  'Vegetales',
  'Frutas',
  'Azucar',
  'Harinas',
  'Proteina',
  'Grasas',
];

const units = ['g', 'mg', 'ml', 'µg'];

const IngredientForm = ({ open, onClose, selectedIngredient, setSelectedIngredient, fetchIngredients }) => {
  const { token } = useAuthValidation();
  const [nombre, setNombre] = useState('');
  const [sustitutos, setSustitutos] = useState([]);
  const [listaIngredientes, setListaIngredientes] = useState([]);
  const [categoria, setCategoria] = useState('');
  const [proteinas, setProteinas] = useState({ valor: '', unidad: 'g' });
  const [carbohidratos, setCarbohidratos] = useState({ valor: '', unidad: 'g' });
  const [grasas, setGrasas] = useState({ valor: '', unidad: 'g' });
  const [fibra, setFibra] = useState({ valor: '', unidad: 'g' });
  const [vitaminas, setVitaminas] = useState([]);
  const [minerales, setMinerales] = useState([]);
  const [caloriasPorUnidad, setCaloriasPorUnidad] = useState(0);
  const [dietasAdecuadas, setDietasAdecuadas] = useState({
    vegetariano: false,
    vegano: false,
    pescetariano: false,
    libreDeGluten: true,
    esKeto: false,
    esCarne: false,
    libreDeLactosa: true,
    libreDeSoya: true,
  });

  useEffect(() => {
    const fetchIngredientes = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/ingredients`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (response.data && response.data.length > 0 && response.data[0].opciones) {
          setListaIngredientes(response.data[0].opciones);
        } else {
          console.error('Unexpected API response format', response.data);
          setListaIngredientes([]);
        }
      } catch (error) {
        console.error('Error fetching ingredients for substitutes', error);
        setListaIngredientes([]);
      }
    };
    fetchIngredientes();
  }, [token]);

  useEffect(() => {
    if (selectedIngredient) {
      setNombre(selectedIngredient.nombre || '');
      setCategoria(selectedIngredient.categoria || '');
      setProteinas(selectedIngredient.proteinas || { valor: '', unidad: 'g' });
      setCarbohidratos(selectedIngredient.carbohidratos || { valor: '', unidad: 'g' });
      setGrasas(selectedIngredient.grasas || { valor: '', unidad: 'g' });
      setFibra(selectedIngredient.fibra || { valor: '', unidad: 'g' });
      setVitaminas(Object.entries(selectedIngredient.vitaminas || {}).map(([nombre, data]) => ({ nombre, valor: data.valor, unidad: data.unidad || 'g' })));
      setMinerales(Object.entries(selectedIngredient.minerales || {}).map(([nombre, data]) => ({ nombre, valor: data.valor, unidad: data.unidad || 'g' })));
      setCaloriasPorUnidad(calculateCalories(selectedIngredient.proteinas || { valor: 0, unidad: 'g' }, selectedIngredient.carbohidratos || { valor: 0, unidad: 'g' }, selectedIngredient.grasas || { valor: 0, unidad: 'g' }));
      setDietasAdecuadas(selectedIngredient.dietasAdecuadas || {
        vegetariano: false,
        vegano: false,
        pescetariano: false,
        libreDeGluten: true,
        esKeto: false,
        esCarne: false,
        libreDeLactosa: true,
        libreDeSoya: true,
      });
      if (selectedIngredient.sustitutos && listaIngredientes.length) {
        const sustitutosData = selectedIngredient.sustitutos.map(sustId =>
          listaIngredientes.find(ingredient => ingredient._id === sustId)
        ).filter(Boolean);
        setSustitutos(sustitutosData);
      }
    }
  }, [selectedIngredient, listaIngredientes]);

  useEffect(() => {
    setCaloriasPorUnidad(calculateCalories(proteinas, carbohidratos, grasas));
  }, [proteinas, carbohidratos, grasas]);

  const handleNutrientChange = (setter) => (e) => {
    const { name, value } = e.target;
    setter(prev => ({ ...prev, [name]: value }));
  };

  const handleVitaminChange = (index, field, value) => {
    const newVitamins = [...vitaminas];
    newVitamins[index][field] = value;
    setVitaminas(newVitamins);
  };

  const handleAddVitamin = () => {
    setVitaminas([...vitaminas, { nombre: '', valor: '', unidad: 'g' }]);
  };

  const handleRemoveVitamin = (index) => {
    setVitaminas(prev => prev.filter((_, i) => i !== index));
  };

  const handleMineralChange = (index, field, value) => {
    const newMinerals = [...minerales];
    newMinerals[index][field] = value;
    setMinerales(newMinerals);
  };

  const handleAddMineral = () => {
    setMinerales([...minerales, { nombre: '', valor: '', unidad: 'g' }]);
  };

  const handleRemoveMineral = (index) => {
    setMinerales(prev => prev.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const ingredientData = {
      nombre,
      sustitutos: sustitutos.map(sust => sust._id),
      caloriasPorUnidad,
      categoria,
      proteinas,
      carbohidratos,
      grasas,
      fibra,
      vitaminas: vitaminas.reduce((acc, { nombre, valor, unidad }) => ({ ...acc, [nombre]: { valor, unidad } }), {}),
      minerales: minerales.reduce((acc, { nombre, valor, unidad }) => ({ ...acc, [nombre]: { valor, unidad } }), {}),
      dietasAdecuadas,
    };

    try {
      if (selectedIngredient) {
        await axios.put(`${process.env.REACT_APP_API_URL}/api/ingredients/${selectedIngredient._id}`, ingredientData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/api/ingredients`, ingredientData, {
          headers: { Authorization: `Bearer ${token}` },
        });
      }
      setSelectedIngredient(null);
      fetchIngredients();
      onClose();
    } catch (error) {
      console.error('Error saving ingredient', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{selectedIngredient ? 'Editar Ingrediente' : 'Agregar Ingrediente'}</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Nombre"
            value={nombre}
            onChange={(e) => setNombre(e.target.value)}
            required
            fullWidth
          />
          <Autocomplete
            multiple
            options={listaIngredientes}
            getOptionLabel={(option) => option.nombre}
            filterSelectedOptions
            value={sustitutos}
            onChange={(event, newValue) => setSustitutos(newValue)}
            disableCloseOnSelect
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                const tagProps = getTagProps({ index });
                delete tagProps.key;
                return (
                  <Chip
                    key={option._id}
                    label={option.nombre}
                    {...tagProps}
                  />
                );
              })
            }
            renderInput={(params) => (
              <TextField {...params} label="Sustitutos" placeholder="Seleccionar sustitutos" />
            )}
          />
          <FormControl fullWidth>
            <InputLabel>Categoría</InputLabel>
            <Select
              value={categoria}
              onChange={(e) => setCategoria(e.target.value)}
              required
            >
              {categories.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Proteínas"
            type="number"
            value={proteinas.valor}
            onChange={handleNutrientChange(setProteinas)}
            name="valor"
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Unidad de proteínas</InputLabel>
            <Select
              value={proteinas.unidad}
              onChange={handleNutrientChange(setProteinas)}
              name="unidad"
              required
            >
              {units.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Carbohidratos"
            type="number"
            value={carbohidratos.valor}
            onChange={handleNutrientChange(setCarbohidratos)}
            name="valor"
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Unidad de carbohidratos</InputLabel>
            <Select
              value={carbohidratos.unidad}
              onChange={handleNutrientChange(setCarbohidratos)}
              name="unidad"
              required
            >
              {units.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Grasas"
            type="number"
            value={grasas.valor}
            onChange={handleNutrientChange(setGrasas)}
            name="valor"
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Unidad de grasas</InputLabel>
            <Select
              value={grasas.unidad}
              onChange={handleNutrientChange(setGrasas)}
              name="unidad"
              required
            >
              {units.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Fibra"
            type="number"
            value={fibra.valor}
            onChange={handleNutrientChange(setFibra)}
            name="valor"
            fullWidth
          />
          <FormControl fullWidth>
            <InputLabel>Unidad de fibra</InputLabel>
            <Select
              value={fibra.unidad}
              onChange={handleNutrientChange(setFibra)}
              name="unidad"
              required
            >
              {units.map((unit) => (
                <MenuItem key={unit} value={unit}>
                  {unit}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Calorías calculadas (kcal)"
            type="number"
            value={caloriasPorUnidad}
            InputProps={{ readOnly: true }}
            fullWidth
            style={{ marginTop: '10px' }}
          />
  
          {/* Campos para vitaminas */}
          <Box mt={2}>
            <h3>Vitaminas</h3>
            {vitaminas.map((vitamina, index) => (
              <Box key={index} display="flex" alignItems="center" mb={1}>
                <TextField
                  label="Nombre de Vitamina"
                  value={vitamina.nombre}
                  onChange={(e) => handleVitaminChange(index, 'nombre', e.target.value)}
                />
                <TextField
                  label="Valor"
                  type="number"
                  value={vitamina.valor}
                  onChange={(e) => handleVitaminChange(index, 'valor', e.target.value)}
                  style={{ marginLeft: '10px' }}
                />
                <FormControl fullWidth>
                  <InputLabel>Unidad</InputLabel>
                  <Select
                    value={vitamina.unidad}
                    onChange={(e) => handleVitaminChange(index, 'unidad', e.target.value)}
                  >
                    {units.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button onClick={() => handleRemoveVitamin(index)} style={{ marginLeft: '10px' }}>Eliminar</Button>
              </Box>
            ))}
            <Button onClick={handleAddVitamin}>Agregar Vitamina</Button>
          </Box>
  
          {/* Campos para minerales */}
          <Box mt={2}>
            <h3>Minerales</h3>
            {minerales.map((mineral, index) => (
              <Box key={index} display="flex" alignItems="center" mb={1}>
                <TextField
                  label="Nombre de Mineral"
                  value={mineral.nombre}
                  onChange={(e) => handleMineralChange(index, 'nombre', e.target.value)}
                />
                <TextField
                  label="Valor"
                  type="number"
                  value={mineral.valor}
                  onChange={(e) => handleMineralChange(index, 'valor', e.target.value)}
                  style={{ marginLeft: '10px' }}
                />
                <FormControl fullWidth>
                  <InputLabel>Unidad</InputLabel>
                  <Select
                    value={mineral.unidad}
                    onChange={(e) => handleMineralChange(index, 'unidad', e.target.value)}
                  >
                    {units.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Button onClick={() => handleRemoveMineral(index)} style={{ marginLeft: '10px' }}>Eliminar</Button>
              </Box>
            ))}
            <Button onClick={handleAddMineral}>Agregar Mineral</Button>
          </Box>
  
          {/* Checkboxes para dietas adecuadas */}
          <Box mt={2}>
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.vegetariano} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, vegetariano: e.target.checked }))} />}
              label="Vegetariano"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.vegano} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, vegano: e.target.checked }))} />}
              label="Vegano"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.pescetariano} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, pescetariano: e.target.checked }))} />}
              label="Pescetariano"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.libreDeGluten} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, libreDeGluten: e.target.checked }))} />}
              label="Libre de Gluten"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.esKeto} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, esKeto: e.target.checked }))} />}
              label="Keto"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.esCarne} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, esCarne: e.target.checked }))} />}
              label="Carne"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.libreDeLactosa} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, libreDeLactosa: e.target.checked }))} />}
              label="Libre de Lactosa"
            />
            <FormControlLabel
              control={<Checkbox checked={dietasAdecuadas.libreDeSoya} onChange={(e) => setDietasAdecuadas(prev => ({ ...prev, libreDeSoya: e.target.checked }))} />}
              label="Libre de Soya"
            />
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancelar</Button>
        <Button onClick={handleSubmit} color="primary">Guardar</Button>
      </DialogActions>
    </Dialog>
  );  
};

export default IngredientForm;