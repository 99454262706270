import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function ResetPasswordForm() {
    const { token } = useParams();
    const navigate = useNavigate();
    const [password, setPassword] = useState('');

    const handleInputChange = (e) => {
        setPassword(e.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        let errorMessage = '';
    
        if (password.length < 8) {
            errorMessage = 'La contraseña debe tener al menos 8 caracteres de longitud.';
        } else if (!/[a-z]/.test(password)) {
            errorMessage = 'La contraseña debe contener al menos una letra minúscula.';
        } else if (!/[A-Z]/.test(password)) {
            errorMessage = 'La contraseña debe contener al menos una letra mayúscula.';
        } else if (!/\d/.test(password)) {
            errorMessage = 'La contraseña debe contener al menos un número.';
        } else if (!/[\]!@#$%^&*_+=:;,./\\|~'?\\:_\\[-]/.test(password)) {
            errorMessage = 'La contraseña debe contener al menos un carácter especial.';
        }
    
        if (errorMessage) {
            Swal.fire('Error', errorMessage, 'error');
            return;
        }
    
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/reset/${token}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ password }),
            });
    
            if (!response.ok) {
                const errorData = await response.json();
                if (response.status === 400 && errorData.message === 'Token de restablecimiento de contraseña inválido o expirado') {
                    Swal.fire('Error', 'El link de restablecimiento de contraseña ha expirado.', 'error');
                    navigate('/forgot-password');
                    return;
                }
                throw new Error(errorData.message || 'Error al restablecer la contraseña');
            }
    
            Swal.fire('Éxito', 'Contraseña cambiada satisfactoriamente', 'success');
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
            Swal.fire('Error', error.message, 'error');
        }
    };       

    return (
        <form onSubmit={handleSubmit}>
            <input
                type="password"
                name="password"
                placeholder="Nueva contraseña"
                value={password}
                onChange={handleInputChange}
            />
            <button type="submit">Restablecer contraseña</button>
        </form>
    );
}

export default ResetPasswordForm;