import React, { useState, useEffect, useRef } from 'react';
import { TextField, Checkbox, List, ListItem, ListItemText, Button, Collapse, IconButton, Chip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

function CustomDropdown({ label, options, selectedOptions, onChange, allowNoAplica, onNoAplicaChange }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [categorySearchTerms, setCategorySearchTerms] = useState({});
  const [filteredOptions, setFilteredOptions] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);
  const [expandedPanels, setExpandedPanels] = useState({});
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    const newFilteredOptions = {};
    // let categoryMatched = false;
    Object.keys(options).forEach((cat) => {
      if (cat.toLowerCase().includes(searchTerm.toLowerCase())) {
        newFilteredOptions[cat] = options[cat]; // Show all options in the matched category
        // categoryMatched = true;
      } else {
        const filtered = options[cat].filter(option =>
          option.label.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (filtered.length > 0) {
          newFilteredOptions[cat] = filtered;
        }
      }
    });
    setFilteredOptions(newFilteredOptions);

    const newExpandedPanels = {};
    Object.keys(newFilteredOptions).forEach((cat) => {
      newExpandedPanels[cat] = searchTerm ? newFilteredOptions[cat].length > 0 : false;
    });
    setExpandedPanels(newExpandedPanels);

  }, [searchTerm, options]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropdown(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleCategoryToggle = (cat) => {
    setExpandedPanels(prev => ({ ...prev, [cat]: !prev[cat] }));
  };

  // CustomDropdown.js

const handleOptionToggle = (option) => {
  const currentIndex = selectedOptions.findIndex(selected => selected.id === option.value);
  const newSelectedOptions = [...selectedOptions];

  if (currentIndex === -1) {
    newSelectedOptions.push({
      id: option.value,
      nombre: option.label,
      refModel: 'Ingredients'
    });
  } else {
    newSelectedOptions.splice(currentIndex, 1);
  }

  if (allowNoAplica && option.value === 'No aplica') {
    if (currentIndex === -1) {
      newSelectedOptions.length = 0; // Deselect all if "No Aplica" is selected
      newSelectedOptions.push({
        id: 'No aplica',
        nombre: 'No aplica',
        refModel: null,
        nivelTolerancia: 0 // Asignar un valor por defecto que cumpla con el esquema
      });
    }
  } else {
    if (allowNoAplica) {
      const noAplicaIndex = newSelectedOptions.findIndex(selected => selected.id === 'No aplica');
      if (noAplicaIndex !== -1) {
        newSelectedOptions.splice(noAplicaIndex, 1); // Remove "No Aplica" if other options are selected
      }
    }
  }

  //console.log('handleOptionToggle - newSelectedOptions:', newSelectedOptions);
  onChange(newSelectedOptions);
};

  const handleSelectAll = (cat) => {
    const allOptions = filteredOptions[cat] || [];
    const newSelectedOptions = [
      ...selectedOptions.filter(selected => !options[cat].some(option => option.value === selected.id)),
      ...allOptions.map(option => ({
        id: option.value,
        nombre: option.label,
        refModel: 'Ingredients'
      }))
    ];
    //console.log('handleSelectAll - newSelectedOptions:', newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const handleDeselectAll = (cat) => {
    const remainingOptions = selectedOptions.filter(
      option => !filteredOptions[cat].some(filteredOption => filteredOption.value === option.id)
    );
    //console.log('handleDeselectAll - remainingOptions:', remainingOptions);
    onChange(remainingOptions);
  };

  const handleNoAplicaChange = (isSelected) => {
    onNoAplicaChange(isSelected);
    if (isSelected) {
      const noAplicaOption = [{ id: 'No aplica', nombre: 'No aplica', refModel: null }];
      //console.log('handleNoAplicaChange - noAplicaOption:', noAplicaOption);
      onChange(noAplicaOption);
    } else {
      onChange([]);
    }
  };

  const handleDeleteChip = (option) => {
    const newSelectedOptions = selectedOptions.filter(selected => selected.id !== option.id);
    //console.log('handleDeleteChip - newSelectedOptions:', newSelectedOptions);
    onChange(newSelectedOptions);
  };

  const handleDropdownToggle = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCategorySearchChange = (cat, value) => {
    setCategorySearchTerms(prev => ({ ...prev, [cat]: value }));
    setFilteredOptions(prev => ({
      ...prev,
      [cat]: options[cat].filter(option => option.label.toLowerCase().includes(value.toLowerCase()))
    }));
  };

  const selectedLabels = selectedOptions.map(option => option.nombre).join(', ');

  return (
    <div className="dropdown" style={{ position: 'relative', display: 'inline-block' }} ref={dropdownRef}>
      <TextField
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onClick={handleDropdownToggle}
        label={label}
        variant="outlined"
        fullWidth
        placeholder={selectedLabels}
        InputProps={{
          startAdornment: (
            <>
              {selectedOptions.map((option) => (
                <Chip
                  key={option.id}
                  label={option.nombre}
                  onDelete={() => handleDeleteChip(option)}
                  style={{ margin: 2 }}
                />
              ))}
            </>
          ),
        }}
      />
      {showDropdown && (
        <div id="myDropdown" className="dropdown-content" style={{ display: 'block', position: 'absolute', backgroundColor: '#f9f9f9', minWidth: '160px', boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)', zIndex: 1 }}>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton onClick={() => setShowDropdown(false)} style={{ position: 'absolute', right: 0 }}>
              <CloseIcon />
            </IconButton>
          </div>
          {allowNoAplica && (
            <Button onClick={() => handleNoAplicaChange(true)}>No Aplica</Button>
          )}
          {Object.keys(filteredOptions).map((cat) => (
            <div key={cat}>
              <Button className="accordion" onClick={() => handleCategoryToggle(cat)}>
                {cat}
              </Button>
              <Collapse in={expandedPanels[cat]}>
                <div className="panel">
                  <TextField
                    label="Buscar"
                    variant="outlined"
                    fullWidth
                    value={categorySearchTerms[cat] || ''}
                    onChange={(e) => handleCategorySearchChange(cat, e.target.value)}
                  />
                  <Button onClick={() => handleSelectAll(cat)}>Seleccionar todos</Button>
                  <Button onClick={() => handleDeselectAll(cat)}>Deseleccionar todos</Button>
                  <List>
                    {filteredOptions[cat].map((option) => (
                      <ListItem key={option.value} dense button onClick={() => handleOptionToggle(option)}>
                        <Checkbox
                          edge="start"
                          checked={selectedOptions.some(selected => selected.id === option.value)}
                          tabIndex={-1}
                          disableRipple
                        />
                        <ListItemText primary={option.label} />
                      </ListItem>
                    ))}
                  </List>
                </div>
              </Collapse>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default CustomDropdown;