import React, { useState, useEffect } from 'react';
import './Popup.css';
import { Tooltip } from '@mui/material';

const Popup = ({ onClose, onSave, initialValue, onDelete, showUnitsSelect }) => {
  const [selectedOption, setSelectedOption] = useState(initialValue?.option || initialValue);
  const [selectedUnit, setSelectedUnit] = useState(initialValue?.units || '');
  const [optionUnits, setOptionUnits] = useState(initialValue?.units || '');

  useEffect(() => {
    if (initialValue?.option === 'Elegir una opción' && initialValue?.units) {
      setSelectedOption('Elegir una opción');
      setOptionUnits(initialValue.units);
    } else {
      setSelectedOption(initialValue);
    }
  }, [initialValue]);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
    if (event.target.value === 'Elegir una opción') {
      setOptionUnits(''); // Reset the option units if 'Elegir una opción' is selected
    } else {
      setOptionUnits(''); // Reset the option units if any other option is selected
    }
  };

  const handleUnitChange = (event) => {
    setSelectedUnit(event.target.value);
    setSelectedOption(''); // Reset the option selection if a unit is selected
  };

  const handleOptionUnitsChange = (event) => {
    setOptionUnits(event.target.value);
  };

  const handleSave = () => {
    if (selectedOption === 'Elegir una opción') {
      onSave({ option: selectedOption, units: optionUnits });
    } else {
      onSave(selectedUnit || selectedOption);
    }
    onClose();
  };

  const handleDeleteClick = () => {
    onDelete();
    onClose();
  };

  return (
    <div className='popup_overlay'>
      <div className='popup_content'>
        <h3>Selecciona una opción</h3>
        <p>Lorem ipsum dolor sit amet.</p>
        <div className='popup_buttons_table'>
          <div className="radio_group">
            <input 
              type="radio" 
              id="unit" 
              name="option" 
              value="unit" 
              checked={selectedOption === '' && selectedUnit !== ''} 
              onChange={() => setSelectedOption('')} 
            />
            <select value={selectedUnit} onChange={handleUnitChange}>
              <option value="">Seleccionar cantidad específica</option>
              {[...Array(10).keys()].map(i => (
                <option key={i + 1} value={i + 1}>{i + 1}</option>
              ))}
            </select>
          </div>
          <div className="radio_group">
            <input 
              type="radio" 
              id="libre" 
              name="option" 
              value="Libre" 
              checked={selectedOption === 'Libre'} 
              onChange={handleOptionChange} 
            />
            <label htmlFor="libre">Libres</label>
          </div>
          <div className="radio_group">
            <Tooltip title="Elegir una opción significa que solo se puede consumir un grupo alimentario o en otro">
              <input 
                type="radio" 
                id="elegir" 
                name="option" 
                value="Elegir una opción" 
                checked={selectedOption === 'Elegir una opción'} 
                onChange={handleOptionChange} 
              />
            </Tooltip>
            <label htmlFor="elegir">Elegir una opción</label>
            {selectedOption === 'Elegir una opción' && (
              <select value={optionUnits} onChange={handleOptionUnitsChange}>
                <option value="">Seleccionar cantidad</option>
                {[...Array(10).keys()].map(i => (
                  <option key={i + 1} value={i + 1}>{i + 1}</option>
                ))}
              </select>
            )}
          </div>
        </div>
        <div className='popup_actions'>
          <button className="cancel_button" onClick={onClose}>Cancelar</button>
          <button className="save_button" onClick={handleSave}>Guardar</button>
          {initialValue !== 'Seleccionar' && (
            <button className="delete_button" onClick={handleDeleteClick}>Eliminar</button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Popup;