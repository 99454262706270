import React, { useContext } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { AuthContext } from './AuthContext';
import './AuthForm.css';
import facebookIcon from '../images/facebook.svg';
import microsoftIcon from '../images/microsoft.svg';
import googleIcon from '../images/google.svg';

function LoginForm({ formData, handleInputChange, rememberMe, setRememberMe }) {
    const { login, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if (!formData.email) {
            Swal.fire('Error', 'El email es requerido', 'error');
            return;
        }
    
        if (!formData.password) {
            Swal.fire('Error', 'La contraseña es requerida', 'error');
            return;
        }
    
        if (rememberMe) {
            localStorage.setItem('email', formData.email);
        }

        const url = `${process.env.REACT_APP_API_URL}/api/users/login`;
        //console.log('Sending login request with data:', formData);
    
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
                credentials: 'include'
            });

            const data = await response.json();
            //console.log('Login response data:', data);

            if (!response.ok) {
                Swal.fire('Error', data.message, 'error');
            } else {
                Swal.fire('Éxito', 'Operación exitosa', 'success');
                login(data.token);
                localStorage.setItem('token', data.token);
                navigate('/dashboard');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    if (isLoading) {
        return null;
    }

    return (
        <div className='login-form'>
            <h2>Bienvenido</h2>
            <p>Por favor ingrese su nombre de usuario y contraseña para acceder a su cuenta</p>
            <label className='label-form-auth'>Usuario</label>
            <input
                type="email"
                name="email"
                placeholder="Correo"
                value={formData.email}
                className='input-form-auth'
                onChange={handleInputChange}
            />
            <label className='label-form-auth'>Contraseña</label>
            <input
                type="password"
                name="password"
                placeholder="Contraseña"
                value={formData.password}
                className='input-form-auth'
                onChange={handleInputChange}
            />
            <div className="extras">
                <label>
                    <input type="checkbox" onChange={e => setRememberMe(e.target.checked)} className='checkbox'/>
                    <span className="checkbox-label">Recordarme</span>
                </label>
                <Link to="/forgot-password" className='olvide-contraseña'>
                    Has olvidado tu contraseña
                </Link>
            </div>
            <button type="submit" className='login-button' onClick={handleSubmit}>Entrar</button>
            <p>¿No tienes una cuenta? <Link to="/register"><strong>Registrate</strong></Link></p>
            <hr className="separator-line" />
            <div className="social-login">
                <div className='col-icon-log'>
                    <button type="button" className="social-button">
                        <img src={facebookIcon} alt="Facebook" />
                    </button>
                    Login con Facebook
                </div>
                <div className='col-icon-log'>
                    <button type="button" className="social-button">
                        <img src={microsoftIcon} alt="Microsoft" />
                    </button>
                    Login con Microsoft
                </div>
                <div className='col-icon-log'>
                    <button type="button" className="social-button">
                        <img src={googleIcon} alt="Google" />
                    </button>
                    Login con Google
                </div>
            </div>
        </div>
    );
}

export default LoginForm;