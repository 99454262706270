import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Despensa.css';
import SideMenu from '../../navigation/SideMenu';
import { AuthContext } from '../AuthContext';
import PaginaConstruccion from '../PagConst';

function Despensa() {
    const { isLoggedIn, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
          navigate('/login');
        }
      }, [isLoggedIn, isLoading, navigate]);

    return (
      <div className='flex_row'>
        <SideMenu />
        <PaginaConstruccion />
      </div>
    )
}

export default Despensa;