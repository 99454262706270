import React from "react";
import { Link, useLocation } from 'react-router-dom';
import './PagConst.css';

function PaginaConstruccion () {
    const location = useLocation();

    return (
        <div className={`construccion ${location.pathname === '/novedades' ? 'full-width' : ''}`}>
            <h1 className="nordique_bold">Pagina de Construccion</h1>
            <p className="nordique_normal">Esta pagina se encuentra en construccion</p>
            <Link to={location.pathname === '/novedades' ? '/home' : '/dashboard'}>
                {location.pathname === '/novedades' ? 'Regresar a Home' : 'Regresar al Dashboard'}
            </Link>
        </div>
    );
}

export default PaginaConstruccion;