import React, { useState } from 'react';
import { TextField, Box, MenuItem, Select, FormControl, InputLabel, Button } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const IngredientForm = ({ nombre, setNombre, nombreEstado, setNombreEstado, categoria, setCategoria, dificultad, setDificultad, ingredientes, ingredientesSeleccionados, setIngredientesSeleccionados }) => {
  const [ingredienteActual, setIngredienteActual] = useState(null);
  const [typingTimeout, setTypingTimeout] = useState(null);

  const handleAddIngredient = () => {
    if (ingredienteActual) {
      setIngredientesSeleccionados([...ingredientesSeleccionados, ingredienteActual]);
      setIngredienteActual(null);
    }
  };

  const handleNombreChange = (e) => {
    const newValue = e.target.value;
    setNombre(newValue);

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setTypingTimeout(setTimeout(() => {
      if (newValue.toLowerCase().includes(nombreEstado.toLowerCase())) {
        console.log(`Escribió "${newValue}" y se detectó el estado "${nombreEstado}"`);
      } else {
        console.log(`Escribió "${newValue}" y no se detectó el estado "${nombreEstado}"`);
      }
    }, 500)); // Tiempo de espera de 500 ms
  };

  const handleNombreEstadoChange = (e) => {
    const newValue = e.target.value;
    setNombreEstado(newValue);
    console.log(`Haz elegido el estado ${newValue}`);
  };

  return (
    <>
      <Box mb={2}>
        <TextField
          fullWidth
          label="Nombre"
          value={nombre}
          onChange={handleNombreChange}
          required
          variant="outlined"
        />
      </Box>
      <Box mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="nombre-estado-label">Estado del Nombre</InputLabel>
          <Select
            labelId="nombre-estado-label"
            value={nombreEstado}
            onChange={handleNombreEstadoChange}
            label="Estado del Nombre"
          >
            <MenuItem value="ninguno"><em>Ninguno</em></MenuItem>
            <MenuItem value="polvo">Polvo</MenuItem>
            <MenuItem value="picadillo">Picadillo</MenuItem>
            <MenuItem value="trozos">Trozos</MenuItem>
            <MenuItem value="trocitos">Trocitos</MenuItem>
            <MenuItem value="rebanadas">Rebanadas</MenuItem>
            <MenuItem value="luicianas">Luicianas</MenuItem>
            <MenuItem value="tiras">Tiras</MenuItem>
            <MenuItem value="aros">Aros</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="categoria-label">Categoría</InputLabel>
          <Select
            labelId="categoria-label"
            value={categoria}
            onChange={(e) => setCategoria(e.target.value)}
            label="Categoría"
          >
            <MenuItem value=""><em>Seleccionar</em></MenuItem>
            <MenuItem value="vegetales">Vegetales</MenuItem>
            <MenuItem value="proteinas">Proteínas</MenuItem>
            <MenuItem value="grasas">Grasas</MenuItem>
            <MenuItem value="azucares">Azúcares</MenuItem>
            <MenuItem value="frutas">Frutas</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box mb={2}>
        <Autocomplete
          value={ingredienteActual}
          onChange={(event, newValue) => {
            setIngredienteActual(newValue);
          }}
          options={ingredientes}
          getOptionLabel={(option) => option.nombre}
          renderInput={(params) => <TextField {...params} label="Tipo de Ingrediente" variant="outlined" />}
          noOptionsText="No hay opciones"
        />
      </Box>
      <Button onClick={handleAddIngredient} variant="contained" color="primary">Agregar Ingrediente</Button>
      <Box mb={2}>
        <FormControl fullWidth variant="outlined">
          <InputLabel id="dificultad-label">Dificultad</InputLabel>
          <Select
            labelId="dificultad-label"
            value={dificultad}
            onChange={(e) => setDificultad(e.target.value)}
            label="Dificultad"
          >
            <MenuItem value=""><em>Seleccionar</em></MenuItem>
            <MenuItem value="facil">Fácil</MenuItem>
            <MenuItem value="medio">Medio</MenuItem>
            <MenuItem value="avanzado">Avanzado</MenuItem>
            <MenuItem value="chef">Chef</MenuItem>
          </Select>
        </FormControl>
      </Box>
    </>
  );
};

export default IngredientForm;