const jaSurpriseMe = {
    texto: {
      surprise: '私を驚かせて',
      compatibility: '互換性の％',
      grams: 'グラム: ',
      time: '調理時間: ',
      minutes: '分',
      portions: 'ポーション',
      recipe: 'レシピを見る',
      settings: '設定',
      like: 'いいね',
      dislike: '嫌い',
    },
    sweetalert: {
      error: {
        title: 'エラー',
        text: 'お好みに合うレシピが見つかりませんでした',
      },
      noNewRecipes: {
        title: '新しいレシピがありません',
        text: 'お好みに合う新しいレシピが見つかりませんでした',
      },
      noMoreRecipes: {
        title: 'これ以上のレシピはありません',
        text: 'お好みに合うレシピがもう見つかりませんでした',
      },
      startOfHistory: {
        title: '履歴の開始',
        text: '履歴に前のレシピがありません',
      },
    },
  };
  
  export default jaSurpriseMe;  