import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(english);
const countryOptions = countries.getNames('en', { select: 'official' });

export const preguntasGastronomicas = [
  { id: 1, texto: 'Platillos favoritos (Los que más preferís)', tipo: 'Recipe', categoria: 'recipes', nombreCampo: 'platillosFavoritos' },
  { id: 2, texto: 'Ingredientes que disfrutas (Danos tus favoritos, con 3 está bien)', tipo: 'Ingredients', categoria: 'ingredients', nombreCampo: 'ingredientesDisfrutas' },
  { id: 3, texto: 'Sabores preferidos (Que buenos gustos tenés)', tipo: 'SaboresPreferidos', categoria: 'sabores_preferidos', nombreCampo: 'saboresPreferidos' },
];

export const preguntasRestricciones = [
  { id: 4, texto: 'Alimentos que no te gustan (Entre más abarques mejor)', tipo: 'Ingredients', categoria: 'ingredients', nombreCampo: 'alimentosQueNoGustan' },
  { id: 5, texto: 'Alimentos que evitas (Por una cuestión de salud o de objetivo nutricional)', tipo: 'Ingredients', categoria: 'ingredients', nombreCampo: 'alimentosQueEvitas' },
  { id: 6, texto: 'Alergias alimentarias (Recuerda mencionarlos todos)', tipo: 'Ingredients', categoria: 'ingredients', nombreCampo: 'alergiasAlimentarias' },
  { id: 7, texto: 'Intolerancias (No lo omitas, es importante)', tipo: 'Intolerancias', categoria: 'intolerancias', nombreCampo: 'intolerancias' },
];

export const preguntasHabitos = [
  { id: 8, texto: 'Tipo de dieta', tipo: 'TipoDeDieta', categoria: 'tipo_de_dieta', nombreCampo: 'tipoDieta' },
  { id: 9, texto: 'Frecuencia de consumo de carne', tipo: 'FrecuenciaConsumoCarne', categoria: 'frecuencia_consumo_carne', nombreCampo: 'frecuenciaConsumoCarne' },
  { id: 10, texto: 'Preferencias de bebidas', tipo: 'PreferenciasBebidas', categoria: 'preferencias_bebidas', nombreCampo: 'preferenciasBebidas' },
];

export const preguntasSalud = [
  { id: 11, texto: 'Objetivos nutricionales', tipo: 'ObjetivosNutricionales', categoria: 'objetivos_nutricionales', nombreCampo: 'objetivosNutricionales' },
  { id: 12, texto: 'Enfermedades o condiciones de salud', tipo: 'EnfermedadesSalud', categoria: 'enfermedades_salud', nombreCampo: 'enfermedadesSalud' },
  { id: 13, texto: 'Suplementos o vitaminas que consumes', tipo: 'SuplementosVitaminas', categoria: 'suplementos_vitaminas', nombreCampo: 'suplementosVitaminas' },
];

export const preguntasCultura = [
  { id: 14, texto: 'De que país se basa tu dieta', tipo: 'ComidasDePaises', categoria: 'paises', nombreCampo: 'comidasDisfrutas' },
  { id: 15, texto: 'Platillos internacionales que te gustaría probar', tipo: 'Recipe', categoria: 'recipes', nombreCampo: 'platillosInternacionales' },
];

// Opciones de países
export const countryOptionsList = Object.keys(countryOptions).map(key => ({ label: countryOptions[key], value: key }));