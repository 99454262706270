import React, { useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { TextField, Button, CircularProgress, Backdrop } from '@mui/material';
import useAuthValidation from '../../../hooks/useAuthValidation';

const InvitacionPaciente = ({ onInvitationSent }) => {
  const { userId, token } = useAuthValidation();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendInvitation = async () => {
    setLoading(true);
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/send-invitation`, { email, doctorId: userId }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      Swal.fire('Invitación Enviada', response.data.message, 'success');
      setEmail('');
      onInvitationSent(); // Notify parent component to refresh invitations
    } catch (error) {
      if (error.response && error.response.status === 400) {
        Swal.fire('Usuario Ya Existe', 'El usuario ya existe en el sistema.', 'warning');
      } else {
        Swal.fire('Error', 'No se pudo enviar la invitación', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <h2>Enviar Invitación</h2>
      <TextField
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        placeholder="Correo electrónico del paciente"
        fullWidth
      />
      <Button onClick={handleSendInvitation} variant="contained" color="primary">Enviar Invitación</Button>
      <Backdrop open={loading} style={{ zIndex: 1300, color: '#fff' }}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
};

export default InvitacionPaciente;
