import React, { useState, useEffect, useCallback } from 'react';
import '../../tablanutricional/TablaNutricional.css';
import Popup from '../../tablanutricional/Popup';
import ConfigPopup from '../../tablanutricional/ConfigPopup';
import useAuthValidation from '../../../hooks/useAuthValidation';
import fetchTablas from '../../tablanutricional/fetchTablas';
import handleCellClick from '../../tablanutricional/handleCellClick';
import handleSave from '../../tablanutricional/handleSave';
import handleDelete from '../../tablanutricional/handleDelete';
import handleAddTabla from '../../tablanutricional/handleAddTabla';
import handleConfigSave from '../../tablanutricional/handleConfigSave';
import calculateTotalPortions from '../../tablanutricional/calculateTotalPortions';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import axios from 'axios';
import Swal from 'sweetalert2';

const TablaNutricionalNutricionista = ({ email, onNewTable, invitationId, tableType, tableIds = [], setHasFetchedFalse, userId, context }) => {
  const { userId: nutritionistId, isLoading, token } = useAuthValidation();
  const [tablas, setTablas] = useState([]);
  const [activeTabla, setActiveTabla] = useState(null);
  const [selectedCell, setSelectedCell] = useState({ group: '', col: null });
  const [showPopup, setShowPopup] = useState(false);
  const [showConfigPopup, setShowConfigPopup] = useState(false);
  const [configInitialData, setConfigInitialData] = useState({});
  const [warnings, setWarnings] = useState('');
  const [showUnitsSelect, setShowUnitsSelect] = useState(false);
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (typeof setHasFetchedFalse === 'function') {
      setHasFetchedFalse();
    }
  }, [invitationId, setHasFetchedFalse]);

  const fetchTablasCallback = useCallback(async () => {
    const fetchUserId = context === 'administrarpaciente' ? userId : nutritionistId;
    if (!fetchUserId) return;

    try {
      console.log('fetchTablasCallback called');
      await fetchTablas({
        userId: fetchUserId,
        source: 'nutricionista',
        tableIds,
        setTablas,
        setActiveTabla,
        setHasFetched
      });
      setHasFetched(true);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  }, [userId, nutritionistId, context, tableIds, setHasFetched]);

  useEffect(() => {
    fetchTablasCallback();
  }, [fetchTablasCallback]);

  const getMealTime = (col) => {
    switch (col) {
      case 1: return 'desayuno';
      case 2: return 'merienda1';
      case 3: return 'almuerzo';
      case 4: return 'merienda2';
      case 5: return 'cena';
      case 6: return 'merienda3';
      default: return '';
    }
  };

  const handleDeleteTabla = async (tablaId) => {
    try {
      const tabla = tablas.find(tabla => tabla._id === tablaId);
      let secondaryTable = null;
      if (tabla.tipo === 'principal') {
        secondaryTable = tablas.find(tabla => tabla.tipo === 'secundaria');
        if (secondaryTable) {
          await axios.delete(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${secondaryTable._id}?context=${context}`, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
        }
      }
  
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${tablaId}?context=${context}`, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const updatedTablas = tablas.filter(tabla => tabla._id !== tablaId && (!secondaryTable || tabla._id !== secondaryTable._id));
      setTablas(updatedTablas);
      setActiveTabla(updatedTablas.length > 0 ? updatedTablas[0] : null);
      console.log('Deleted table from database');
    } catch (error) {
      console.error('Error deleting table:', error);
    }
  };  

  const handleConfigTabla = (tabla) => {
    setConfigInitialData(tabla);
    setShowConfigPopup(true);
  };

  const handleBlockTabla = async (tablaId, isBlocked) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/nutritionist/block-tabla`, { tablaId, isBlocked }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const updatedTablas = tablas.map(tabla => tabla._id === tablaId ? { ...tabla, isBlocked: response.data.isBlocked } : tabla);
      setTablas(updatedTablas);
      setActiveTabla(updatedTablas.find(tabla => tabla._id === tablaId));
    } catch (error) {
      console.error('Error blocking/unblocking tabla:', error);
      Swal.fire('Error', 'Hubo un problema al bloquear/desbloquear la tabla.', 'error');
    }
  };

  if (isLoading || !nutritionistId) {
    return <div>Loading...</div>;
  }

  if (!hasFetched) {
    return null;
  }

  return (
    <div>
      <h2>Crear Tabla Nutricional</h2>
      <div className='tabla_buttons'>
        {tablas.map((tabla, index) => (
          <div key={index} className="tabla_button_wrapper">
            <button
              className={activeTabla && activeTabla._id === tabla._id ? 'selected' : ''}
              onClick={() => setActiveTabla(tabla)}
            >
              {tabla.nombre}
            </button>
            <IconButton onClick={() => handleDeleteTabla(tabla._id)}>
              <DeleteIcon />
            </IconButton>
            <IconButton onClick={() => handleConfigTabla(tabla)}>
              <EditIcon />
            </IconButton>
            <IconButton onClick={() => handleBlockTabla(tabla._id, !tabla.isBlocked)}>
              {tabla.isBlocked ? <LockIcon /> : <LockOpenIcon />}
            </IconButton>
          </div>
        ))}
        {tablas.length < 2 && (
          <button onClick={() => handleAddTabla('Nueva Tabla', false, setConfigInitialData, setShowConfigPopup, userId, 'nutricionista', email, null, token, invitationId, context)}>
            +
          </button>
        )}
      </div>
      {activeTabla && (
        <div>
          <h2>{activeTabla.nombre}</h2>
          {warnings && (
            <div style={{ color: 'red' }}>
              <p>{warnings}</p>
            </div>
          )}
          <table>
            <thead>
              <tr>
                <th>Grupo de Alimentos</th>
                <th>Porciones Totales</th>
                <th>Desayuno</th>
                <th>Merienda 1</th>
                <th>Almuerzo</th>
                <th>Merienda 2</th>
                <th>Cena</th>
                <th>Merienda 3</th>
              </tr>
            </thead>
            <tbody>
              {['lacteos', 'vegetales', 'frutas', 'azucar', 'harinas', 'proteinas', 'grasas'].map((group) => (
                <tr key={group}>
                  <td>{group.charAt(0).toUpperCase() + group.slice(1)}</td>
                  <td>{calculateTotalPortions(group, activeTabla)}</td>
                  {[1, 2, 3, 4, 5, 6].map((colIndex) => (
                    <td key={colIndex}>
                      <button onClick={() => handleCellClick(group, colIndex, activeTabla, setWarnings, setSelectedCell, setShowPopup, setShowUnitsSelect, getMealTime)}>
                        {activeTabla.tableData[group][colIndex] || 'Seleccionar'}
                      </button>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
          {showPopup && (
            <Popup
              onClose={() => setShowPopup(false)}
              onSave={(value) => handleSave(value, activeTabla, selectedCell, setActiveTabla, setShowPopup, setWarnings, setTablas, tablas, getMealTime)}
              onDelete={() => handleDelete(activeTabla, selectedCell, setActiveTabla, setShowPopup, setTablas, tablas, getMealTime)}
              initialValue={activeTabla.tableData[selectedCell.group][selectedCell.col]}
              showUnitsSelect={showUnitsSelect}
            />
          )}
        </div>
      )}
      {showConfigPopup && (
        <ConfigPopup
          onClose={() => setShowConfigPopup(false)}
          onSave={(data) => handleConfigSave(data, tableType, userId, 'nutricionista', setTablas, setShowConfigPopup, setActiveTabla, onNewTable, email, token, context)}
          initialName={configInitialData.nombre || ''}
          initialData={configInitialData}
        />
      )}
    </div>
  );
};

export default TablaNutricionalNutricionista;