// src/components/Dashboard.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useAuthValidation from '../hooks/useAuthValidation';
import 'reactjs-popup/dist/index.css';
import './Dashboard.css';
import SideMenu from '../navigation/SideMenu';
import InternalMenu from '../navigation/InternalMenu';
import SurpriseMe from './recetas/SurpriseMe';

function Dashboard() {
  const { t } = useTranslation(); // Hook para obtener la función de traducción
  const {
    firstName,
    userId,
    token,
    isLoading,
    hasCompletedForm,
  } = useAuthValidation();

  if (isLoading) {
    return <p>{t('loading')}</p>;
  }

  return (
    <div className={`dashboard_flex`}>
      <SideMenu />
      <div className="main_content">
        <InternalMenu />
        <div>
          <h1>{t('welcomeMessage', { firstName })}</h1>
          {hasCompletedForm ? (
            <div className='flex_row'>
              <Link to="/descubrir" className="button choose">{t('chooseMeal')}</Link>
              <SurpriseMe userId={userId} token={token} />
            </div>
          ) : (
            <p>{t('completeProfile')}</p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;