import axios from 'axios';
import updateTotalPortions from './updateTotalPortions';

const handleDelete = async (activeTabla, selectedCell, setActiveTabla, setShowPopup, setTablas, tablas, getMealTime) => {
  try {
    // Verificación adicional
    if (typeof activeTabla === 'string') {
      activeTabla = tablas.find(tabla => tabla._id === activeTabla);
    }

    //console.log('handleDelete called with:', { activeTabla, selectedCell });

    if (!activeTabla || !selectedCell || !selectedCell.group || selectedCell.col === null) {
      console.error('Invalid activeTabla or selectedCell:', { activeTabla, selectedCell });
      return;
    }

    const updatedTableData = { ...activeTabla.tableData };
    const mealTime = getMealTime(selectedCell.col);
    const removedOption = updatedTableData[selectedCell.group][selectedCell.col];

    if (removedOption && removedOption.includes('Elegir una opción')) {
      activeTabla.elegirOpcionIndices[mealTime] -= 1;
    }

    updatedTableData[selectedCell.group][selectedCell.col] = 'Seleccionar';

    const updatedTableDataWithTotals = updateTotalPortions(updatedTableData);

    setActiveTabla({ ...activeTabla, tableData: updatedTableDataWithTotals });
    setShowPopup(false);

    await axios.put(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${activeTabla._id}`, {
      tableData: updatedTableDataWithTotals,
      elegirOpcionIndices: activeTabla.elegirOpcionIndices
    });
    setTablas(tablas.map(tabla => tabla._id === activeTabla._id ? { ...tabla, tableData: updatedTableDataWithTotals } : tabla));
    //console.log('Deleted table data from database');
  } catch (error) {
    console.error('Error deleting table data:', error);
  }
};

export default handleDelete;