import React, { useState, useEffect, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import logo from '../images/logo.png'; // Logo de escritorio
import logoMobile from '../images/logo-mobile.png'; // Logo móvil
import './Menu.css';
import { AuthContext } from '../components/AuthContext';

function Menu({ impactoRef, nosotrosRef, preciosRef }) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 481);
    const location = useLocation();
    const { isLoggedIn } = useContext(AuthContext);

    const updateMedia = () => {
        setIsMobile(window.innerWidth < 481);
    };

    useEffect(() => {
        window.addEventListener('resize', updateMedia);
        return () => window.removeEventListener('resize', updateMedia);
    });

    const toggleMenu = () => {
        setIsMenuOpen(prevIsMenuOpen => {
            return !prevIsMenuOpen;
        });
    };    

    return (
        <div className="menu">
            {isMobile && (
                <div className="hamburger-menu" onClick={toggleMenu}>
                    ☰
                </div>
            )}
            <Link to="/" id="link-logo">
                <img src={isMobile ? logoMobile : logo} alt="Logo" className="logo"/>
            </Link>
            <div className={`middle-menu ${isMenuOpen ? 'show' : ''}`}>
            <Link to="/">Inicio</Link>
            <button onClick={() => impactoRef.current.scrollIntoView({ behavior: 'smooth' })}>
            Nuestro Impacto
            </button>
            <button onClick={() => nosotrosRef.current.scrollIntoView({ behavior: 'smooth' })}>
            Funciones
            </button>
            <button onClick={() => preciosRef.current.scrollIntoView({ behavior: 'smooth' })}>
            Precios
            </button>
                {isMobile && <Link to="/contribuir" className="button-6">Contribuir</Link>}
            </div>
            <div className="end-menu">
                {isLoggedIn ? (
                    <Link to="/dashboard" className="button-5">Dashboard</Link>
                ) : (
                    location.pathname !== '/login' && <Link to="/login" className="button-5">Login</Link>
                )}
                {!isMobile && <Link to="/novedades" className="button-6">Novedades</Link>}
            </div>
        </div>
    );
}

export default Menu;