import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import useAuthValidation from '../hooks/useAuthValidation';
import Formulario from '../forms/Formulario';
import './Bienvenida.css';
import logo from '../images/unifood-blanco.png';
import smile from '../images/smiley-wink.png';

function Bienvenida() {
  const {
    firstName,
    token,
    isLoggedIn,
    hasCompletedForm,
    completeForm
  } = useAuthValidation();
  
  const [nombre, setNombre] = useState(firstName || '');
  const [nivel, setNivel] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  const handleNivelClick = (nivelSeleccionado) => {
    setNivel(nivelSeleccionado);
    setShowForm(true);
  };

  const handleHideForm = () => {
    setShowForm(false);
  };

  useEffect(() => {
    if (isLoggedIn && token) {
      const fetchUserData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/me`, {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (!response.ok) {
            throw new Error('No estás autenticado');
          }
          const data = await response.json();
          setNombre(data.nombre);
          completeForm(data.hasCompletedForm);
        } catch (error) {
          console.error('Error:', error);
        }
      };
      fetchUserData();
    }
  }, [isLoggedIn, token, completeForm]);

  useEffect(() => {
    if (hasCompletedForm) {
      navigate('/dashboard');
    }
  }, [hasCompletedForm, navigate]);

  if (showForm) {
    return <Formulario nivel={nivel} hideForm={handleHideForm} />;
  } else {
    return (
      <div className="bienvenida-container">
        <div className="left-side">
          <Link to="/home">
            <img src={logo} alt="Unifood Logo" className="logo" />
          </Link>
          <p className="trial-text">Disfruta de tus 3 meses gratis de prueba</p>
        </div>
        <div className="right-side">
          <div className="content">
            <img src={smile} alt="Smiley face" className="smiley" />
            <h1>Gracias por ser parte de Unifood</h1>
            <p>Hola, {nombre}. Por favor, elija entre el formulario básico, medio o avanzado para proporcionar la información necesaria.<br />Recuerde que la diferencia radica en la cantidad de detalles que recopilaremos para comprender mejor sus necesidades y preferencias.</p>
            <button className="custom-button" onClick={() => handleNivelClick('basic')}>Personalización Básica (3 min)</button>
            <button className="custom-button" onClick={() => handleNivelClick('medium')}>Personalización Intermedia (8 min)</button>
            <button className="custom-button" onClick={() => handleNivelClick('advanced')}>Personalización Avanzada (15 min)</button>
          </div>
        </div>
      </div>
    );
  }
}

export default Bienvenida;