const esTranslations = {
    menu: {
      statusActive: 'Estado: Activo',
      statusInactive: 'Estado: Inactivo',
      editAccount: 'Editar Cuenta',
      settings: 'Configuración',
      support: 'Soporte',
      logout: 'Cerrar Sesión',
    },
    search: {
      placeholder: 'Buscar…',
    },
    notifications: {
      noNew: 'No hay notificaciones nuevas',
    },
    languages: {
      es: 'ES',
      en: 'EN',
      ja: 'JP',
      it: 'IT',
    },
  };  

  export default esTranslations;