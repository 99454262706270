// ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuthValidation from '../hooks/useAuthValidation';

const ProtectedRoute = ({ component: Component, requiredRoles }) => {
  // Obtener los roles del usuario desde useAuthValidation
  const { isMedical, superUser } = useAuthValidation(); 

  // Convertir los roles en un array para facilitar la verificación
  const roles = [];
  if (isMedical) roles.push('doctor');
  if (superUser) roles.push('superUser');

  // Debugging: Log the roles and requiredRoles to see what is being handled
  console.log('Current user roles:', roles);
  console.log('Required roles for this route:', requiredRoles);

  // Verifica si el usuario tiene al menos uno de los roles requeridos
  const hasRequiredRole = requiredRoles.some((role) => roles.includes(role));

  if (!hasRequiredRole) {
    // Debugging: Log the reason for redirection
    console.log('User does not have required roles. Redirecting to dashboard.');
    return <Navigate to="/dashboard" />;
  }

  // Debugging: Log if access is granted
  console.log('User has required roles. Access granted. Rendering component.');

  // Si tiene el rol requerido, renderiza el componente recibido como prop
  return (
    <div>
      {console.log('Rendering the passed component inside ProtectedRoute.')}
      <Component />
    </div>
  );
};

export default ProtectedRoute;