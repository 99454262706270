import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import AuthForm from './components/AuthForm';
import Dashboard from './components/Dashboard';
import { AuthProvider } from './components/AuthContext';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import Home from './components/Home';
import ResetPasswordForm from './components/ResetPasswordForm';
import Impacto from './navigation/Impacto';
import Nosotros from './navigation/Nosotros';
import Precios from './navigation/Precios';
import ConfirmSubscription from './components/ConfirmSubscription';
import Subsidio from './forms/FormSubsidio';
import Bienvenida from './components/Bienvenida';
import Descubrir from './components/descubrir/Descubrir';
import Despensa from './components/despensa/Despensa';
import Historial from './components/historial/Historial';
import Preferencias from './components/preferencias/Preferencias';
import Configuracion from './components/configuracion/Configuracion';
import Soporte from './components/soporte/Soporte';
import Novedades from './navigation/Novedades';
import Recetas from './components/recetas/CreateRecipe';
import Suscripcion from './forms/FormPayment';
import NutricionistaPanel from './components/nutricionista/NutricionistaPanel';
import PrivateRoute from './components/PrivateRoute';
import LogoutButton from './components/LogoutButton';
import ConfirmAccount from './components/ConfirmAccount';
import Recipes from './components/recetas/Recipes';
import IngredientRecipeForm from './components/recetas/forms/RecetaIngrediente/IngredientRecipeForm';
import IngredientPage from './components/recetas/forms/ingredientes/IngredientPage';
import ProtectedRoute from './components/ProtectedRoute';
import UserManagement from './components/administrativo/usuario/UserManagement';
import './App.css';

function App() {
  
  return (
    <I18nextProvider i18n={i18n}>
      <AuthProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<AuthForm isLogin={true} />} />
            <Route path="/register" element={<AuthForm isLogin={false} />} />
            <Route path="/register/:token" element={<AuthForm isLogin={false} />} />
            <Route path="/" element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm />} />
            <Route path="/reset/:token" element={<ResetPasswordForm />} />
            <Route path="/confirm/:token" element={<ConfirmAccount />} />
            <Route path="/confirm-subscription" element={<ConfirmSubscription />} />
            <Route path="/impacto" element={<Impacto />} />
            <Route path="/nosotros" element={<Nosotros />} />
            <Route path="/precios" element={<Precios />} />
            <Route path="/novedades" element={<Novedades />} />
            <Route path="/subsidio" element={<Subsidio />} />
            <Route path="/bienvenida" element={<><Bienvenida /><LogoutButton /></>} />
            <Route path="*" element={<Navigate to="/" />} />

            {/* Rutas privadas */}
            <Route element={<PrivateRoute />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/descubrir" element={<Descubrir />} />
              <Route path="/despensa" element={<Despensa />} />
              <Route path="/historial" element={<Historial />} />
              <Route path="/preferencias" element={<Preferencias />} />
              <Route path="/configuracion" element={<Configuracion />} />
              <Route path="/soporte" element={<Soporte />} />
              <Route path="/suscripcion" element={<Suscripcion />} />
              <Route path="/nutricionista" element={<ProtectedRoute component={NutricionistaPanel} requiredRoles={['doctor', 'superUser']} />} />
              <Route path="/recetas" element={<ProtectedRoute component={Recipes} requiredRoles={['superUser']} />} />
              <Route path="/recetas/ingredientes" element={<ProtectedRoute component={IngredientRecipeForm} requiredRoles={['superUser']} />} />
              <Route path="/recetas/completas" element={<ProtectedRoute component={Recetas} requiredRoles={['superUser']} />} />
              <Route path="/ingredientes" element={<ProtectedRoute component={IngredientPage} requiredRoles={['superUser']} />} />
              <Route path="/admin/usuarios" element={<ProtectedRoute component={UserManagement} requiredRoles={['superUser']} />} />
            </Route>
          </Routes>
        </Router>
      </AuthProvider>
    </I18nextProvider>
  );
}

export default App;