import Menu from './Menu';

function Precios() {
    return (
        <div className="precios">
            <Menu/>
        </div>
    )
}

export default Precios;