import React from 'react';
import './Novedades.css';
import PaginaConstruccion from '../components/PagConst';

function Novedades() {
    return (
      <div className='flex_row'>
        <PaginaConstruccion />
      </div>
    )
}

export default Novedades;