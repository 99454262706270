import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const ConfirmAccount = () => {
    const { token } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const confirmAccount = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/confirm/${token}`, {
                    method: 'GET',
                });

                const data = await response.json();

                if (response.ok) {
                    Swal.fire('Éxito', data.message, 'success').then(() => {
                        navigate('/login');
                    });
                } else {
                    Swal.fire('Error', data.message, 'error').then(() => {
                        navigate('/login');
                    });
                }
            } catch (error) {
                console.error('Error:', error);
                Swal.fire('Error', 'Hubo un problema al confirmar tu cuenta.', 'error').then(() => {
                    navigate('/login');
                });
            } finally {
                setLoading(false);
            }
        };

        confirmAccount();
    }, [token, navigate]);

    if (loading) {
        return <div>Cargando...</div>;
    }

    return null; // No se necesita renderizar nada ya que redirigimos al usuario
};

export default ConfirmAccount;