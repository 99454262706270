import { useState, useEffect } from 'react';
import axios from 'axios';

const useFetchIngredients = (token) => {
  const [ingredientes, setIngredientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (token) {
      setLoading(true);
      axios.get(`${process.env.REACT_APP_API_URL}/api/ingredients/paginacion`, {
        headers: { Authorization: `Bearer ${token}` }
      })
        .then(response => {
          console.log('API Response:', response.data);
          const opciones = response.data.ingredients.flatMap(ingredient => ingredient.opciones);
          setIngredientes(opciones);
          setLoading(false);
        })
        .catch(err => {
          console.error('Error fetching ingredients:', err);
          setError(err);
          setLoading(false);
        });
    }
  }, [token]);  

  return { ingredientes, loading, error };
};

export default useFetchIngredients;