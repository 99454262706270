import React, { useState, useRef, useEffect, useCallback } from 'react';
import { Box, TextField, Button, FormControlLabel, Checkbox, CircularProgress, Typography } from '@mui/material';
import axios from 'axios';
import Swal from 'sweetalert2';
import SelectImageModal from './SelectImageModal'; // Asegúrate de importar el modal
import './InstructionList.css';

const InstructionList = ({ instrucciones, setInstrucciones, token, promptHistory, setPromptHistory, nombreEstado }) => {
  const [generandoImagen, setGenerandoImagen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageModalTargetIndex, setImageModalTargetIndex] = useState(null); // Índice de la instrucción para la cual se selecciona la imagen
  const [detectedStates, setDetectedStates] = useState(Array(instrucciones.length).fill(''));
  const typingTimeoutRef = useRef(null);

  const handleInstructionChange = (index, field, value) => {
    const newInstructions = [...instrucciones];
    newInstructions[index][field] = value;
    setInstrucciones(newInstructions);
    clearTimeout(typingTimeoutRef.current);

    typingTimeoutRef.current = setTimeout(() => {
      analyzeText(index, value);
    }, 500);
  };

  const analyzeText = useCallback((index, text) => {
    if (text && nombreEstado) {
      const regex = new RegExp(`\\b${nombreEstado}\\b`, 'gi');
      const isDetected = regex.test(text);

      setDetectedStates(prev => {
        const newDetectedStates = [...prev];
        newDetectedStates[index] = isDetected ? nombreEstado : '';
        return newDetectedStates;
      });

      console.log(`Instrucción: "${text}" - Estado detectado: ${isDetected ? "Sí" : "No"}`);
    } else {
      setDetectedStates(prev => {
        const newDetectedStates = [...prev];
        newDetectedStates[index] = '';
        return newDetectedStates;
      });

      console.log(`Instrucción: "${text}" - Estado detectado: No`);
    }
  }, [nombreEstado]);

  useEffect(() => {
    instrucciones.forEach((instruccion, index) => {
      analyzeText(index, instruccion.descripcion);
    });
  }, [instrucciones, analyzeText]);

  const handleFileChange = (e, index, field) => {
    const file = e.target.files[0];
    setSelectedFile({ file, index, field });
  };

  const handleUploadFile = async () => {
    if (!selectedFile) return;

    const { file, index, field } = selectedFile;
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload`, formData, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      const imageUrl = response.data.url;
      handleInstructionChange(index, field, imageUrl);
      setSelectedFile(null);
    } catch (error) {
      console.error('Error uploading image:', error);
      Swal.fire('Error', 'Ocurrió un error al subir la imagen', 'error');
    }
  };

  const handleGenerateImage = async (index) => {
    setGenerandoImagen(true);
    const prompt = instrucciones[index].descripcion;
    const fullPrompt = `${promptHistory} ${prompt}`;

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/generate-image`, { prompt: fullPrompt }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const localImageUrl = response.data.imageUrl;

      const uploadResponse = await axios.post(`${process.env.REACT_APP_API_URL}/api/upload/generateImageUrl`, { imageUrl: localImageUrl }, {
        headers: { 'Authorization': `Bearer ${token}` }
      });

      const publicUrl = uploadResponse.data.url;
      handleInstructionChange(index, 'imagen', publicUrl);
      setPromptHistory(fullPrompt);
    } catch (error) {
      console.error('Error generating or uploading image:', error);
      Swal.fire('Error', 'Ocurrió un error al generar o subir la imagen', 'error');
    } finally {
      setGenerandoImagen(false);
    }
  };

  const handleDeleteInstruction = (index) => {
    setInstrucciones(prev => prev.filter((_, i) => i !== index));
  };

  const handleSelectImage = (url) => {
    const newInstructions = [...instrucciones];
    newInstructions[imageModalTargetIndex].imagen = url;
    setInstrucciones(newInstructions);
    setOpenImageModal(false);
  };

  return (
    <>
      {instrucciones.map((instruccion, index) => (
        <Box key={index} mb={2} position="relative">
          <Typography variant="h6">Instrucción {index + 1}</Typography>
          <Box className="textarea-container" position="relative">
            <textarea
              value={instruccion.descripcion}
              onChange={(e) => handleInstructionChange(index, 'descripcion', e.target.value)}
              className="textarea"
              rows={4}
            />
          </Box>
          {detectedStates[index] && (
            <Typography variant="body2" style={{ color: 'green' }}>
              Éxito: Estado "<strong>{detectedStates[index]}</strong>" detectado.
            </Typography>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={instruccion.tieneCronometro}
                onChange={(e) => handleInstructionChange(index, 'tieneCronometro', e.target.checked)}
              />
            }
            label="Añadir Cronómetro"
          />
          {instruccion.tieneCronometro && (
            <TextField
              label="Tiempo (minutos)"
              type="number"
              value={instruccion.tiempo}
              onChange={(e) => handleInstructionChange(index, 'tiempo', e.target.value)}
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}
          <Box mt={2}>
            <Button variant="contained" component="label">
              Subir Imagen o Video
              <input
                type="file"
                accept="image/*,video/*"
                hidden
                onChange={(e) => handleFileChange(e, index, e.target.files[0].type.startsWith('image') ? 'imagen' : 'video')}
              />
            </Button>
            {selectedFile && selectedFile.index === index && (
              <Button onClick={handleUploadFile} variant="outlined" color="primary">
                Subir
              </Button>
            )}
            <Button onClick={() => handleGenerateImage(index)} variant="outlined" color="primary" disabled={generandoImagen}>
              Generar Imagen por IA
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setImageModalTargetIndex(index);
                setOpenImageModal(true);
              }}
            >
              Seleccionar Imagen desde Biblioteca
            </Button>
            {generandoImagen && (
              <Box display="flex" alignItems="center" mt={2}>
                <CircularProgress size={24} />
                <Typography variant="body1" ml={2}>Generando imagen...</Typography>
              </Box>
            )}
          </Box>
          {instruccion.imagen && (
            <Box mt={2}>
              <Typography variant="body1">Imagen de Instrucción:</Typography>
              <img src={instruccion.imagen} alt={`Instrucción ${index + 1}`} style={{ maxWidth: '100%' }} />
            </Box>
          )}
          {instrucciones.length > 1 && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => handleDeleteInstruction(index)}
              style={{ marginTop: '10px' }}
            >
              Eliminar Instrucción
            </Button>
          )}
        </Box>
      ))}
      <Button onClick={() => setInstrucciones([...instrucciones, { descripcion: '', imagen: '', video: '', prompt: '', tiempo: '', tieneCronometro: false }])}>
        Añadir Instrucción
      </Button>
      <SelectImageModal
        open={openImageModal}
        handleClose={() => setOpenImageModal(false)}
        handleSelect={handleSelectImage}
      />
    </>
  );
};

export default InstructionList;