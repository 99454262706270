import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css'; // Estilos globales si los tiene
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = createRoot(document.getElementById('root'));
root.render(<App />);

// Registra el service worker para producción
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register();
} else {
  serviceWorkerRegistration.unregister();
}