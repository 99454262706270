import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AuthContext } from '../components/AuthContext';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, Typography, Modal, Box, Button, Radio } from '@mui/material';
import './FormPayment.css';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    zIndex: 1300,
};

function PaymentForm() {
    const { userId, firstName, lastName, isLoggedIn, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();
    const [cards, setCards] = useState([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isEditing, setIsEditing] = useState(false); // Nuevo estado para edición
    const [open, setOpen] = useState(false); // Estado para el modal
    const [cardType, setCardType] = useState(''); // Estado para el tipo de tarjeta
    const [cardDetails, setCardDetails] = useState({
        cardNumber: '',
        expiryDate: '',
        cvv: '',
        cardholderName: firstName + ' ' + lastName,
        lastFourDigits: ''
    });

    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigate('/login');
        } else {
            fetchCardDetails();
        }
    }, [isLoggedIn, isLoading, navigate]);

    useEffect(() => {
        setCardDetails(prevDetails => ({
            ...prevDetails,
            cardholderName: `${firstName} ${lastName}`
        }));
    }, [firstName, lastName]);

    const fetchCardDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/payments/cardDetails`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            if (response.data.success) {
                setCards(response.data.data);
            } else {
                setCards([]);
            }
        } catch (error) {
            console.error('Failed to fetch card details:', error);
            setCards([]);
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === 'cardNumber') {
            const formattedValue = formatCardNumber(value);
            setCardDetails({ ...cardDetails, [name]: formattedValue });
            setCardType(getCardType(formattedValue.replace(/\s/g, '')));
        } else if (name === 'expiryDate') {
            setCardDetails({ ...cardDetails, [name]: formatExpiryDate(value) });
        } else {
            setCardDetails({ ...cardDetails, [name]: value });
        }
    };

    const handleEdit = (card) => {
        setIsEditing(true);
        setCardDetails({
            cardNumber: '', // No mostramos el número completo por razones de seguridad
            expiryDate: '',
            cvv: '',
            cardholderName: card.cardholderName,
            lastFourDigits: card.lastFourDigits
        });
        setOpen(true);
    };

    const handleDelete = async (cardId) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/payments/deleteCardDetails`, {
                headers: {
                    Authorization: `Bearer ${token}`
                },
                data: { cardId }
            });

            setCards(cards.filter(card => card._id !== cardId));
            Swal.fire('Eliminado', 'Los detalles de tu tarjeta han sido eliminados.', 'success');
        } catch (error) {
            console.error('Error al eliminar los detalles de la tarjeta:', error);
            Swal.fire('Error', 'Error al eliminar los detalles de la tarjeta.', 'error');
        }
    };

    const handleSetDefault = async (cardId) => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payments/setDefaultCard`, { cardId }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });

            if (response.data.success) {
                fetchCardDetails();
                Swal.fire('Actualizado', 'La tarjeta predeterminada ha sido actualizada.', 'success');
            } else {
                Swal.fire('Error', 'No se pudo actualizar la tarjeta predeterminada.', 'error');
            }
        } catch (error) {
            console.error('Error al actualizar la tarjeta predeterminada:', error);
            Swal.fire('Error', 'Error al actualizar la tarjeta predeterminada.', 'error');
        }
    };

    function formatExpiryDate(value) {
        const cleaned = value.replace(/\D/g, '').slice(0, 4);
        if (cleaned.length > 2) {
            return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
        }
        return cleaned;
    }

    function getCardType(cardNumber) {
        const regexPatterns = {
            visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^5[1-5][0-9]{14}$/,
            amex: /^3[47][0-9]{13}$/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
            unionpay: /^(62[0-9]{14,17})$/
        };

        for (let type in regexPatterns) {
            if (regexPatterns[type].test(cardNumber)) {
                return type;
            }
        }
        return '';
    }

    function formatCardNumber(cardNumber) {
        const cardType = getCardType(cardNumber);
        let formattedNumber = cardNumber.replace(/\D/g, '').substring(0, cardType === 'amex' ? 15 : 16);
        
        if (cardType === 'amex') {
            return formattedNumber.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
        } else {
            return formattedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
        }
    }

    const isDateValid = (expiryDate) => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear() % 100; // Get last two digits of the year
        const currentMonth = currentDate.getMonth() + 1;
        const [expMonth, expYear] = expiryDate.split('/').map(num => parseInt(num, 10));

        if (expMonth < 1 || expMonth > 12 || expYear < currentYear) {
            return false;
        }

        if (expYear === currentYear && expMonth < currentMonth) {
            return false;
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsProcessing(true);
    
        if (!userId) {
            Swal.fire({
                icon: 'error',
                title: 'No autorizado',
                text: 'Debe iniciar sesión para continuar.',
                customClass: {
                    popup: 'swal-custom-zindex' // Clase personalizada para ajustar z-index
                },
                willOpen: () => {
                    const modalElement = document.querySelector('.swal2-container');
                    if (modalElement) {
                        modalElement.style.zIndex = '9999'; // Asegura que SweetAlert2 tenga un z-index suficientemente alto
                    }
                }
            });
            setIsProcessing(false); 
            return;
        }
        
        if (!isDateValid(cardDetails.expiryDate)) {
            Swal.fire({ 
                icon: 'error', 
                title: 'Fecha inválida', 
                text: 'La fecha de expiración no es válida.',
                customClass: {
                    popup: 'swal-custom-zindex' // Clase personalizada para ajustar z-index
                },
                willOpen: () => {
                    const modalElement = document.querySelector('.swal2-container');
                    if (modalElement) {
                        modalElement.style.zIndex = '9999'; // Asegura que SweetAlert2 tenga un z-index suficientemente alto
                    }
                }
            });
            setIsProcessing(false); 
            return;
        }
        
        const paymentData = {
            userId, 
            cardNumber: cardDetails.cardNumber.replace(/\s/g, ''),
            cardholderName: cardDetails.cardholderName,
            cvv: cardDetails.cvv,
            expiryDate: cardDetails.expiryDate,
            lastFourDigits: cardDetails.lastFourDigits
        };
    
        try {
            const token = localStorage.getItem('token');
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payments/processPayment`, paymentData, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        
            if (response.data.success) {
                const lastFour = cardDetails.cardNumber.slice(-4);
                setCardDetails({
                    ...cardDetails,
                    lastFourDigits: lastFour
                });
                fetchCardDetails();
                Swal.fire({
                    icon: 'success',
                    title: 'Tarjeta registrada correctamente',
                    text: `Su tarjeta terminada en ${lastFour} ha sido registrada.`,
                    confirmButtonText: 'Ok',
                    customClass: {
                        popup: 'swal-custom-zindex' // Clase personalizada para ajustar z-index
                    },
                    willOpen: () => {
                        const modalElement = document.querySelector('.swal2-container');
                        if (modalElement) {
                            modalElement.style.zIndex = '9999'; // Asegura que SweetAlert2 tenga un z-index suficientemente alto
                        }
                    }
                });
                setIsEditing(false); // Resetear el estado de edición
                setOpen(false); // Cerrar el modal
            } else {
                const errorDesc = response.data.errordescription || 'Se ha producido un error desconocido';
                Swal.fire({
                    icon: 'info',
                    title: 'Iniciación de pago fallida',
                    text: `Error: ${errorDesc}`,
                    customClass: {
                        popup: 'swal-custom-zindex' // Clase personalizada para ajustar z-index
                    },
                    willOpen: () => {
                        const modalElement = document.querySelector('.swal2-container');
                        if (modalElement) {
                            modalElement.style.zIndex = '9999'; // Asegura que SweetAlert2 tenga un z-index suficientemente alto
                        }
                    }
                });
            }
        } catch (error) {
            let errorMessage = error.response ? error.response.data.message : 'Error de conexión';

            console.log(errorMessage)

            // Customizar el mensaje según el tipo de error
            if (errorMessage.includes('declined')) {
                errorMessage = "Lo sentimos, no pudimos procesar tu pago. Por favor, verifica los detalles de tu tarjeta o intenta con otra tarjeta.";
            } else if (errorMessage.includes('timeout')) {
                errorMessage = "La transacción tardó demasiado. Por favor, inténtalo de nuevo.";
            } else if (errorMessage.includes('network')) {
                errorMessage = "Parece que hay un problema con tu conexión. Por favor, verifica tu red e inténtalo de nuevo.";
            } else {
                errorMessage = "Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde.";
            }
            Swal.fire({
                icon: 'error',
                title: 'Error de suscripción',
                text: errorMessage,
                customClass: {
                    popup: 'swal-custom-zindex' // Clase personalizada para ajustar z-index
                },
                willOpen: () => {
                    const modalElement = document.querySelector('.swal2-container');
                    if (modalElement) {
                        modalElement.style.zIndex = '9999'; // Asegura que SweetAlert2 tenga un z-index suficientemente alto
                    }
                }
            });
        } finally {
            setIsProcessing(false); 
        }
    };    

    const handleOpen = () => {
        setCardDetails({
            cardNumber: '',
            expiryDate: '',
            cvv: '',
            cardholderName: firstName + ' ' + lastName,
            lastFourDigits: ''
        });
        setCardType('');
        setIsEditing(false);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div className='payment-form-container'>
            {isProcessing ? (
                <div className='processing-container'>
                    <CircularProgress />
                    <Typography variant="h6" style={{ marginTop: '20px' }}>Suscribiendo...</Typography>
                </div>
            ) : (
                <>
                    {cards.map(card => (
                        <div key={card._id} className='card-container'>
                            <p className={card.isDefault ? 'default-card-label' : ''}>Card ending in {card.lastFourDigits}</p>
                            <div className='card-details'>
                                <p>{card.cardholderName}</p>
                                <p>Expiry: {card.expiryDate}</p>
                                {card.isDefault && <p className='default-card-label'>Default</p>}
                            </div>
                            <div className='card-actions'>
                                <button onClick={() => handleEdit(card)}>Editar</button>
                                <button onClick={() => handleDelete(card._id)}>Eliminar</button>
                                <Radio
                                    checked={card.isDefault}
                                    onChange={() => handleSetDefault(card._id)}
                                    value="default"
                                    name="default-card"
                                    inputProps={{ 'aria-label': 'Default Card' }}
                                /> Predeterminada
                            </div>
                        </div>
                    ))}
                    {cards.length < 3 && (
                        <Button onClick={handleOpen} variant="contained" color="primary" className='add-card-button'>
                            Agregar tarjeta
                        </Button>
                    )}
                    <Modal open={open} onClose={handleClose}>
                        <Box sx={style}>
                            <form onSubmit={handleSubmit}>
                                <input
                                    name="cardNumber"
                                    value={cardDetails.cardNumber}
                                    onChange={handleChange}
                                    placeholder="Card Number"
                                    required
                                />
                                {cardType && <p>Card Type: {cardType}</p>}
                                <input
                                    name="expiryDate"
                                    value={cardDetails.expiryDate}
                                    onChange={handleChange}
                                    placeholder="Expiry Date (MM/YY)"
                                    required
                                />
                                <input
                                    name="cvv"
                                    value={cardDetails.cvv}
                                    onChange={handleChange}
                                    placeholder="CVV"
                                    maxLength={getCardType(cardDetails.cardNumber) === 'amex' ? 4 : 3}
                                    required
                                />
                                <input
                                    name="cardholderName"
                                    value={cardDetails.cardholderName}
                                    onChange={handleChange}
                                    placeholder="Cardholder Name"
                                    required
                                />
                                <button type="submit">{isEditing ? "Actualizar" : "Suscribirme"}</button>
                            </form>
                        </Box>
                    </Modal>
                </>
            )}
        </div>
    );
}

export default PaymentForm;





// import React, { useState, useContext, useEffect } from 'react';
// import axios from 'axios';
// import Swal from 'sweetalert2';
// import { AuthContext } from '../components/AuthContext';
// import { useNavigate } from 'react-router-dom';
// import { CircularProgress, Typography, Modal, Box, Button } from '@mui/material';
// import './FormPayment.css';

// const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: 400,
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     p: 4,
// };

// function PaymentForm() {
//     const { userId, firstName, lastName, isLoggedIn, isLoading } = useContext(AuthContext);
//     const navigate = useNavigate();
//     const [cards, setCards] = useState([]);
//     const [isProcessing, setIsProcessing] = useState(false);
//     const [isEditing, setIsEditing] = useState(false); // Nuevo estado para edición
//     const [open, setOpen] = useState(false); // Estado para el modal
//     const [cardType, setCardType] = useState(''); // Estado para el tipo de tarjeta
//     const [cardDetails, setCardDetails] = useState({
//         cardNumber: '',
//         expiryDate: '',
//         cvv: '',
//         cardholderName: firstName + ' ' + lastName,
//         lastFourDigits: ''
//     });

//     useEffect(() => {
//         if (!isLoading && !isLoggedIn) {
//             navigate('/login');
//         } else {
//             fetchCardDetails();
//         }
//     }, [isLoggedIn, isLoading, navigate]);

//     useEffect(() => {
//         setCardDetails(prevDetails => ({
//             ...prevDetails,
//             cardholderName: `${firstName} ${lastName}`
//         }));
//     }, [firstName, lastName]);

//     const fetchCardDetails = async () => {
//         try {
//             const token = localStorage.getItem('token');
//             const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/payments/cardDetails`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 }
//             });
//             if (response.data.success) {
//                 setCards(response.data.data);
//             } else {
//                 setCards([]);
//             }
//         } catch (error) {
//             console.error('Failed to fetch card details:', error);
//             setCards([]);
//         }
//     };

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         if (name === 'cardNumber') {
//             const formattedValue = formatCardNumber(value);
//             setCardDetails({ ...cardDetails, [name]: formattedValue });
//             setCardType(getCardType(formattedValue.replace(/\s/g, '')));
//         } else if (name === 'expiryDate') {
//             setCardDetails({ ...cardDetails, [name]: formatExpiryDate(value) });
//         } else {
//             setCardDetails({ ...cardDetails, [name]: value });
//         }
//     };

//     const handleEdit = (card) => {
//         setIsEditing(true);
//         setCardDetails({
//             cardNumber: '', // No mostramos el número completo por razones de seguridad
//             expiryDate: '',
//             cvv: '',
//             cardholderName: card.cardholderName,
//             lastFourDigits: card.lastFourDigits
//         });
//         setOpen(true);
//     };

//     const handleDelete = async (cardId) => {
//         try {
//             const token = localStorage.getItem('token');
//             await axios.delete(`${process.env.REACT_APP_API_URL}/api/payments/deleteCardDetails`, {
//                 headers: {
//                     Authorization: `Bearer ${token}`
//                 },
//                 data: { cardId }
//             });

//             setCards(cards.filter(card => card._id !== cardId));
//             Swal.fire('Eliminado', 'Los detalles de tu tarjeta han sido eliminados.', 'success');
//         } catch (error) {
//             console.error('Error al eliminar los detalles de la tarjeta:', error);
//             Swal.fire('Error', 'Error al eliminar los detalles de la tarjeta.', 'error');
//         }
//     };

//     function formatExpiryDate(value) {
//         const cleaned = value.replace(/\D/g, '').slice(0, 4);
//         if (cleaned.length > 2) {
//             return `${cleaned.slice(0, 2)}/${cleaned.slice(2, 4)}`;
//         }
//         return cleaned;
//     }

//     function getCardType(cardNumber) {
//         const regexPatterns = {
//             visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
//             mastercard: /^5[1-5][0-9]{14}$/,
//             amex: /^3[47][0-9]{13}$/,
//             diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
//             discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
//             jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
//             unionpay: /^(62[0-9]{14,17})$/
//         };

//         for (let type in regexPatterns) {
//             if (regexPatterns[type].test(cardNumber)) {
//                 return type;
//             }
//         }
//         return '';
//     }

//     function formatCardNumber(cardNumber) {
//         const cardType = getCardType(cardNumber);
//         let formattedNumber = cardNumber.replace(/\D/g, '').substring(0, cardType === 'amex' ? 15 : 16);
        
//         if (cardType === 'amex') {
//             return formattedNumber.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
//         } else {
//             return formattedNumber.replace(/(\d{4})(?=\d)/g, '$1 ');
//         }
//     }

//     const isDateValid = (expiryDate) => {
//         const currentDate = new Date();
//         const currentYear = currentDate.getFullYear().toString().substr(-2);
//         const currentMonth = currentDate.getMonth() + 1;
//         const [expMonth, expYear] = expiryDate.split('/');
//         return expYear > currentYear || (expYear === currentYear && expMonth >= currentMonth);
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         setIsProcessing(true);

//         if (!userId) {
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Unauthorized',
//                 text: 'You must be logged in to proceed.'
//             });
//             setIsProcessing(false); 
//             return;
//         }
    
//         if (!isDateValid(cardDetails.expiryDate)) {
//             Swal.fire({ 
//                 icon: 'error', 
//                 title: 'Invalid Date', 
//                 text: 'The expiry date is invalid.'
//             });
//             setIsProcessing(false); 
//             return;
//         }
    
//         const paymentData = {
//             userId, 
//             cardNumber: cardDetails.cardNumber.replace(/\s/g, ''),
//             cardholderName: cardDetails.cardholderName,
//             cvv: cardDetails.cvv,
//             expiryDate: cardDetails.expiryDate,
//             lastFourDigits: cardDetails.lastFourDigits
//         };

//         try {
//             const token = localStorage.getItem('token');
//             const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/payments/processPayment`, paymentData, {
//                 headers: {
//                     'Authorization': `Bearer ${token}`
//                 }
//             });
    
//             if (response.data.success) {
//                 const lastFour = cardDetails.cardNumber.slice(-4);
//                 setCardDetails({
//                     ...cardDetails,
//                     lastFourDigits: lastFour
//                 });
//                 fetchCardDetails();
//                 Swal.fire({
//                     icon: 'success',
//                     title: 'Card Registered Successfully',
//                     text: `Your card ending in ${lastFour} has been registered.`,
//                     confirmButtonText: 'Ok'
//                 });
//                 setIsEditing(false); // Resetear el estado de edición
//                 setOpen(false); // Cerrar el modal
//             } else {
//                 const errorDesc = response.data.errordescription || 'Unknown error occurred';
//                 Swal.fire({
//                     icon: 'info',
//                     title: 'Payment Initiation Failed',
//                     text: `Error: ${errorDesc}`
//                 });
//             }
//         } catch (error) {
//             const errorMessage = error.response ? error.response.data.message : 'Network Error';
//             Swal.fire({
//                 icon: 'error',
//                 title: 'Failed to Initiate Payment',
//                 text: errorMessage
//             });
//         } finally {
//             setIsProcessing(false); 
//         }
//     };

//     const handleOpen = () => {
//         setCardDetails({
//             cardNumber: '',
//             expiryDate: '',
//             cvv: '',
//             cardholderName: firstName + ' ' + lastName,
//             lastFourDigits: ''
//         });
//         setCardType('');
//         setIsEditing(false);
//         setOpen(true);
//     };

//     const handleClose = () => {
//         setOpen(false);
//     };

//     return (
//         <div className='flex_row'>
//             {isProcessing ? (
//                 <div className='processing_container'>
//                     <CircularProgress />
//                     <Typography variant="h6" style={{ marginTop: '20px' }}>Suscribiendo...</Typography>
//                 </div>
//             ) : (
//                 <>
//                     {cards.map(card => (
//                         <div key={card._id} className='card_container'>
//                             <p>Card ending in {card.lastFourDigits}</p>
//                             <button onClick={() => handleEdit(card)}>Editar</button>
//                             <button onClick={() => handleDelete(card._id)}>Eliminar</button>
//                         </div>
//                     ))}
//                     {cards.length < 3 && (
//                         <Button onClick={handleOpen} variant="contained" color="primary" className='add_card_button'>
//                             Agregar tarjeta
//                         </Button>
//                     )}
//                     <Modal open={open} onClose={handleClose}>
//                         <Box sx={style}>
//                             <form onSubmit={handleSubmit}>
//                                 <input
//                                     name="cardNumber"
//                                     value={cardDetails.cardNumber}
//                                     onChange={handleChange}
//                                     placeholder="Card Number"
//                                     required
//                                 />
//                                 {cardType && <p>Card Type: {cardType}</p>}
//                                 <input
//                                     name="expiryDate"
//                                     value={cardDetails.expiryDate}
//                                     onChange={handleChange}
//                                     placeholder="Expiry Date (MM/YY)"
//                                     required
//                                 />
//                                 <input
//                                     name="cvv"
//                                     value={cardDetails.cvv}
//                                     onChange={handleChange}
//                                     placeholder="CVV"
//                                     maxLength={getCardType(cardDetails.cardNumber) === 'amex' ? 4 : 3}
//                                     required
//                                 />
//                                 <input
//                                     name="cardholderName"
//                                     value={cardDetails.cardholderName}
//                                     onChange={handleChange}
//                                     placeholder="Cardholder Name"
//                                     required
//                                 />
//                                 <button type="submit">{isEditing ? "Actualizar" : "Suscribirme"}</button>
//                             </form>
//                         </Box>
//                     </Modal>
//                 </>
//             )}
//         </div>
//     );
// }

// export default PaymentForm;