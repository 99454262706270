const esSorprendeme = {
    texto: {
      surprise: 'Sorpréndeme',
      compatibility: '% de compatibilidad',
      grams: 'Gramos: ',
      time: 'Tiempo de cocción: ',
      minutes: 'minutos',
      portions: 'Porciones',
      recipe: 'Ver receta',
      settings: 'Ajustes',
      like: 'Me gusta',
      dislike: 'No me gusta',
    },
    sweetalert: {
      error: {
        title: 'Error',
        text: 'No se encontraron recetas adecuadas para tus preferencias',
      },
      noNewRecipes: {
        title: 'Sin nuevas recetas',
        text: 'No se encontraron nuevas recetas adecuadas para tus preferencias',
      },
      noMoreRecipes: {
        title: 'Sin nuevas recetas',
        text: 'No se encontraron más recetas adecuadas para tus preferencias',
      },
      startOfHistory: {
        title: 'Inicio de historial',
        text: 'No hay recetas anteriores en el historial',
      },
    },
  };
  
  export default esSorprendeme;  