const it = {
    sideMenu: {
      dashboard: 'Dashboard',
      descubrir: 'Scoprire',
      despensa: 'La Mia Dispensa',
      historial: 'Cronologia',
      preferencias: 'Preferenze',
      configuracion: 'Impostazioni',
      nutricionista: 'Pannello Nutrizionista',
      editarRecetas: 'Modifica Ricette',
      soporte: 'Supporto',
    },
  };
  
  export default it;  