import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../AuthContext';
import './Configuracion.css';
import SideMenu from '../../navigation/SideMenu';
import Notificaciones from './notificacion/Notificaciones';
import FormPayment from '../../forms/FormPayment';
import InternalMenu from '../../navigation/InternalMenu';

function Configuracion() {
    const { isLoggedIn, isLoading } = useContext(AuthContext);
    const navigate = useNavigate();
    const [activeSection, setActiveSection] = useState('Perfil');
    const notificacionesRef = useRef(null);
    const perfilRef = useRef(null);

    useEffect(() => {
        if (!isLoading && !isLoggedIn) {
            navigate('/login');
        }
    }, [isLoggedIn, isLoading, navigate]);

    useEffect(() => {
        if (localStorage.getItem('selectNotificaciones') === 'true') {
            localStorage.removeItem('selectNotificaciones');
            setActiveSection('Notificaciones');
            notificacionesRef.current?.click();
        } else if (localStorage.getItem('selectPerfil') === 'true') {
            localStorage.removeItem('selectPerfil');
            setActiveSection('Perfil');
            perfilRef.current?.click();
        }
    }, []);

    return (
        <div className='flex_row'>
            <SideMenu />
            <div className='main_content'>
                <InternalMenu />
                <div className='button_group'>
                    {['Perfil', 'Notificaciones', 'Seguridad', 'Apariencia', 'Métodos de pago'].map(section => (
                        <button
                            key={section}
                            id={`${section.toLowerCase()}-btn`}
                            className={activeSection === section ? 'selected' : ''}
                            onClick={() => setActiveSection(section)}
                            ref={section === 'Notificaciones' ? notificacionesRef : section === 'Perfil' ? perfilRef : null}
                        >
                            {section}
                        </button>
                    ))}
                </div>

                {activeSection === 'Perfil' && <div>Perfil en construcción</div>}
                {activeSection === 'Notificaciones' && <Notificaciones />}
                {activeSection === 'Seguridad' && <div>Seguridad en construcción</div>}
                {activeSection === 'Apariencia' && <div>Apariencia en construcción</div>}
                {activeSection === 'Métodos de pago' && <FormPayment />}
            </div>
        </div>
    );
}

export default Configuracion;