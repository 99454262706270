const itSurpriseMe = {
    texto: {
      surprise: 'Sorprendimi',
      compatibility: '% di compatibilità',
      grams: 'Grammi: ',
      time: 'Tempo di cottura: ',
      minutes: 'minuti',
      portions: 'Porzioni',
      recipe: 'Vedi ricetta',
      settings: 'Impostazioni',
      like: 'Mi piace',
      dislike: 'Non mi piace',
    },
    sweetalert: {
      error: {
        title: 'Errore',
        text: 'Nessuna ricetta adatta trovata per le tue preferenze',
      },
      noNewRecipes: {
        title: 'Nessuna nuova ricetta',
        text: 'Nessuna nuova ricetta adatta trovata per le tue preferenze',
      },
      noMoreRecipes: {
        title: 'Nessun altra ricetta',
        text: 'Nessuna altra ricetta adatta trovata per le tue preferenze',
      },
      startOfHistory: {
        title: 'Inizio della cronologia',
        text: 'Non ci sono ricette precedenti nella cronologia',
      },
    },
  };
  
  export default itSurpriseMe;  