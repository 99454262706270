import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const ConfirmSubscription = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');

        if (token) {
            navigate(`/home?token=${token}`);
        } else {
            navigate('/');
        }
    }, [navigate]);

    return <div>Confirmando suscripción...</div>;
};

export default ConfirmSubscription;