import React from 'react';
import TablaNutricionalPreferencias from './TablaNutricionalPreferencias';

const PreferenciasUsuarioNutricion = ({ onNewTable, invitationId, tableType, tableIds }) => {
  return (
    <div>
      <h2>Preferencias del Usuario</h2>
      <TablaNutricionalPreferencias
        onNewTable={onNewTable}
        invitationId={invitationId}
        tableType={tableType}
        tableIds={tableIds}
      />
    </div>
  );
};

export default PreferenciasUsuarioNutricion;