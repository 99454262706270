import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import axios from 'axios';
import Swal from 'sweetalert2';
import { AuthContext } from '../AuthContext';

function UserPreferences() {
  const { userId } = useContext(AuthContext);
  const [mealTimes, setMealTimes] = useState({
    desayuno: { inicio: 0, fin: 9 },
    merienda1: { inicio: 9, fin: 11 },
    almuerzo: { inicio: 11, fin: 15 },
    merienda2: { inicio: 15, fin: 18 },
    cena: { inicio: 18, fin: 20 },
    merienda3: { inicio: 20, fin: 24 }
  });
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const validateTimes = useCallback((updatedMealTimes = mealTimes) => {
    let newErrors = {};
    let totalMinutes = 0;
    const times = [];

    Object.keys(updatedMealTimes).forEach(meal => {
      let start = updatedMealTimes[meal].inicio * 60;
      let end = updatedMealTimes[meal].fin * 60;

      if (end < start) {
        end += 1440; // 24 hours in minutes
      }

      totalMinutes += end - start;
      times.push({ start, end, meal });
    });

    if (totalMinutes !== 1440) {
      newErrors.general = 'El tiempo total para todas las comidas debe ser exactamente 24 horas.';
    }

    times.forEach((time, i) => {
      times.forEach((otherTime, j) => {
        if (i !== j && (
          (time.start < otherTime.end && time.end > otherTime.start) ||
          (otherTime.start < time.end && otherTime.end > time.start)
        )) {
          newErrors.general = 'Hay un traslape en los horarios de las comidas.';
        }
      });
    });

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [mealTimes]);

  useEffect(() => {
    validateTimes();
  }, [validateTimes]);

  useEffect(() => {
    const fetchUserPreferences = async () => {
      try {
        if (userId) {
          //console.log(`Fetching preferences for user: ${userId}`);
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/options/userPreferences/${userId}`);
          if (response.data && response.data.mealTimes) {
            setMealTimes(response.data.mealTimes);
          }
        }
      } catch (error) {
        //console.log('Error fetching user preferences:', error);
      }
    };

    fetchUserPreferences();
  }, [userId]);

  const handleTimeChange = (meal, type, value) => {
    const updatedTimes = { ...mealTimes };
    updatedTimes[meal][type] = value.getHours() + value.getMinutes() / 60;
    setMealTimes(updatedTimes);
    validateTimes(updatedTimes);
  };

  const handleSubmit = async () => {
    if (!validateTimes()) return;
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/options/userPreferences`, {
        userId,
        mealTimes
      });
      // Usar response de manera que no afecte el comportamiento
      void response;
      Swal.fire({
        title: 'Éxito!',
        text: 'Preferencias guardadas con éxito!',
        icon: 'success',
        confirmButtonText: 'OK'
      });
      setIsEditing(false);
    } catch (error) {
      //console.log('Error submitting preferences:', error.response.data);
      Swal.fire({
        title: 'Error!',
        text: 'Error al guardar preferencias.',
        icon: 'error',
        confirmButtonText: 'OK'
      });
    }
  };

  const handleReset = async () => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: "Esto eliminará tus preferencias guardadas y no podrás revertirlo.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sí, eliminar'
    }).then((result) => {
      if (result.isConfirmed) {
        axios.delete(`${process.env.REACT_APP_API_URL}/api/options/userPreferences/${userId}`)
          .then(response => {
            setMealTimes({
              desayuno: { inicio: 0, fin: 9 },
              merienda1: { inicio: 9, fin: 11 },
              almuerzo: { inicio: 11, fin: 15 },
              merienda2: { inicio: 15, fin: 18 },
              cena: { inicio: 18, fin: 20 },
              merienda3: { inicio: 20, fin: 24 }
            });
            setErrors({});
            Swal.fire(
              'Eliminado!',
              'Tus preferencias han sido eliminadas.',
              'success'
            );
          }).catch(error => {
            //console.log('Error al eliminar preferencias:', error);
            Swal.fire(
              'Error!',
              'No se pudieron eliminar las preferencias.',
              'error'
            );
          });
      }
    });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <div>
        <h2>Configura Tus Horarios de Comidas</h2>
        <Grid container spacing={2}>
          {Object.keys(mealTimes).map((meal) => (
            <Grid item xs={12} sm={6} key={meal}>
              <Typography variant="h6">{meal.charAt(0).toUpperCase() + meal.slice(1)}</Typography>
              {isEditing ? (
                <>
                  <TimePicker
                    label={`Hora de inicio de ${meal}`}
                    value={new Date(0, 0, 0, Math.floor(mealTimes[meal].inicio), (mealTimes[meal].inicio % 1) * 60)}
                    onChange={(value) => handleTimeChange(meal, 'inicio', value)}
                    slotProps={{ textField: { variant: 'outlined' } }}
                  />
                  <TimePicker
                    label={`Hora de fin de ${meal}`}
                    value={new Date(0, 0, 0, Math.floor(mealTimes[meal].fin), (mealTimes[meal].fin % 1) * 60)}
                    onChange={(value) => handleTimeChange(meal, 'fin', value)}
                    slotProps={{ textField: { variant: 'outlined' } }}
                  />
                </>
              ) : (
                <>
                  <Typography>Inicio: {`${Math.floor(mealTimes[meal].inicio)}:${(mealTimes[meal].inicio % 1) * 60}`}</Typography>
                  <Typography>Fin: {`${Math.floor(mealTimes[meal].fin)}:${(mealTimes[meal].fin % 1) * 60}`}</Typography>
                </>
              )}
            </Grid>
          ))}
          {errors.general && <p style={{ color: 'red', margin: '20px' }}>{errors.general}</p>}
        </Grid>
        {isEditing ? (
          <>
            <Button onClick={handleSubmit} color="primary" variant="contained" style={{ margin: 8 }} disabled={Object.keys(errors).length > 0}>
              Guardar Preferencias
            </Button>
            <Button onClick={() => setIsEditing(false)} color="secondary" style={{ margin: 8 }}>
              Cancelar
            </Button>
          </>
        ) : (
          <Button onClick={() => setIsEditing(true)} color="primary" variant="contained" style={{ margin: 8 }}>
            Editar Preferencias
          </Button>
        )}
        <Button onClick={handleReset} color="secondary" style={{ margin: 8 }}>
          Restablecer a Predeterminados
        </Button>
      </div>
    </LocalizationProvider>
  );
}

export default UserPreferences;