import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Swal from 'sweetalert2';
import axios from 'axios';
import './SurpriseMe.css';
import { Favorite, ThumbDownAlt, FavoriteBorder } from '@mui/icons-material';

function SurpriseMe({ userId, token }) {
  const { t } = useTranslation();
  const [recommendedDish, setRecommendedDish] = useState(null);
  const [openPopup, setOpenPopup] = useState(false);
  const [recipeHistory, setRecipeHistory] = useState([]);
  const [currentRecipeIndex, setCurrentRecipeIndex] = useState(-1);
  const [shownRecipeIds, setShownRecipeIds] = useState(new Set());
  const [allRecipes, setAllRecipes] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const fetchAllRecipes = async () => {
    if (userId) {
      setIsFetching(true);
      const horaActual = new Date().getHours();
      const url = `${process.env.REACT_APP_API_URL}/api/recipes/sorprendeme/${userId}?hora=${horaActual}`;

      try {
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        });
        const recipes = response.data;
        console.log('Fetched recipes:', recipes);
        setAllRecipes(recipes);
        setIsFetching(false);
      } catch (error) {
        console.error('Error fetching recipes:', error);
        Swal.fire({
          icon: 'error',
          title: t('sweetalert.error.title'),
          text: t('sweetalert.error.text'),
          timer: 2000,
          showConfirmButton: false
        });
        setIsFetching(false);
      }
    }
  };

  useEffect(() => {
    if (allRecipes.length > 0) {
      const randomIndex = Math.floor(Math.random() * allRecipes.length);
      const selectedRecipe = allRecipes[randomIndex];
      setRecommendedDish(selectedRecipe);
      setRecipeHistory([selectedRecipe]);
      setCurrentRecipeIndex(0);
      setShownRecipeIds(new Set([selectedRecipe._id]));
      setOpenPopup(true);
    }
  }, [allRecipes]);

  const handleSurpriseMeClick = async () => {
    if (allRecipes.length === 0 && !isFetching) {
      await fetchAllRecipes();
    } else if (allRecipes.length > 0) {
      const randomIndex = Math.floor(Math.random() * allRecipes.length);
      const selectedRecipe = allRecipes[randomIndex];
      setRecommendedDish(selectedRecipe);
      setRecipeHistory([selectedRecipe]);
      setCurrentRecipeIndex(0);
      setShownRecipeIds(new Set([selectedRecipe._id]));
      setOpenPopup(true);
    } else {
      Swal.fire({
        icon: 'info',
        title: t('sweetalert.noNewRecipes.title'),
        text: t('sweetalert.noNewRecipes.text'),
        timer: 2000,
        showConfirmButton: false
      });
    }
  };

  const handleNextRecipe = () => {
    if (recipeHistory.length === allRecipes.length) {
      Swal.fire({
        icon: 'info',
        title: t('sweetalert.noMoreRecipes.title'),
        text: t('sweetalert.noMoreRecipes.text'),
        timer: 2000,
        showConfirmButton: false
      });
      return;
    }

    let nextRecipe;
    let randomIndex;

    do {
      randomIndex = Math.floor(Math.random() * allRecipes.length);
      nextRecipe = allRecipes[randomIndex];
    } while (shownRecipeIds.has(nextRecipe._id));

    setRecommendedDish(nextRecipe);
    setRecipeHistory([...recipeHistory, nextRecipe]);
    setCurrentRecipeIndex(currentRecipeIndex + 1);
    setShownRecipeIds(new Set([...shownRecipeIds, nextRecipe._id]));
  };

  const handlePrevRecipe = () => {
    if (currentRecipeIndex > 0) {
      setCurrentRecipeIndex(currentRecipeIndex - 1);
      setRecommendedDish(recipeHistory[currentRecipeIndex - 1]);
    } else {
      Swal.fire({
        icon: 'info',
        title: t('sweetalert.startOfHistory.title'),
        text: t('sweetalert.startOfHistory.text'),
        timer: 2000,
        showConfirmButton: false
      });
    }
  };

  return (
    <div>
      <button onClick={handleSurpriseMeClick} className="button surprise">{t('texto.surprise')}</button>
      {recommendedDish && (
        <Popup open={openPopup} closeOnDocumentClick onClose={() => setOpenPopup(false)}>
          <div className="popup-content">
            <div className="popup-left">
              <img src={recommendedDish.imagenes[0]} alt={recommendedDish.nombre} className="recipe-image" />
              <div className="categories">
                {recommendedDish.categoria.slice(0, 2).map((cat, index) => (
                  <span key={index} className="category">{cat}</span>
                ))}
              </div>
            </div>
            <div className="popup-right">
              <div className="compatibility">
                <Favorite style={{ color: 'red' }} />
                <span>{recommendedDish.compatibilidad} {t('texto.compatibility')}</span>
              </div>
              <h2>{recommendedDish.nombre}</h2>
              <p>{recommendedDish.descripcion}</p>
              <ul>
                <li>{t('texto.grams')} {recommendedDish.gramos}</li>
                <li>{t('texto.time')} {recommendedDish.tiempoCoccion} {t('texto.minutes')}</li>
                <li>{t('texto.portions')} {recommendedDish.porciones}</li>
              </ul>
              <button className="button view-recipe">{t('texto.recipe')}</button>
              <button className="button settings">{t('texto.settings')}</button>
              <div className="actions">
                <button className="button like">
                  <FavoriteBorder style={{ color: 'red' }} /> {t('texto.like')}
                </button>
                <button className="button dislike">
                  <ThumbDownAlt style={{ color: 'gray' }} /> {t('texto.dislike')}
                </button>
              </div>
            </div>
            <button className="arrow left" onClick={handlePrevRecipe}>←</button>
            <button className="arrow right" onClick={handleNextRecipe}>→</button>
          </div>
        </Popup>
      )}
    </div>
  );
}

export default SurpriseMe;