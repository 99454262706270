const enSurpriseMe = {
  texto: {
    surprise: 'Surprise Me',
    compatibility: '% compatibility',
    grams: 'Grams: ',
    time: 'Cooking time: ',
    minutes: 'minutes',
    portions: 'Portions',
    recipe: 'View recipe',
    settings: 'Settings',
    like: 'Like',
    dislike: 'Dislike',
  },
  sweetalert: {
    error: {
      title: 'Error',
      text: 'No suitable recipes found for your preferences',
    },
    noNewRecipes: {
      title: 'No new recipes',
      text: 'No new suitable recipes found for your preferences',
    },
    noMoreRecipes: {
      title: 'No more recipes',
      text: 'No more suitable recipes found for your preferences',
    },
    startOfHistory: {
      title: 'Start of history',
      text: 'No previous recipes in the history',
    },
  },
};

export default enSurpriseMe;