import React, { useState } from 'react';
import Menu from '../navigation/Menu';

export default function FormSubsidio() {
    const [isDisabled, setIsDisabled] = useState(false); // inicialmente, el formulario está habilitado

    const toggleForm = () => {
        setIsDisabled(!isDisabled); // cambia el estado de isDisabled cada vez que se llama a toggleForm
    };

    return (
        <div>
            <Menu />
            <button onClick={toggleForm}>
                {isDisabled ? 'Habilitar formulario' : 'Deshabilitar formulario'}
            </button>
            <form>
                <label>
                    Nombre:
                    <input type="text" name="nombre" disabled={isDisabled} />
                </label>
                <label>
                    Apellido:
                    <input type="text" name="apellido" disabled={isDisabled} />
                </label>
                <label>
                    Cédula:
                    <input type="text" name="cedula" disabled={isDisabled} />
                </label>
                <label>
                    País:
                    <input type="text" name="pais" disabled={isDisabled} />
                </label>
                <label>
                    ¿Por qué solicita el subsidio?:
                    <textarea name="razon" disabled={isDisabled} />
                </label>
                <input type="submit" value="Enviar" disabled={isDisabled} />
            </form>
        </div>
    );
}