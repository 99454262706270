const en = {
    swal: {
      title: 'Are you sure?',
      text: 'Do you want to log out?',
      confirmButtonText: 'Yes, log out',
      cancelButtonText: 'No, cancel',
      confirmedTitle: 'Logged Out!',
      confirmedText: 'Your session has been closed.',
    },
    button: {
      logout: 'Log out',
    },
  };
  
  export default en;  