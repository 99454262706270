import axios from 'axios';
import updateTotalPortions from './updateTotalPortions';

const handleSave = async (value, activeTabla, selectedCell, setActiveTabla, setShowPopup, setWarnings, setTablas, tablas, getMealTime) => {
  const updatedTableData = { ...activeTabla.tableData };
  const mealTime = getMealTime(selectedCell.col);

  const valueString = typeof value === 'string' ? value : value.option ? `(${value.units}) ${value.option}` : value.toString();

  if (valueString.includes('Elegir una opción')) {
    const totalOptionsInMeal = Object.values(updatedTableData).map(row => row[selectedCell.col]).filter(item => item.includes('Elegir una opción')).length;

    // Permitir agregar múltiples "Elegir una opción"
    if (totalOptionsInMeal < 3) { // Permitir hasta 3 "Elegir una opción"
      updatedTableData[selectedCell.group][selectedCell.col] = valueString;
      activeTabla.elegirOpcionIndices[mealTime] += 1;
    } else {
      setWarnings(`Falta que agregue otro "Elegir una opción" en otro tipo de comida en ${mealTime}`);
      return;
    }
  } else if (activeTabla.elegirOpcionIndices[mealTime] === 1) {
    setWarnings(`Debe completar "Elegir una opción" en ${mealTime} antes de continuar con otra opción.`);
    return;
  } else {
    updatedTableData[selectedCell.group][selectedCell.col] = valueString;
  }

  const updatedTableDataWithTotals = updateTotalPortions(updatedTableData);
  setActiveTabla({ ...activeTabla, tableData: updatedTableDataWithTotals });
  setShowPopup(false);
  setWarnings('');

  try {
    await axios.put(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${activeTabla._id}`, {
      tableData: updatedTableDataWithTotals,
      elegirOpcionIndices: activeTabla.elegirOpcionIndices
    });
    setTablas(tablas.map(tabla => tabla._id === activeTabla._id ? { ...tabla, tableData: updatedTableDataWithTotals } : tabla));
    //console.log('Saved table data to database');
  } catch (error) {
    console.error('Error saving table data:', error);
  }
};

export default handleSave;


// CODIGO ORIGINAL

// import axios from 'axios';
// import updateTotalPortions from './updateTotalPortions';

// const handleSave = async (value, activeTabla, selectedCell, setActiveTabla, setShowPopup, setWarnings, setTablas, tablas, getMealTime) => {
//   const updatedTableData = { ...activeTabla.tableData };
//   const mealTime = getMealTime(selectedCell.col);

//   const valueString = typeof value === 'string' ? value : value.option ? `(${value.units}) ${value.option}` : value.toString();

//   if (valueString.includes('Elegir una opción')) {
//     const totalOptionsInMeal = Object.values(updatedTableData).map(row => row[selectedCell.col]).filter(item => item.includes('Elegir una opción')).length;

//     if (totalOptionsInMeal < 2) {
//       updatedTableData[selectedCell.group][selectedCell.col] = valueString;
//       activeTabla.elegirOpcionIndices[mealTime] += 1;
//     } else {
//       setWarnings(`Falta que agregue otro "Elegir una opción" en otro tipo de comida en ${mealTime}`);
//       return;
//     }
//   } else if (activeTabla.elegirOpcionIndices[mealTime] === 1) {
//     setWarnings(`Debe completar "Elegir una opción" en ${mealTime} antes de continuar con otra opción.`);
//     return;
//   } else {
//     updatedTableData[selectedCell.group][selectedCell.col] = valueString;
//   }

//   const updatedTableDataWithTotals = updateTotalPortions(updatedTableData);
//   setActiveTabla({ ...activeTabla, tableData: updatedTableDataWithTotals });
//   setShowPopup(false);
//   setWarnings('');

//   try {
//     await axios.put(`${process.env.REACT_APP_API_URL}/api/options/tablaNutricional/${activeTabla._id}`, {
//       tableData: updatedTableDataWithTotals,
//       elegirOpcionIndices: activeTabla.elegirOpcionIndices
//     });
//     setTablas(tablas.map(tabla => tabla._id === activeTabla._id ? { ...tabla, tableData: updatedTableDataWithTotals } : tabla));
//     //console.log('Saved table data to database');
//   } catch (error) {
//     console.error('Error saving table data:', error);
//   }
// };

// export default handleSave;
